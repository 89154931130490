import { useEffect } from 'react';
import { Loader } from '../components/generic/Loader';
import { GetMoreSection } from '../components/generic/landing/GetMoreSection';
import { Breadcrumbs } from '../components/layout';
import { UserCaseArticleContent } from '../components/user-case-article/UserCaseArticleContent';
import { useCurrentCase } from '../hooks/api/useCurrentCase';
import { scrollToTop } from '../util/scrollToTop';

const crumbs = [
  { label: 'Home', to: '/' },
  { label: 'User Case', to: '/user-case' },
];

export const UserCaseArticle = () => {
  const {
    userCase = { image: '', description: '', productName: '', id: '', relatedCategories: [''] },
    loading,
  } = useCurrentCase();

  const shownCrumbs = [
    ...crumbs,
    {
      label: userCase.productName,
    },
  ];

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className="flex justify-center mb-6">
        <Breadcrumbs crumbs={shownCrumbs} />
      </div>
      {loading ? <Loader /> : <UserCaseArticleContent userCase={userCase} />}
      <GetMoreSection />
    </>
  );
};
