import { HeroSection } from '../components/solution-page/HeroSection';
import { BetterThenOthers } from '../components/generic/landing/BetterThanOthers';
import { CallToAction } from '../components/generic/landing/CallToAction';
import { TrustedBySection } from '../components/solution-page/TrustedBySection';
import { HowCosmithWorksSection } from '../components/solution-page/HowCosmithWorksSection';
import { GetMoreSection } from '../components/generic/landing/GetMoreSection';

export const SolutionsPage = () => {
  return (
    <>
      <HeroSection />
      <TrustedBySection />
      <BetterThenOthers />
      <HowCosmithWorksSection />
      <GetMoreSection />
      <CallToAction />
    </>
  );
};
