export const ArrowDownIcon = ({
  className,
  fillOpacity,
}: {
  className?: string;
  color?: string;
  fillOpacity?: string;
}) => {
  return (
    <svg
      aria-label='Arrow Down'
      className={className}
      width='12'
      height='8'
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.99962 4.97638L10.1246 0.851379L11.303 2.02971L5.99962 7.33305L0.696289 2.02971L1.87462 0.851379L5.99962 4.97638Z'
        fill='currentColor'
        fillOpacity={fillOpacity ? fillOpacity : '0.6'}
      />
    </svg>
  );
};
