import { InputHTMLAttributes, forwardRef } from 'react';
import { ChangeHandler, FormItemVariant } from '../../../types';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  variant?: FormItemVariant;
  onChange: ChangeHandler;
  autoComplete?: string
}

const VARIANT_PROPS: Record<FormItemVariant, string> = {
  normal : 'border-cosmith-primary-900/10 placeholder-color-cosmith-primary-900/60',
  success: 'text-cosmith-success-500 border-cosmith-success-200 bg-cosmith-success-50 placeholder-color-cosmith-success-200',
  error  : 'text-cosmith-error-500 border-cosmith-error-200 bg-cosmith-error-50 placeholder-color-cosmith-error-200'
};

export const FormInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { className = '', onChange, variant = 'normal', ...restProps } = props;

  return (
    <input
      className={[
        'cosmith-form-input',
        'w-full',
        'placeholder-color-cosmith-primary-900/60',
        'border-solid border rounded-xl',
        'shadow px-4 h-12',
        'focus:outline focus:outline-[3px] focus:outline-cosmith-lavanda-400 focus:border-transparent',
        VARIANT_PROPS[variant],
        className
      ].join(' ')}
      {...restProps}
      ref={ref}
      onChange={onChange}
    />
  );
});
