import { useMemo } from 'react';
import { COUNTRIES } from '../util/constants';

export function useCountries (country: string) {
  const countries = useMemo(
    () => COUNTRIES.map((c) => ({ id: c.code, value: c.code, label: c.label })),
    []
  );

  const states = useMemo(
    () => {
      const countryObj = COUNTRIES.find((c) => c.code === country);

      if (!countryObj || !countryObj.states) {
        return [];
      }

      return countryObj.states.map((s) => ({ id: s.code, value: s.code, label: s.label }));
    },
    [country]
  );

  return { countries, states };
}
