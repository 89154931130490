import { AuthPageHeader } from '../../components/auth/AuthPageHeader';
import { AuthHeader } from '../../components/auth/generic/AuthHeader';
import { NavLinkButton } from '../../components/generic';
import { useAuthPageProps } from '../../hooks/auth/useAuthPageProps';
import { Routes } from '../../util/constants';

export const WaitingEmailConfirmationPage = () => {
  const { containerClassName } = useAuthPageProps();

  return (
    <>
      <AuthPageHeader
        linkText="Sign in"
        linkHref={Routes.login()}
        containerClassName={containerClassName}
      />

      <div
        className={`page-content flex items-center justify-center ${containerClassName}`}
      >
        <div className="info-wrapper max-w-[600px] w-full flex flex-col items-center">
          <AuthHeader>Waiting...</AuthHeader>

          <p className="mt-4 text-center">
            Thank you for signing up! A confirmation email has been sent to your
            registered email address. Please check your inbox and follow the
            instructions to complete your account registration. If you don't
            receive the email within a few minutes, please check your spam
            folder.
          </p>

          <NavLinkButton to="/talk-to-expert" className="w-[160px] mt-8">
            Talk to expert
          </NavLinkButton>
        </div>
      </div>
    </>
  );
};
