interface Props {
  className?: string;
  isTrial: boolean;
  daysLeft: number;
  price: number;
}

export default function ComponentServiceInfoSection (props: Props): JSX.Element {
  const { className = '', isTrial, daysLeft, price } = props;

  return (
    <section className={`service-info-section text-cosmith-primary-900 grid grid-cols-[75px,1fr] gap-x-1 whitespace-nowrap ${className}`}>
      {isTrial
        ? <span>Trials:</span>
        : <span>Days left:</span>
      }

      <span>{daysLeft} days</span>

      <span>Price/m</span>
      <span>${price}</span>
    </section>
  );
}
