import { SetterOrUpdater } from 'recoil';
import { Select, SelectOptions } from '../generic/Select';

interface Props {
  value: string;
  onChange: SetterOrUpdater<string>;
  className?: string;
}

export default function ProductCategoryFilter ({ value, onChange, className = '' }: Props): JSX.Element {
  const categoryList: SelectOptions[] = [
    { id: '1', value: 'Adalo', label: 'Adalo' },
    { id: '2', value: 'Marketing tool', label: 'Marketing tool' },
    {
      id: '3',
      value: 'Content creator and viewer',
      label: 'Content creator and viewer',
    },
    { id: '4', value: 'Device', label: 'Device' },
    { id: '5', value: 'Other', label: 'Other' },
  ];

  return (
    <Select
      className={className}
      label="App Categories"
      value={value}
      options={categoryList}
      onChange={onChange}
    />
  );
}
