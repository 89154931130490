import { Oval } from 'react-loader-spinner';

interface Props {
  className?: string;
  visible: boolean;
}

export default function FormSpinner ({ className = '', visible }: Props): JSX.Element | null {
  if (!visible) {
    return null;
  }

  return (
    <div className={`flex items-center gap-2 text-cosmith-warning-500 ${className}`}>
      <Oval
        height={18}
        width={18}
        color="#E9A23B"
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#FBF2CB"
        strokeWidth={4}
        strokeWidthSecondary={4}
      />

      <span>In progress</span>
    </div>
  );
}
