import { FormHeader, FormDescription, Button } from '../../generic';
import { useConfirmEmailForm } from '../../../hooks/auth/useConfirmEmailForm';

export function ConfirmEmailForm () {
  const { onSubmit } = useConfirmEmailForm();

  return (
    <div className='w-full sm:w-[356px]'>
      <FormHeader text="Confirm your email" />
      <FormDescription>
        Thank you for signing up for our Component marketplace. To complete the registration process, please confirm your email address. Once confirmed, you will be able to log in and access our wide range of components.
      </FormDescription>

      <form onSubmit={(e) => e.preventDefault()} className='w-full space-y-6'>
        <Button
          className="w-full"
          type="dark"
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </form>
    </div>
  );
}
