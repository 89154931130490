import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ArrowDownIcon } from '../../icons';
interface IListElem {
  id: string;
  label: string;
  placeholder?: boolean;
  value: string;
}

interface ISidebarProps {
  categoryList: IListElem[];
}

export const Sidebar = ({ categoryList }: ISidebarProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryValue = searchParams.get('category');
  const searchValue = searchParams.get('search');
  const [categoryShown, setCategoryShown] = useState(true);

  return (
    <div className='hidden xl:block xl:ml-[-214px] mr-2'>
      <div className='w-[214px]'>
        <ul>
          <li
            className={`${
              categoryShown
                ? 'bg-cosmith-primary-50 transition-colors duration-700 ease-in-out '
                : 'bg-white transition-colors  duration-700 ease-in-out'
            }`}
          >
            <button
              className='text-cosmith-primary-900/60 text-sm cursor-default flex justify-between items-center w-full focus-outline-custom py-2.5 px-4'
              onClick={() => {
                setCategoryShown(!categoryShown);
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              <span className='mr-2'>App categories</span>
              <div
                className={`${
                  categoryShown && 'rotate-180'
                } text-cosmith-grey-50`}
              >
                <ArrowDownIcon />
              </div>
            </button>
          </li>
          {categoryShown &&
            categoryList.map(({ placeholder, value, id, label }) => (
              <li
                key={id}
                className={`${
                  categoryValue === value
                    ? 'bg-cosmith-primary-50 transition-colors duration-700 ease-in-out '
                    : 'bg-white transition-colors  duration-700 ease-in-out '
                } `}
              >
                <NavLink
                  to={
                    placeholder
                      ? searchValue
                        ? `?search=${searchValue}`
                        : ''
                      : searchValue
                        ? `?category=${value}&search=${searchValue}`
                        : `?category=${value}`
                  }
                  className='focus-outline-custom w-full block h-full py-2.5 px-4'
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {label}
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
