import { useProductList } from './useProductList';
import { useParams } from 'react-router-dom';
import { ProductRouteParams } from '../../types';
import { useMyComponentList } from './useMyComponentList';

export function useCurrentProduct () {
  const { productName } = useParams<ProductRouteParams>();
  const { products, loading } = useProductList({ status:'all' });
  const { components } = useMyComponentList();

  const installedComponent = components.find((c) => c.product.name === productName) || null;
  

  const product = products.find((p) => p.name === productName);

  return { product, installedComponent, loading, productName };
}
