import { useComponentInstallationFlow } from '../../hooks';

import { Stepper } from '../generic';
import FormStep0 from './forms/FormStep0';
import FormStep1 from './forms/FormStep1';
import FormStep2 from './forms/FormStep2';
import FormStep3 from './forms/FormStep3';

export const InstallationProcessor = () => {
  const {
    steps, currentStep, loading,
    onStart, onAgain,
    onCancel, onInstallProduct, onLinkProductToApp
  } = useComponentInstallationFlow();

  return (
    <div className="installation-processor space-y-6">
      <Stepper
        className="lg:text-sm"
        steps={steps}
        currentStep={currentStep}
      />

      {currentStep === 0 && (
        <FormStep0 onClick={onStart} />
      )}

      {currentStep === 1 && (
        <FormStep1
          loading={loading}
          installProduct={onInstallProduct}
          onCancel={onCancel}
        />
      )}

      {currentStep === 2 && (
        <FormStep2
          loading={loading}
          linkProductToApp={onLinkProductToApp}
          onCancel={onCancel}
        />
      )}

      {currentStep === 3 && (
        <FormStep3 onClick={onAgain} />
      )}
    </div>
  );
};
