import { NavLink } from 'react-router-dom';
import { Routes } from '../../../util/constants';

import { FormControl, FormHint, FormInput, FormLabel, FormHeader, FormDescription, Button, FormCheckbox } from '../../generic';
import { GoogleIcon } from '../../../icons';
import { useLoginForm } from '../../../hooks/auth/useLoginForm';
import { FormPasswordInput } from '../../generic/form/FormPasswordInput';

export function LoginForm () {
  const { email, password, rememberMe, onSubmit, onSignInWithGoogle } = useLoginForm();


  return (
    <div className='w-full sm:w-[356px]'>
      <FormHeader text="Log in to your account" />
      <FormDescription>
        Don't have an account?
        <NavLink to={Routes.register()} className="font-semibold mx-2 focus-outline-custom">Sign up</NavLink>
      </FormDescription>

      <form onSubmit={(e) => e.preventDefault()} className='w-full space-y-6'>
        <FormControl variant={email.state.variant}>
          <FormLabel htmlFor='email' children="Email" />
          <FormInput id="email" placeholder="Enter your email" {...email.register} />
          <FormHint hint={email.state.message}  className='absolute bottom-[-22px]'/>
        </FormControl>

        <FormControl variant={password.state.variant}>
          <FormLabel htmlFor='password' children="Password" />
          <FormPasswordInput id="password" placeholder="Enter your password"  {...password.register} />
          <FormHint hint={password.state.message} className='absolute bottom-[-22px]'/>
        </FormControl>

        <div className="flex justify-between items-center">
          <FormCheckbox
            id="remember"
            label="Remember me"
            {...rememberMe.register}
          />

          <NavLink className="font-medium focus-outline-custom" to={Routes.resetPassword()}>Forgot password</NavLink>
        </div>

        <Button
          className="w-full"
          type="dark"
          onClick={onSubmit}
        >
          Continue
        </Button>
      </form>

      <p className="text-center my-6">or sign up with</p>

      <Button className="w-full sm: mb-4" onClick={() => onSignInWithGoogle()}>
        <GoogleIcon />
        <span className="ml-2">Continue with Google</span>
      </Button>
    </div>
  );
}
