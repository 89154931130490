import comments from '../../state/jsons/comments.json';
import { ReviewStarstQty } from '../generic/landing/ReviewStarsQty';

interface IReviewStarsProps {
  type?: 'dark' | 'light';
}

export const ReviewCard = ({ type = 'dark' }: IReviewStarsProps) => {
  return (
    <div
      className={`max-w-[43.75rem] mt-8 mb-6 p-6 text-center border border-cosmith-primary-900/10  rounded-3xl 
       lg:p-12 lg:mt-0 lg:mb-0
       ${type === 'dark' ? 'bg-cosmith-primary-900 text-white ' : ''}    
                `}
    >
      <div className="flex flex-col  gap-6 mb-3 lg:mb-6">
        <ReviewStarstQty type={type} />
      </div>
      <p className="mb-6 text-2xl leading-8 tracking-[-0.024rem]">
        {comments[0].text}
      </p>
      <div>
        <div className="mb-4 flex flex-col items-center">
          <img src={comments[0].avatar} alt="avatar" />
        </div>
        <p className="mb-1 font-medium">
          {comments[0].name}
        </p>
        <p className="text-sm tracking-[-0.14px]">{comments[0].info}</p>
      </div>
    </div>
  );
};
