interface Props {
  icon: string;
  text: string;
}
export function LoginInfoSection ({ icon, text }: Props) {
  return (
    <div className="info grid grid-cols-[48px_1fr] items-start gap-6">
      <img src={icon} alt="icon" width="48" height="48" />
      <p className="text-cosmith-primary-900/60 max-w-[380px] w-full">{text}</p>
    </div>
  );
}
