import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { TrustpilotService } from '../generic/landing/TrustpilotService';

import comments from '../../state/jsons/comments.json';

const settings = {
  dots: true,
  infinite: true,
  speed: 800,
  slidesToShow: 3,
  slidesToScroll: 3,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

export const MainSlider = () => {
  return (
    <section className="px-6 my-12 lg:my-16">
      <div className="flex justify-center mb-6 lg:mb-8">
        <TrustpilotService />
      </div>
      <Slider {...settings} className="main-slider comments-slider">
        {comments.map((comment) => {
          return (
            <div
              key={comment.id}
              className="border border-gray-400 rounded-3xl p-6"
            >
              <div className="flex flex-col gap-6 mb-3 lg:mb-6">
                <div className="w-[76px] h-8 ">
                  <img src={comment.logo} alt="logo" />
                </div>
                <div>
                  <img src={comment.star} alt="stars" />
                </div>
              </div>
              <p className="mb-6 text-lg lg:text-2xl">{comment.text}</p>
              <div>
                <div className="mb-4">
                  <img src={comment.avatar} alt="avatar" />
                </div>
                <p className="mb-1">{comment.name}</p>
                <p className="text-sm text-cosmith-primary-900/60">
                  {comment.info}
                </p>
              </div>
            </div>
          );
        })}
      </Slider>
    </section>
  );
};
