import { MouseEvent, PropsWithChildren } from 'react';
import { useButtonProps } from '../../hooks';
import { ButtonProps } from '../../types';

type Props = ButtonProps<{
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}>

export function Button ({
  type = 'primary',
  size = 'normal',
  children,
  onClick,
  disabled = false,
  className = ''
}: PropsWithChildren<Props>): JSX.Element {
  const { classNames } = useButtonProps({ type, size, disabled, className });

  return (
    <button className={classNames} onClick={onClick}>
      {children}
    </button>
  );
}
