import { useMemo } from 'react';
import type { IBillingData } from '../../types';

interface Props {
  data: IBillingData[];
  className?: string;
}

export default function EstimatedDueSummary ({ data, className = '' }: Props): JSX.Element {
  const totalAmount = useMemo(() => {
    return data.reduce((res, b) =>res + b.price, 0);
  }, [data]);

  return (
    <div className={`estimated-due-summary ${className}`}>
      <div className="font-semibold text-2xl">
        <span className="mr-2">Estimated Due:</span>
        <br className="block lg:hidden" />
        <span>${totalAmount}</span>
      </div>
      <div className="text-cosmith-primary-900/60 mt-4">This is an estimate of the amount you owe based on your current month-to-date usage after credits & prepayments.</div>

      <div className="grid grid-cols-2 w-[270px] gap-x-14 gap-y-3 mt-4 mx-auto lg:w-[330px] lg:mx-0 lg:gap-x-2">
        <span className="font-semibold">April 1, 2023</span>
        <span className="font-semibold">$56.60</span>

        <span className="font-semibold text-xs">Payment due</span>
        <span className="font-semibold text-xs">Total usage</span>
      </div>
    </div>
  );
}
