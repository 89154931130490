import { SetterOrUpdater } from 'recoil';
import { Select, SelectOptions } from '../generic';
import { useLocation, useNavigate } from 'react-router-dom';

interface IProps {
  categoryList: SelectOptions[];
  onChange?: SetterOrUpdater<string>;
  className?: string;
}

export const CategoryProductFilter = ({
  categoryList,
  className = '',
}: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const categoryValue = searchParams.get('category');
  const searchValue = searchParams.get('search');

  const handleOnChange = (value: string) => {
    if (!value) {
      searchParams.delete('category');
      const newURL = `${location.pathname}?${searchParams.toString()}`;
      navigate(newURL);
      return;
    }
    searchValue
      ? navigate(`?category=${value}&search=${searchValue}`)
      : navigate(`?category=${value}`);
  };
  return (
    <Select
      className={className}
      label="App Categories"
      value={categoryValue ? categoryValue : ''}
      options={categoryList}
      onChange={handleOnChange}
    />
  );
};
