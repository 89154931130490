import authLogo from '../../../assets/auth/auth-logo-icon.svg';
import { AuthHeader } from '../generic/AuthHeader';

export function RegisterInfo () {
  return (
    <div className="reset-password-info">
      <img src={authLogo} alt="auth logo" width="100" height="100" className="" />

      <AuthHeader>
        Start making your work easier
      </AuthHeader>

      <p className="text-lg text-cosmith-primary-900/60 mt-6">Create an account and feel the ease, speed and quality.</p>
    </div>
  );
}
