interface Props {
  className?: string;
}

export default function AccessTokenInfo ({ className = '' }: Props): JSX.Element {
  return (
    <div className={`token-info bg-cosmith-primary-600 rounded-lg p-6 ${className}`}>
      <h4 className="font-semibold text-[#fdffff]">Access Token*</h4>

      <p className="text-[#fdffff]/70 mt-3">In Adalo applications, access tokens are used to authenticate and authorize access to external APIs and services that your app integrates with. Access tokens are generated by the API or service provider, and then passed to Adalo in order to allow your app to make requests to the external service.</p>
    </div>
  );
}
