import { useEffect, useState } from 'react';
import ScrollArrow from '../../assets/ScrollArrow.svg';
export const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setIsVisible(true);
      } else if (scrolled <= 300) {
        setIsVisible(false);
      }
    };
    window.addEventListener('scroll', toggleVisible);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className='focus-outline-custom fixed bottom-9 right-5 w-11 h-11 bg-transparent focus:rounded-full rounded-full flex items-center justify-center cursor-pointer'
        >
          <img src={ScrollArrow} alt='scroll to top' />
        </button>
      )}
    </div>
  );
};
