import { PropsWithChildren } from 'react';
import { NavLinkButton } from '.';

interface IAdaptiveErrorSectionProps {
  imgUrl: string;
  title: string;
  buttonText: string;
  to: string;
  target?: string;
  onClick?: () => void;
}
export const AdaptiveErrorSection = ({
  imgUrl,
  title,
  children,
  buttonText,
  to,
  target = '_self',
  onClick,
}: PropsWithChildren<IAdaptiveErrorSectionProps>) => {
  return (
    <section className="flex flex-col items-center ">
      <img src={imgUrl} alt="icon" />
      <h3 className="text-2xl font-semibold leading-[1.33] tracking-[-0.024rem] text-center">
        {title}
      </h3>
      <p className="mb-4 text-center text-base font-medium leading-6 tracking-[-0.01rem] text-cosmith-primary-900/60">
        {children}
      </p>
      <div onClick={onClick}>
        <NavLinkButton type="primary" to={to} target={target} className='min-w-[19rem] max-w-[25rem] lg:min-w-[9.75rem] '>
          {buttonText}
        </NavLinkButton>
      </div>
    </section>
  );
};
