import { PropsWithChildren } from 'react';

interface IInfoFeaturesProps {
  title: JSX.Element | string;
  imageUrl?: string;
  className?: string;
}

export const InfoFeatures = ({
  imageUrl,
  children,
  title,
  className,
}: PropsWithChildren<IInfoFeaturesProps>) => {
  return (
    <div
      className={`flex flex-col items-center mt-6 lg:items-start  lg:mt-0 xl:w-1/2 ${className} `}
    >
      <img src={imageUrl} alt="icon" width={48} height={48} />
      <h3 className="text-center text-xl font-semibold leading-[1.4] tracking-[-0.018rem] mt-4 mb-4 lg:text-left lg:mt-6 xl:mb-6">
        {title}
      </h3>
      <p className="text-center leading-[1.5] tracking-[-0.01rem] opacity-60 lg:text-left">
        {children}
      </p>
    </div>
  );
};
