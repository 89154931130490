import React, { useState } from 'react';
import { Paragraphs } from './Paragraphs';
import { ArrowDownIcon } from '../../icons';

interface IFaqItemProps {
  title: string;
  paragraphs: string[];
}
export const FaqItem = (content: IFaqItemProps) => {
  const { title, paragraphs } = content;
  const [isOpen, setIsOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleShowAll = () => {
    setTimeout(() => {
      setShowAll(false);
    }, 700);
  };
  const handleClick = () => {
    toggleIsOpen();
    handleShowAll();
  };

  return (
    <li
      key={title}
      className={`${
        isOpen
          ? 'max-h-[2000px]  after:border-cosmith-lavanda-400 after:w-full'
          : 'max-h-[80px]'
      }
        py-6  overflow-y-hidden transition-[max-height] duration-700 ease-in-out border-b
        relative  group
        after:absolute after:border-b-[1px] after:border-solid  after:border-cosmith-lavanda-400 after:left-[0px] after:bottom-0 after:w-[0px]
        lg:after:hover:w-full after:transition-all after:duration-700 
        `}
    >
      <div
        className='mx-1 cursor-pointer flex items-center 
                  focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-cosmith-lavanda-400 
                  outline-none rounded-[8px] p-2 w-[90%] md:w-[45%] gap-4 lg:gap-0'
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Enter' && handleClick()}
        onClick={() => handleClick()}
        // mouseDown preveny outline
        onMouseDown={(e) => e.preventDefault()}
      >
        <div
          className={`text-cosmith-grey-50  lg:group-hover:text-cosmith-lavanda-400 lg:focus-within:text-cosmith-lavanda-400 
            ${isOpen ? 'text-cosmith-lavanda-400' : 'text-cosmith-grey-50'}`}
        >
          <ArrowDownIcon
            fillOpacity='1'
            className={`${isOpen ? 'rotate-180' : ''} 
          transition-[transform] duration-700  ease-in-out`}
          />
        </div>
        <h2
          className='text-lg font-medium leading-6 tracking-tight text-center 
                 lg:text-2xl lg:text-left lg:font-semibold lg:leading-8 lg:tracking-tighter lg:ml-6'
        >
          {title}
        </h2>
      </div>

      <Paragraphs
        paragraphs={paragraphs}
        showAll={showAll}
        setShowAll={setShowAll}
        isOpen={isOpen}
      />
    </li>
  );
};
