import { useParams } from 'react-router-dom';
import { useGetUserCases } from './useGetUserCases';

export const useCurrentCase = () => {
  const { id } = useParams();
  const { allUserCases, loading } = useGetUserCases();
  const userCase = allUserCases.find((c) => c.id === id);

  return { userCase, loading };
};
