import { BusinessFeaturesWrapper } from '../generic/landing/BusinessFeaturesWrapper';
import { ReviewCard } from './ReviewCard';

const features = [
  { id: 1, value: 'Extensive component library' },
  { id: 2, value: 'Expert consultation' },
  { id: 3, value: 'Top-notch support' },
];

export const StartupInfo = () => {
  return (
    <div>
      <BusinessFeaturesWrapper
        title="Access to high-quality components"
        features={features}
        secondChildren={<ReviewCard type="light" />}
        secondChildrenclassName="lg:min-h-[428px] lg:pt-[25.5px]"
      >
        With Cosmith.io, you have everything you need to bring your ideas to
        life and create exceptional applications that meet your business needs.
      </BusinessFeaturesWrapper>
    </div>
  );
};
