import { AdaptiveImageCard } from '../generic/AdaptiveImageCard';
import { ResponsivePicture } from '../generic/ResponsivePicture';
import { SectionSubTitle } from '../generic/landing/SectionSubTitle';
import { HowCosmithWorksList } from './HowCosmithWorksList';
import HowWorks_mobile from '../../assets/solutions-page/HowItWorks_mobile.png';
import HowWorks_tablet from '../../assets/solutions-page/HowItWorks_tablet.png';
import HowWorks_pc from '../../assets/solutions-page/HowItWorks_pc.png';

const images = {
  mobile: {
    src: HowWorks_mobile,
    width: 356,
  },
  tablet: {
    src: HowWorks_tablet,
    width: 536,
  },
  pc: {
    src: HowWorks_pc,
    width: 536,
  },
};

export const HowCosmithWorksSection = () => {
  return (
    <section>
      <SectionSubTitle className="mb-[3rem] mt-12 text-center lg:mb-0 lg:mt-[4rem]">
        How cosmith.io works?
      </SectionSubTitle>
      <div className="lg:ml-[-2rem] xl:ml-0 flex flex-col-reverse">
        <AdaptiveImageCard
          reversed
          secondChildren={
            <ResponsivePicture imageUrl={HowWorks_pc} images={images} />
          }
          className="flex flex-col-reverse"
        >
          <div>
            <div className="mb-12 lg:mb-8">
              <div
                className="lg:flex lg:flex-col lg:gap-6 lg:mt-8 lg:mb-8 lg:max-w-[470px]
                xl:flex-row xl:gap-6 xl:max-w-[588px] "
              >
                <HowCosmithWorksList />
              </div>
            </div>
          </div>
        </AdaptiveImageCard>
      </div>
    </section>
  );
};
