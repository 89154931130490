import { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { useButtonProps } from '../../hooks';
import { ButtonProps } from '../../types';

type Props = ButtonProps<{
  state?:string;
  to: string;
  onClick?: () => void;
}>;

export function NavLinkButton ({
  type = 'primary',
  size = 'normal',
  children,
  to,
  state,
  disabled,
  className = '',
  target = '_self',
  onClick,
}: PropsWithChildren<Props>): JSX.Element {
  const { classNames } = useButtonProps({ type, size, disabled, className });

  return (
    <NavLink className={classNames} to={to} target={target} onClick={onClick} state={state}>
      {children}
    </NavLink>
  );
}
