import { ReactComponent as ArrowDown } from '../../icons/ArrowDownSmall.svg';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Section } from './helpPageTypes';

const DEFAULT_ARTICLES_TO_SHOW = 3;

interface HelpItemSectionProps {
  section: Section;
}
export const HelpSectionItem = ({ section }: HelpItemSectionProps) => {
  const { id, title, articles } = section;

  const [isExpanded, setIsExpanded] = useState(false);
  const [articlesToShow, setArticlesToShow] = useState(
    articles.slice(0, DEFAULT_ARTICLES_TO_SHOW)
  );
  const toggleSection = () => {
    setIsExpanded(!isExpanded);
    if (isExpanded) {
      setTimeout(() => {
        setArticlesToShow(articles.slice(0, DEFAULT_ARTICLES_TO_SHOW));
      }, 700);
    }
    setArticlesToShow(articles);
  };

  return (
    <li className='text-cosmith-primary-800'>
      <article key={id} className='mb-12 '>
        <header>
          <h2 className='text-[24px] font-semibold leading-[32px] tracking-[-0.016em] mb-4 lg:mb-12 text-cosmith-primary-900'>
            {title}
          </h2>
        </header>
        <div
          className={`${
            isExpanded ? 'max-h-[300px]' : 'max-h-[108px] lg:max-h-[34px]'
          }  overflow-y-hidden transition-[max-height] duration-700 ease-in
             grid lg:grid-cols-3  gap-4 lg:gap-y-6
             p-[2px] 
          `}
        >
          {articlesToShow.map((article) => (
            <Link
              key={article.id}
              to={`/help/article/${article.id}`}
              className='text-[16px] leading-[24px] tracking-[-0.01em]  lg:font-bold 
              h-[24px] block focus:outline-cosmith-lavanda-400 '
            >
              {article.title}
            </Link>
          ))}
        </div>
        {articles.length > DEFAULT_ARTICLES_TO_SHOW && (
          <div className='flex justify-center lg:justify-start'>
            <button
              onClick={toggleSection}
              className='flex items-center justify-center text-[16px] font-medium leading-[24px] tracking-[-0.01em]   mt-4 lg:mt-6
              focus:outline-cosmith-lavanda-400 '
            >
              {isExpanded ? 'Show less' : 'See all articles'}
              <span
                className={`ml-2 transform transition-transform duration-700 ${
                  isExpanded ? 'rotate-180' : ''
                }`}
              >
                <ArrowDown />
              </span>
            </button>
          </div>
        )}
      </article>
    </li>
  );
};
