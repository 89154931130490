import { StartupInfo } from './StartupInfo';
import { SmallBusinessInfo } from './SmallBusinessInfo';
import { EnterprisesBusinessInfo } from './EnterprisesBusinessInfo';

export const RenderBusinessComponent = ({
  switchPosition,
}: {
  switchPosition: string;
}) => {
  if (switchPosition === 'startup') {
    return <StartupInfo />;
  }
  if (switchPosition === 'smallAndMedium') {
    return <SmallBusinessInfo />;
  }
  if (switchPosition === 'enterprises') {
    return <EnterprisesBusinessInfo />;
  }

  return <StartupInfo />;
};
