import { NavLink } from 'react-router-dom';
import { MainLogo } from '../../icons/Logo';
import { Burger, useNavMenu } from '../generic/burger/Burger';
import { NavLinkButton } from '../generic';
import { useScreenSize } from '../../hooks';
import { BurgerMenu } from '../generic/burger/BurgerMenu';
interface Props {
  className?: string;
  containerClassName?: string;
  linkText: string;
  linkHref: string;
}

export function AuthPageHeader ({
  linkText,
  linkHref,
  className = '',
  containerClassName = '',
}: Props): JSX.Element {
  const screen = useScreenSize();
  const { isNavMenuOpen, toggleNavMenu } = useNavMenu();

  return (
    <div className={`auth-header w-full bg-white border-b-[1px] ${className}`}>
      <div
        className={`flex justify-between items-center ${containerClassName}`}
      >
        <NavLink
          to='/'
          className='focus-outline-custom'
          onMouseDown={(e) => e.preventDefault()}
        >
          <MainLogo type='black' />
        </NavLink>

        <div className='flex items-center space-x-4'>
          <NavLinkButton
            to={linkHref}
            children={linkText}
            type={screen.gt.md ? 'link' : 'dark'}
            size='extraSmall'
          />
          <Burger onBurgerClick={toggleNavMenu} isMenuOpen={isNavMenuOpen} />
        </div>
      </div>
      <div className='relative ml-4 lg:hidden'>
        <BurgerMenu
          isBurgerMenuVisible={isNavMenuOpen}
          onOptionClick={toggleNavMenu}
        />
      </div>
    </div>
  );
}
