import { SectionSubTitle } from './SectionSubTitle';
import FeatureIcon from '../.././../assets/better-then-others/FeaturesIcons.svg';
import FeatureHighQuality from '../../../assets/better-then-others/FeaturesHighQuality.svg';
import SearchIcon from '../../../assets/better-then-others/SearchIcon.svg';
import PaymentDeliveryIcon from '../../../assets/better-then-others/PaymentDeliveryIcon.svg';
import DetailedIcon from '../../../assets/better-then-others/DetailedIcon.svg';
import SupportIcon from '../../../assets/better-then-others/SupportIcon.svg';
import { BetterThenOthersItem } from './BetterThenOthersItem';

export const BetterThenOthers = () => {
  return (
    <section className="flex flex-col items-center">
      <SectionSubTitle className="mb-[3rem] mt-12 lg:max-w-[792px] text-center lg:mb-[4rem]">
        Why is our platform better than others?
      </SectionSubTitle>

      <ul className="grid gap-[24px] mb-12 lg:mb-16 lg:grid-cols-2 xl:grid-cols-3 ">
        <BetterThenOthersItem
          imgUrl={FeatureIcon}
          title="Wide range of components"
        >
          We have components for Adalo, Flutterflow and more.
        </BetterThenOthersItem>

        <BetterThenOthersItem
          imgUrl={FeatureHighQuality}
          title="High quality components"
        >
          All components go through strict quality control and are reliable.
        </BetterThenOthersItem>

        <BetterThenOthersItem
          imgUrl={SearchIcon}
          title="Convenient component search"
        >
          Easily find components using our search by keywords and filters.
        </BetterThenOthersItem>

        <BetterThenOthersItem
          imgUrl={DetailedIcon}
          title="Detailed documentation for components"
        >
          We provide detailed descriptions and documentation for all components.
        </BetterThenOthersItem>

        <BetterThenOthersItem
          imgUrl={PaymentDeliveryIcon}
          title="Convenient payment and fast delivery"
        >
          Choose convenient payment methods, including bank cards and e-wallets.
        </BetterThenOthersItem>
        <BetterThenOthersItem
          imgUrl={SupportIcon}
          title="Professional user support"
        >
          Our experienced specialists are ready to answer any questions and help
          solve problems.
        </BetterThenOthersItem>
      </ul>
    </section>
  );
};
