import { BusinessFeaturesWrapper } from '../generic/landing/BusinessFeaturesWrapper';
import { ReviewCard } from './ReviewCard';

const features = [
  { id: 1, value: 'Wide range of components' },
  { id: 2, value: 'Free installation and using consulting' },
  { id: 3, value: 'Prompt support' },
  { id: 4, value: 'Free trial' },
];

export const SmallBusinessInfo = () => {
  return (
    <div>
      <BusinessFeaturesWrapper
        title="Access to a wide range of components"
        features={features}
        secondChildren={<ReviewCard type="light" />}
        secondChildrenclassName="lg:min-h-[428px] lg:pt-[25.5px]"
      >
        Our comprehensive suite covers essential processes, from payment to
        invoice generation, and integrates seamlessly with various external
        devices. All components are designed to streamline your business
        operations and drive growth.
      </BusinessFeaturesWrapper>
    </div>
  );
};
