import { AdaptiveImageCard } from '../generic/AdaptiveImageCard';
import { ResponsivePicture } from '../generic/ResponsivePicture';
import { InfoDescription } from '../generic/landing/info-section/InfoDescription';
import SEO_photo_mobile from '../../assets/talk-to-an-expert/SEO_photo_mobile.png';
import SEO_photo_tablet from '../../assets/talk-to-an-expert/SEO_photo_tablet.png';

const images = {
  mobile: {
    src: SEO_photo_mobile,
    width: 358,
  },
  tablet: {
    src: SEO_photo_tablet,
    width: 486,
  },
  pc: {
    src: SEO_photo_tablet,
    width: 486,
  },
};

export const SectionWithSEO = () => {
  return (
    <AdaptiveImageCard
      reversed
      secondChildrenclassName="mt-0 mb-[22px] lg:mb-0"
      className="lg:pb-0 xl:pb-16"
      secondChildren={
        <ResponsivePicture imageUrl={SEO_photo_tablet} images={images} />
      }
    >
      <div>
        <div className="mb-12 lg:mb-8">
          <InfoDescription title="" className="opacity-95">
            <div
              className="font-medium tracking-[-0.024rem] opacity-100 text-2xl max-w-[700px]
            lg:text-2xl lg:font-semibold lg:max-w-[25rem] lg:tracking-[-0.024rem]
            xl:max-w-[588px]"
            >
              “Our project helps companies save time when searching for the
              necessary components for their projects, allowing them to focus on
              other aspects of development such as coding or designing an
              interface, without compromising quality or efficiency.”
              <p className="mt-6 text-base tracking-[-0.01rem] leading-6 lg:mt-8">
                Oleksandr L.
              </p>
              <p className="text-sm opacity-60 leading-5 tracking-[-0.14px]">
                СEO of cosmith.io
              </p>
            </div>
          </InfoDescription>
          <div
            className="lg:flex lg:flex-col lg:gap-6 lg:mt-8 lg:mb-8 lg:max-w-[331px]
            xl:flex-row xl:gap-6 xl:max-w-[588px] "
          ></div>
        </div>
      </div>
    </AdaptiveImageCard>
  );
};
