import React, { ReactNode } from 'react';

interface SectionProps {
  title: string;
  children: ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, children }) => (
  <section className="mb-6">
    <h2 className="text-xl font-semibold mb-4">{title}</h2>
    <div className="text-cosmith-primary-900 opacity-60 font-medium text-justify">
      {children}
    </div>
  </section>
);

export default Section;