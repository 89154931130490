import { atom } from 'recoil';

export const productFilterState = atom<string>({
  key: 'productFilterState',
  default: '',
});

export const filterCategoryState = atom<string | null>({
  key: 'shownFilterState',
  default:
    new URLSearchParams(window.location.search).get('categories') ?? 'top',
  effects: [],
});
