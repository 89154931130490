import { Routes } from '../../util/constants';

import { AuthPageWrapper } from '../../components/auth/AuthPageWrapper';
import { ResetPasswordInfo } from '../../components/auth/reset-password/ResetPasswordInfo';
import { ResetPasswordForm } from '../../components/auth/reset-password/ResetPasswordForm';

export const ResetPasswordPage = () => {
  return (
    <AuthPageWrapper
      linkText="Sign up"
      linkHref={Routes.register()}
      info={<ResetPasswordInfo />}
      form={<ResetPasswordForm />}
    />
  );
};
