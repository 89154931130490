import star from '../../../assets/stars.svg';
import trustpilot from '../../../assets/trustpilot.svg';

export const TrustpilotService = () => {
  return (
    <div
      className="inline-flex items-center"
      style={{ fontFamily: 'Helvetica Neue' }}
    >
      <p className="text-[17px] mr-3">Excellent</p>
      <img className="mr-2" src={star} alt="trustpilot" />
      <img src={trustpilot} alt="trustpilotIcon" />
    </div>
  );
};
