import React, { ReactNode } from 'react';

interface StrongTextProps {
  children: ReactNode;
}

const StrongText: React.FC<StrongTextProps> = ({ children }) => (
  <strong className="text-black">
    {children}
  </strong>
);

export default StrongText;
