import ProductInstallationWrapper from '../components/installation/ProductInstallationWrapper';
import InstallationVideo from '../components/installation/InstallationVideo';
import AccessTokenInfo from '../components/installation/AccessTokenInfo';
import InstallationManual from '../components/installation/InstallationManual';
import { InstallationProcessor } from '../components/installation/InstallationProcessor';
import TokenUsageHistory from '../components/installation/TokenUsageHistory';
import InstallationPlaceholder from '../components/installation/InstallationPlaceholder';
import { useCurrentProduct, useIsUserAuth } from '../hooks';
import { EmptyDataResponse } from '../components/generic';

export const ProductInstallationPage = () => {
  const isAuthorized = useIsUserAuth();
  const { product, loading } = useCurrentProduct();
  
  if (loading) {
    return null; // todo preloader
  }
  if (!product || !product.installation) {
    return <EmptyDataResponse />;
  }

  const { installation } = product;

  return (
    <div className="product-installation h-auto">
      <ProductInstallationWrapper>
        <section className="installation-info bg-cosmith-purple-200 px-4 order-2 lg:order-1 rounded space-y-6 lg:overflow-auto lg:max-h-[100vh]">
          <div className="font-semibold">Guide</div>

          <InstallationVideo src={installation.videoUrl} />
          <AccessTokenInfo />
          <InstallationManual data={installation.manual} />
        </section>

        <section className="space-y-6 px-3 order-1 lg:order-2">
          <div className="font-semibold">Installation</div>

          {isAuthorized && ([
            <InstallationProcessor key="processor" />,
            <TokenUsageHistory className="pt-11" key="history" />
          ])}

          {!isAuthorized && (
            <InstallationPlaceholder />
          )}
        </section>
      </ProductInstallationWrapper>
    </div>
  );
};
