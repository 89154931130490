import { InputHTMLAttributes, forwardRef, useState } from 'react';
import { ChangeHandler, FormItemVariant } from '../../../types';
import ButtonIcon from '../ButtonIcon';
import { CloseEyeIcon } from '../../../icons/CloseEyeIcon';
import { OpenEyeIcon } from '../../../icons/OpenEyeIcon';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  variant?: FormItemVariant;
  onChange: ChangeHandler;
  autoComplete?: string;
};

const VARIANT_PROPS: Record<FormItemVariant, string> = {
  normal:
    'border-cosmith-primary-900/10 placeholder-color-cosmith-primary-900/60',
  success:
    'text-cosmith-success-500 border-cosmith-success-200 bg-cosmith-success-50 placeholder-color-cosmith-success-200',
  error:
    'text-cosmith-error-500 border-cosmith-error-200 bg-cosmith-error-50 placeholder-color-cosmith-error-200',
};

export const FormPasswordInput = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      className = '',
      onChange,
      variant = 'normal',
      ...restProps
    } = props;
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    return (
      <div
        className={['border-solid border rounded-xl', 'shadow h-12', 'bg-white flex justify-between '].join(' ')}
      >
        <input
          className={[
            'cosmith-form-input',
            'w-full',
            'placeholder-color-cosmith-primary-900/60',
            'rounded-xl',
            'h-11 px-4',
            'focus:outline focus:outline-[3px] focus:outline-cosmith-lavanda-400 focus:border-transparent',
            VARIANT_PROPS[variant],
            className,
          ].join(' ')}
          {...restProps}
          type={isPasswordShown?'text':'password'}
          ref={ref}
          onChange={onChange}
        />
        {isPasswordShown ? (
          <ButtonIcon className='absolute right-2 focus-outline-custom' onClick={() => setIsPasswordShown(false)}>
            <CloseEyeIcon clasName='fill-cosmith-primary-900/60 hover:fill-cosmith-lavanda-400' />
          </ButtonIcon>
        ) : (
          <ButtonIcon className='absolute right-2 focus-outline-custom' onClick={() => setIsPasswordShown(true)}>
            <OpenEyeIcon clasName='fill-cosmith-primary-900/60  hover:fill-cosmith-lavanda-400' />
          </ButtonIcon>
        )}
      </div>
    );
  }
);
