import { PropsWithChildren } from 'react';

interface IAdaptiveImageCardProps {
  reversed?: boolean;
  secondChildren?: JSX.Element | string;
  className?: string;
  childrenClassName?: string;
  secondChildrenclassName?: string;
}

export const AdaptiveImageCard = ({
  children,
  secondChildren,
  reversed = false,
  className = '',
  childrenClassName = '',
  secondChildrenclassName = '',
}: PropsWithChildren<IAdaptiveImageCardProps>) => {
  return (
    <section
      className={`flex flex-col flex-wrap items-center
      lg:flex-nowrap lg:flex-row lg:py-16 lg:gap-6 justify-between
      ${reversed ? 'lg:flex-row-reverse' : ''} ${className}`}
    >
      <div className={`lg:w-1/2 ${childrenClassName}`}>{children}</div>
      <div
        className={`max-w-[588px] lg:w-1/2 mt-4 lg:mt-0 ${secondChildrenclassName}`}
      >
        {secondChildren}
      </div>
    </section>
  );
};
