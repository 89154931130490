import { Select, SelectOptions } from '../Select';

interface IButtonSwitcherProps {
  switchPosition: string;
  setSwitchPosition: (switchPosition: string) => void;
  switchPositionList: SelectOptions[];
}

export const SelectSwitcher = ({
  setSwitchPosition,
  switchPosition,
  switchPositionList,
}: IButtonSwitcherProps) => {
  return (
    <Select
      className="lg:hidden min-w-[20rem] max-w-[43.75rem] mb-12"
      value={switchPosition}
      options={switchPositionList}
      onChange={setSwitchPosition}
    />
  );
};
