import { useRecoilValue } from 'recoil';
import { filterCategoryState } from '../../state';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Query, collection, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { IFirebaseProduct } from '../../types';
import { DataOptions } from 'react-firebase-hooks/firestore/dist/firestore/types';
import { useState } from 'react';
import { Product } from '../../state/models/Product';
import { shownProductsPerPage } from '../../appconfig';

const db = collection(firestore, 'products') as Query<IFirebaseProduct>;

const options: DataOptions<IFirebaseProduct> = {
  snapshotListenOptions: { includeMetadataChanges: true },
};

export function useProductByCategoriesList () {
  const selectedCategory = useRecoilValue(filterCategoryState);

  const [chunkIndex, setChunkIndex] = useState(shownProductsPerPage);

  //create  query and fetch data from the Firebase
  const selectedByCategoriesQuery = query(
    db,
    where('categories', 'array-contains', selectedCategory)
  );
  const [loadedProducts, loading, error] = useCollection<IFirebaseProduct>(
    selectedByCategoriesQuery,
    options
  );

  const allProducts = loadedProducts
    ? loadedProducts.docs
      .filter((p) => p.data().categories?.some((c) => c === selectedCategory))
      .map((p) => new Product({ firebaseId: p.id, ...p.data() }))
    : [];

  const visibleProducts = allProducts.slice(0, chunkIndex);
  const allShown = allProducts.length <= visibleProducts.length;

  const loadMore = (extra: number) => setChunkIndex((prev) => prev + extra);
  const showDefaultQuantity = () => setChunkIndex(shownProductsPerPage);

  return {
    products: visibleProducts,
    loading,
    error,
    loadMore,
    showDefaultQuantity,
    allShown,
    allProducts,
  };
}
