import { SectionMainText } from './SectionMainText';

export const CallToActionDescription = () => {
  return (
    <SectionMainText className=" lg:text-center">
      No matter what project you're working on, we've got you covered with the
      best component for any platform.
    </SectionMainText>
  );
};
