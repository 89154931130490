import { AdaptiveErrorSection } from './AdaptiveErrorSection';
import SadNoResults from '../../assets/sadNoResult.png';
import { Routes } from '../../util/constants';

export function EmptyDataResponse () {
  return (
    <div className="lg:min-h-[480px] flex justify-center items-center py-10 lg:py-0">
      <AdaptiveErrorSection
        imgUrl={SadNoResults}
        title="Oops!"
        buttonText="Talk to expert"
        to={Routes.talkToExpert()}
      >
        We don't have that yet... but you can help!
      </AdaptiveErrorSection>
    </div>
  );
}
