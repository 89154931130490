import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { User, browserLocalPersistence, browserSessionPersistence } from 'firebase/auth';
import {
  getAuth, GoogleAuthProvider, signOut,
  createUserWithEmailAndPassword,
  signInWithPopup, signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail
} from 'firebase/auth';

import { httpsCallable, getFunctions } from 'firebase/functions';

const app = initializeApp({
  apiKey           : process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain       : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId        : process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket    : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId            : process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId    : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const firestore = getFirestore(app);
const cloudFunctions = getFunctions(app);
const sendConfirmEmailToNewUser = httpsCallable(cloudFunctions, 'confirmEmailToNewUser');

const acceptConfirmEmail = httpsCallable(cloudFunctions, 'acceptConfirmEmail');

const registerContactUsMessage = httpsCallable(cloudFunctions, 'registerContactUsMessage');
const updatePaymentMethod = httpsCallable(cloudFunctions, 'updatePaymentMethod');
const createSubscription = httpsCallable(cloudFunctions, 'createSubscription');

const signInWithGoogle = async () => {
  await signInWithPopup(auth, googleProvider);
};

const signInWithCredentials = async (
  { email, password }: { email: string, password: string }
) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const signOutAction = () => {
  return signOut(auth);
};

const sendInviteEmail = async (user: User) => {
  return sendConfirmEmailToNewUser({
    email: user.email,
    role: 'admin'
  });
};

const registerWithCredentials = async (
  { email, password, name }: { email: string, password: string, name: string }
) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);

  await updateProfile(userCredential.user, { displayName: name });
  await sendInviteEmail(userCredential.user);
};

const confirmEmail = async (confirmId: string) => {
  await acceptConfirmEmail({ confirmId });
};

const resetPassword = async (email: string) => {
  await sendPasswordResetEmail(auth, email);
};

const setAuthPersistence = (persistent: boolean) => {
  auth.setPersistence(
    persistent
      ? browserLocalPersistence
      : browserSessionPersistence
  );
};

const installProduct = httpsCallable(cloudFunctions, 'installProduct');
const linkProductToApp = httpsCallable(cloudFunctions, 'linkProductToApp');

export {
  firestore,
  auth,
  signInWithGoogle,
  signInWithCredentials,
  registerWithCredentials,
  resetPassword,
  setAuthPersistence,
  signOutAction as signOut,
  confirmEmail,
  registerContactUsMessage,
  updatePaymentMethod,
  createSubscription,
  installProduct,
  linkProductToApp
};
