import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export default function ProductListWrapper ({ children, className = '' }: PropsWithChildren<Props>): JSX.Element {
  return (
    <div
      className={`
        category-wrapper py-6
        grid grid-cols-[repeat(auto-fill,_minmax(350px,1fr))] auto-rows-max
        gap-6 justify-start
        lg:gap-3
        xl:gap-6 xl:grid-cols-3
        min-h-[200px]
        ${className}
      `}
    >
      {children}
    </div>
  );
}
