import { PropsWithChildren } from 'react';

export const HeroText = ({ children }: PropsWithChildren) => {
  return (
    <p
      className="text-lg leading-[1.44] tracking-[0.014rem] opacity-60
            lg:text-xl lg:leading-[1.4] lg:tracking-[-0.28px]"
    >
      {children}
    </p>
  );
};
