import { useCurrentProduct } from '../../hooks';
import { EmptyDataResponse } from '../generic';
import { SubPageHeader } from './SubPageHeader';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const Cases = () => {
  const { product } = useCurrentProduct();

  if (!product || !product.cases) {
    return <EmptyDataResponse />;
  }

  return (
    <div className="h-auto">
      <div className="">
        <SubPageHeader />
        <div className="flex flex-col lg:flex-row mx-4 xl:mx-28 lg:my-6 border-t-2 text-justify">
          <div className="font-semibold mt-6 lg:mr-8 xl:mr-16">Cases</div>
          <div className="flex flex-col lg:mr-6 xl:mr-36">
            {product.cases.map((productCase) => (
              <div
                id={productCase.id}
                key={productCase.id}
                className="lg:first:mt-8"
              >
                <img
                  src={productCase.image.url}
                  className="my-6 lg:my-8 w-96 h-72 rounded-md"
                  alt={productCase.image.alt}
                />
                <div className="text-cosmith-primary-900/70">
                  <ReactMarkdown
                    className="prose-sm prose-ul:list-disc prose-ol:list-decima prose-a:underline underline-offset-2"
                    children={productCase.text}
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
