import { ResponsivePicture } from '../generic/ResponsivePicture';
import Video_mobile from '../../assets/talk-to-an-expert/Video_mobile.png';
import Video_tablet from '../../assets/talk-to-an-expert/Video_tablet.png';

const images = {
  mobile: {
    src: Video_mobile,
    width: 358,
  },
  tablet: {
    src: Video_tablet,
    width: 996,
  },
  pc: {
    src: Video_tablet,
    width: 996,
  },
};

export const VideoSection = () => {
  return (
    <div className="flex items-center justify-center py-12 lg:py-16">
      <ResponsivePicture
        imageUrl={Video_tablet}
        images={images}
        className=""
      />
    </div>
  );
};
