export const maxMobileScreen = '1023px';
export const maxTabletScreen = '1279px';
export const pcScreen = '1280px';

// TODO: change from 3 to 30
export const shownProductsPerPage = 3;
export const shownProductsPerPageInCategoryPage = 9;

export const componentProgressStatus = 'in_progress';

export const requestComponentLink = 'https://forms.gle/3MWjVi4PtZFgVqtB7';

export const youtubeLink = 'https://www.youtube.com/channel/UC2ntDjagRjRguAES8W9RNQA';

export const twitterLink = 'https://twitter.com/cosmith_lowcode';

export const githubLink = 'https://github.com/cosmith-llc';
