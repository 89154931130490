import React, { ReactNode } from 'react';

interface ParagraphProps {
  children: ReactNode;
  className?: string;
}

const Paragraph: React.FC<ParagraphProps> = ({ children, className = '' }) => (
  <p className={`mb-4 ${className}`}>{children}</p>
);

export default Paragraph;