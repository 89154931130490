interface Props {
  src: string;
  className?: string;
}

export default function InstallationVideo ({ src, className = '' }: Props): JSX.Element {
  return (
    <div className={`installation-video ${className} w-full space-y-3`}>
      <div className="font-medium">Video Instructions</div>

      <div className="video-wrapper rounded-lg overflow-hidden relative pb-[56.25%]">
        <iframe
          className="h-full w-full absolute top-0 left-0"
          src={src}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    </div>
  );
}
