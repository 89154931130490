import { useCallback } from 'react';
import { confirmEmail } from '../../lib/firebase';
import  { useParams, useNavigate } from 'react-router-dom';
import type { ConfirmRouteParams } from '../../types';
import { Routes } from '../../util/constants';
import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../state';

export function useConfirmEmailForm () {
  const setLoading = useSetRecoilState(loadingState);
  const { confirmId } = useParams<ConfirmRouteParams>();
  const navigate = useNavigate();

  const onSubmit = useCallback(async () => {
    if (confirmId) {
      setLoading(true);

      try {
        await confirmEmail(confirmId);
        navigate(Routes.login());
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    }
  }, [confirmId]);

  return {
    onSubmit
  };
}
