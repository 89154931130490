import { useGetUserCases } from '../../hooks/api/useGetUserCases';
import { Loader } from '../generic/Loader';
import { CaseCard } from './CaseCard';

export const CaseList = () => {
  const { allUserCases, loading } = useGetUserCases();

  if (loading) {
    return <Loader />;
  }

  return (
    <ul className="flex flex-col gap-4 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-6">
      {allUserCases.map((caseItem) => (
        <CaseCard case={caseItem} key={caseItem.id} />
      ))}
    </ul>
  );
};
