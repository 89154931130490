import { CallToAction } from '../components/generic/landing/CallToAction';
import { NoCodePlatformUsageSection } from '../components/user-case/NoCodePlatformUsageSection';
import { TypesOfApplications } from '../components/user-case/TypesOfApplications';

export const UserCase = () => {
  return (
    <>
      <NoCodePlatformUsageSection />
      <TypesOfApplications />
      <CallToAction />
    </>
  );
};
