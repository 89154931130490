import { NavLinkButton } from '../../generic/NavLinkButton';
import { scrollToTop } from '../../../util/scrollToTop';

export const TalkToAnExpertButton = () => {
  return (
    <NavLinkButton
      to="/talk-to-expert"
      type="primary"
      size="normal"
      className="min-w-[19rem] max-w-[25rem] lg:min-w-[9.75rem] lg:max-w-[9.75rem]"
      onClick={scrollToTop}
    >
      Talk to an expert
    </NavLinkButton>
  );
};
