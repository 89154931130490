import ComponentServiceInfoSection from './ComponentServiceInfoSection';
import MyComponentButtons from './MyComponentButtons';
import { ProductImage } from '../generic';
import { AccountComponent } from '../../state/models';

interface Props {
  className?: string;
  component: AccountComponent
}

export default function MyComponentCard ({ className = '', component }: Props): JSX.Element {
  const { product, installation } = component;

  return (
    <div
      className={`
        ${className} component-card
        grid grid-cols-[106px,1fr,132px,156px] gap-5 items-center
        border-solid border border-cosmith-primary-900/10 rounded-xl
        w-full p-6
      `}
    >
      <ProductImage src={product.imageUrl} alt={product.name} />

      <section className="base-info-section">
        <div className="text-2xl text-cosmith-primary-900 font-semibold">{product.title}</div>
        <div className="text-cosmith-primary-900/60 mt-2">{product.description}</div>
      </section>

      <ComponentServiceInfoSection
        className="gap-y-3 items-center"
        isTrial={installation.isInstalled && installation.isTrial}
        daysLeft={installation.daysLeft}
        price={product.price}
      />

      <section className={`action-section flex flex-col gap-3 ${installation.isSubscription && 'flex-col-reverse'}`}>
        <MyComponentButtons
          productId={product.id}
          productName={product.name}
          isTrial={installation.isTrial}
          isInstalled={installation.isInstalled}
        />
      </section>
    </div>
  );
}
