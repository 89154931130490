import { SetterOrUpdater } from 'recoil';
import { Select, SelectOptions } from '../generic/Select';

interface Props {
  value: string;
  onChange: SetterOrUpdater<string>;
  className?: string;
}

export default function ProductSorter ({ value, onChange, className = '' }: Props): JSX.Element {

  const sortList: SelectOptions[] = [
    { id: '1', value: 'top', label: 'Top' },
    { id: '2', value: 'viewers',   label: 'Viewers' },
    { id: '3', value: 'beta',    label: 'Beta' },
  ];

  return (
    <Select
      className={className}
      label="Show apps by"
      value={value}
      options={sortList}
      onChange={onChange}
    />
  );
}
