export const ArrowRight = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="ml-1"
    >
      <path
        d="M13.4773 9.16591L9.00732 4.69591L10.1857 3.51758L16.6673 9.99924L10.1857 16.4809L9.00732 15.3026L13.4773 10.8326H3.33398V9.16591H13.4773Z"
        className={className}
      />
    </svg>
  );
};
