export const GoogleIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" transform="translate(0.456421 0.342346)" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M23.4964 12.6037C23.4964 11.7883 23.4232 11.0042 23.2873 10.2515H12.4564V14.6999H18.6455C18.3789 16.1374 17.5687 17.3553 16.3507 18.1708V21.0562H20.0673C22.2419 19.0542 23.4964 16.106 23.4964 12.6037Z" fill="#4285F4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4564 23.8422C15.5614 23.8422 18.1646 22.8124 20.0673 21.056L16.3507 18.1706C15.3209 18.8606 14.0037 19.2683 12.4564 19.2683C9.46116 19.2683 6.92593 17.2453 6.02161 14.5272H2.17957V17.5067C4.07184 21.2651 7.96093 23.8422 12.4564 23.8422Z" fill="#34A853"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.02165 14.5274C5.79165 13.8374 5.66097 13.1004 5.66097 12.3424C5.66097 11.5844 5.79165 10.8474 6.02165 10.1574V7.17786H2.1796C1.40074 8.73036 0.956421 10.4867 0.956421 12.3424C0.956421 14.1981 1.40074 15.9544 2.1796 17.5069L6.02165 14.5274Z" fill="#FBBC05"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4564 5.41621C14.1448 5.41621 15.6607 5.99644 16.8525 7.13598L20.1509 3.83757C18.1593 1.98189 15.5562 0.842346 12.4564 0.842346C7.96093 0.842346 4.07184 3.41939 2.17957 7.1778L6.02161 10.1573C6.92593 7.43916 9.46116 5.41621 12.4564 5.41621Z" fill="#EA4335"/>
    </svg>
  );
};