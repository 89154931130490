import { HelpSectionItem } from './HelpSectionItem';
import { sections } from './SectionsData';
import { Section } from './helpPageTypes';
export const ClientInfoSections = () => {
  return (
    <ul>
      {sections.map((section: Section) => {
        return <HelpSectionItem section={section} key={section.id} />;
      })}
    </ul>
  );
};
