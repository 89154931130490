import { AuthHeader } from '../generic/AuthHeader';
import { LoginInfoSection } from './LoginInfoSection';

import iconUrl1 from '../../../assets/auth/login-icon-1.svg';
import iconUrl2 from '../../../assets/auth/login-icon-2.svg';
import iconUrl3 from '../../../assets/auth/login-icon-3.svg';

export function LoginInfo () {
  return (
    <div className="login-info">
      <AuthHeader>
        Need the development of own application?
      </AuthHeader>

      <section className="space-y-6 mt-8">
        <LoginInfoSection
          icon={iconUrl1}
          text="Quick App Launch: cosmith.io allows for fast working with no-code platforms creation with pre-made apps, interface, elements and database tools."
        />

        <LoginInfoSection
          icon={iconUrl2}
          text="Cosmith.io apps does not require technical knowledge and can be used even by app development novices."
        />

        <LoginInfoSection
          icon={iconUrl3}
          text="Lower Development Costs: flexible pricing system, including a free plan, helps users reduce app development expenses."
        />
      </section>
    </div>
  );
}
