import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { Button } from '../generic';
import type { IBillingData } from '../../types';

interface Props {
  data: IBillingData[];
  className?: string;
}

const items = [
  { label: 'Native Photo Camera', value: 11.32 },
  { label: 'Native Photo Camera', value: 11.32 },
  { label: 'Native Photo Camera', value: 11.32 },
  { label: 'Native Photo Camera', value: 11.32 },
  { label: 'Native Photo Camera', value: 11.32 }
];

const summary = [
  { label: 'Subtotal', value: 45.28 },
  { label: 'Taxes',    value: 45.28 },
  { label: 'Total',    value: 45.28 }
];

export default function MonthToDateSummary ({ className = '' }: Props): JSX.Element {
  return (
    <div className={`month-to-date-summary ${className}`}>
      <section className="lg:flex justify-between items-end text-center lg:text-left">
        <div>
          <div className="font-semibold">Month-to-date Summary</div>
          <div className="text-cosmith-primary-900/60 mt-3">These charges are factored into your account balance.</div>
        </div>

        <Button type="link" className="space-x-3 mx-auto lg:mx-0">
          <span>Download: CSV</span>
          <ArrowDownTrayIcon className="w-5 h-5" />
        </Button>
      </section>

      <div className="mt-5">
        {items.map((item, index) => (
          <div key={index} className="grid grid-cols-[1fr_90px] items-center lg:border-b h-[50px] lg:px-6">
            <span>{item.label}</span>
            <span>${item.value}</span>
          </div>
        ))}
      </div>

      <div className="mt-6">
        {summary.map((item, index) => (
          <div key={index} className="grid grid-cols-[repeat(3,1fr)_90px] lg:grid-cols-[repeat(3,1fr)_90px] items-center justify-start h-[50px] lg:px-6">
            <span></span>
            <span></span>
            <span>{item.label}</span>
            <span className="font-semibold">${item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
