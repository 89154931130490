import { PropsWithChildren } from 'react';

export const HeroTitle = ({ children }: PropsWithChildren) => {
  return (
    <h1
      className="text-4xl font-semibold leading-[1.17] tracking-[-0.041rem] mb-4 
      lg:text-[4rem] lg:leading-[1.06] lg:tracking-[-0.08rem] lg:mb-6"
    >
      {children}
    </h1>
  );
};