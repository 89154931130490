import { PropsWithChildren } from 'react';

interface IHowCosmithWorksItemProps {
  number: string;
}

export const HowCosmithWorksItem = ({
  children,
  number,
}: PropsWithChildren<IHowCosmithWorksItemProps>) => {
  return (
    <li className="group max-w-[700px] p-6 hover:outline rounded-lg outline-cosmith-primary-900/10 hover:outline-1 &:hover-switcher">
      <div className="flex gap-6 mb-4 h-[51px] items-center">
        <div
          className='rounded-full flex w-12 h-12 justify-center items-center group-hover:bg-cosmith-green-50
                     before:content-[" "] before:bg-cosmith-lavanda-400 before:w-[13px] before:h-[13px] 
                     before:rounded-full before:group-hover:bg-white'
        ></div>
        <p className="text-xl font-semibold leading-7 tracking-[-0.0175rem]">
          {number}
        </p>
      </div>
      <p className="leading-[1.375rem] tracking-[-0.01rem] text-center text-cosmith-primary-900/60">
        {children}
      </p>
    </li>
  );
};
