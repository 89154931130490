import { AdaptiveImageCard } from '../generic/AdaptiveImageCard';
import { DottedWrapperHero } from '../generic/landing/DottedWrapperHero';
import Hero_mob from '../../assets/main-page/Hero-mobile.png';
import Hero_tablet from '../../assets/main-page/Hero-tablet.png';
import Hero_pc from '../../assets/main-page/Hero-pc.png';
import { ResponsivePicture } from '../generic/ResponsivePicture';
import { TrustpilotService } from '../generic/landing/TrustpilotService';
import { TrustedBy } from './TrustedBy';
import { HeroTitle } from '../generic/landing/HeroTitle';
import { HeroText } from '../generic/landing/HeroText';
import { TalkToAnExpertButton } from '../generic/landing/TalkToAnExpertButton';
import { BuyNowButton } from '../generic/landing/BuyNowButton';

const images = {
  mobile: {
    src: Hero_mob,
    width: 358,
  },
  tablet: {
    src: Hero_tablet,
    width: 482,
  },
  pc: {
    src: Hero_pc,
    width: 588,
  },
};

export const HeroSection = () => {
  return (
    <DottedWrapperHero>
      <div className="pt-12 pb-4 lg:pb-0 lg:pt-0 lg:mr-[-2rem] xl:mr-0">
        <AdaptiveImageCard
          secondChildren={
            <ResponsivePicture imageUrl={Hero_pc} images={images} />
          }
        >
          <div className="flex flex-col gap-6 xl:gap-8">
            <div className="flex justify-center lg:justify-start">
              <TrustpilotService />
            </div>

            <div className="text-center lg:text-left">
              <HeroTitle>Automate work with no-code platforms</HeroTitle>
              <HeroText>
                Cosmith.io automate your work across 10+ apps—so you can move
                forward faster with any no code platform
              </HeroText>
              <div className="hidden lg:block ">
                <TrustedBy />
              </div>
            </div>

            <div className="flex items-center flex-col gap-[0.75rem] lg:flex-row">
              <BuyNowButton />
              <TalkToAnExpertButton />
            </div>
          </div>
        </AdaptiveImageCard>
        <div className="lg:hidden ">
          <TrustedBy />
        </div>
      </div>
    </DottedWrapperHero>
  );
};
