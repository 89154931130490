type Props = {
  className?: string;
  text: string;
}

export function FormHeader ({ className = '', text }: Props) {
  return (
    <h2
      className={[
        'cosmith-form-header',
        'text-4xl font-semibold text-center tracking-[-0.04em]',
        'w-full mb-3',
        className
      ].join(' ')}
    >
      {text}
    </h2>
  );
}
