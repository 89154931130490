import * as yup from 'yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldState } from '../auth/useFieldState';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerContactUsMessage } from '../../../src/lib/firebase';

const emailRegex =
  /^([a-zA-Z0-9]+){1}([a-zA-Z0-9?'"`#$%&*+-_./|^{}~]+){1}@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,3})$/;

const schemaBuilder = () =>
  yup.object({
    name: yup.string().min(5).max(50).required(),
    email: yup
      .string()
      .matches(emailRegex, 'Please provide a valid email')
      .required(),
    message: yup.string().min(5).max(500).required(),
    subscribe: yup.boolean(),
  });

export const useHelpExpertForm = () => {
  const [subscribe, setSubscribe] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schemaBuilder()),
  });

  const onSubmit = useCallback(
    async (data: yup.InferType<ReturnType<typeof schemaBuilder>>) => {
      //ToDO :To show that form is working properly (later will be sent all data to email). Start:
      const requestInfo = {
        ...data,
        subscribe,
      };

      //End
      try {
        setIsLoading(true);
        const contactUsMessage = {
          fullName: requestInfo.name,
          email: requestInfo.email,
          message: requestInfo.message,
          isSubscribed: requestInfo.subscribe 
        };

        await registerContactUsMessage(contactUsMessage);
        toast.success('Thank you. We will contact you ASAP');
        setIsLoading(false);
        reset();
      } catch (error) {
        toast.error('Something went wrong. Please try again');
        console.log(error);
      }
    },
    []
  );

  return {
    name: {
      register: register('name'),
      state: useFieldState(formState.errors.name?.message),
    },
    email: {
      register: register('email'),
      state: useFieldState(formState.errors.email?.message),
    },
    message: {
      register: register('message'),
      state: useFieldState(formState.errors.message?.message),
    },
    subscribe: {
      register: {
        register: 'subscribe',
        checked: subscribe,
        onChange: (e: { target: HTMLInputElement }) =>
          setSubscribe(e.target.checked),
      },
      state: {},
    },

    onSubmit: handleSubmit(onSubmit),
    isLoading:isLoading
  };
};
