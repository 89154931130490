import { Routes } from '../../util/constants';
import { NavLinkButton } from './';

export function AuthenticateFirstHint () {
  return (
    <div className="py-10 lg:py-0">
      <p className="text-center italic">Please log in before using this functionality.</p>

      <NavLinkButton
        className='mt-8'
        type="primary"
        to={Routes.login()}
      >
        Go to login page
      </NavLinkButton>
    </div>
  );
}
