import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from '../../Button';
import { FormControl, FormHint, FormSelect } from '../../form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldState } from '../../../../hooks/auth/useFieldState';
import { ISubscriptionOptions } from '../../../../types';
import { useCountries } from '../../../../hooks/useCountries';

interface Props {
  className?: string;
  onError: (error: string) => void;
  onSuccess: (options: ISubscriptionOptions) => void;
}

export function StripeForm ({ className = '', onError, onSuccess }: Props): JSX.Element {
  const [error, setError] = useState<string>('');
  const [isStateNeeded, setIsStateNeeded] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  const { register, handleSubmit, formState, watch, setValue } = useForm({
    resolver: yupResolver(yup.object({
      country: yup.string().required(),
      state  : isStateNeeded ? yup.string().required() : yup.string()
    }).required()),
    defaultValues: {
      country: '',
      state  : ''
    }
  });

  const countryState = useFieldState(error || formState.errors.country?.message);
  const stateState   = useFieldState(error || formState.errors.state?.message);

  const { countries, states } = useCountries(watch('country'));

  useEffect(() => {
    setValue('state', '');
    setIsStateNeeded(states.length > 0);
  }, [states]);

  const handleSubscribe = handleSubmit(async ({ country, state }) => {
    setError('');

    if (!elements || !stripe) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError && submitError.message) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });

    if (stripeError) {
      onError(stripeError.message || 'Unknown stripe error occurred');
    } else {
      const options: ISubscriptionOptions =  {
        paymentMethodId: paymentMethod.id,
        country
      };

      if (state) {
        options.state = state;
      }

      onSuccess(options);
    }
  });

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className={`${className} space-y-6`}
    >
      <FormControl variant={countryState.variant}>
        <FormSelect
          id="country"
          placeholder="Select your country"
          options={countries}
          {...register('country')}
        />
        <FormHint hint={countryState.message} />
      </FormControl>

      {isStateNeeded && (
        <FormControl variant={stateState.variant}>
          <FormSelect
            id="state"
            placeholder="Select your state"
            options={states}
            {...register('state')}
          />
          <FormHint hint={stateState.message} />
        </FormControl>
      )}

      <div className="px-3 py-3.5 border border-solid rounded-lg border-cosmith-primary-900/10 shadow">
        <CardElement />
      </div>

      <Button className="w-full" onClick={handleSubscribe}>
        Subscribe now
      </Button>
    </form>
  );
}
