import { LabelHTMLAttributes } from 'react';
import { FormItemVariant } from '../../../types';

type Props = LabelHTMLAttributes<HTMLLabelElement> & {
  variant?: FormItemVariant;
}

const VARIANT_PROPS: Record<FormItemVariant, string> = {
  normal : '',
  success: '',
  error  : ''
};

export function FormLabel (props: Props) {
  const { className = '', variant = 'normal', ...restProps } = props;

  return (
    <label
      className={[
        'cosmith-form-label',
        'block font-medium',
        VARIANT_PROPS[variant],
        className
      ].join(' ')}
      {...restProps}
    />
  );
}
