import { useMemo } from 'react';
import { useProductList } from './useProductList';
import { AccountComponent } from '../../state/models';
import { useMyInstallations } from './useMyInstallations';
import { Installation } from '../../state/models/Installation';

export function useMyComponentList () {
  const { products } = useProductList();
  const { installations, loading: iLoading } = useMyInstallations();
  

  const components = useMemo(() => {
    if (!installations || !products.length) {
      return [];
    }

    const results: AccountComponent[] = [];

    for (const installation of installations) {
      const product = products.find((p) => p.libraryId === installation.libraryId);

      if (product) {
        results.push(
          new AccountComponent({
            product,
            installation: new Installation(installation),
          })
        );
      }
    }

    return results;
  }, [products, installations]);

  return { components, loading: iLoading };
}
