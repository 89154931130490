import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  productSearchState,
  productSortState, productFilterState
} from '../../state';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Query, collection } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { IFirebaseProduct } from '../../types';
import { Product } from '../../state/models';
import { DataOptions } from 'react-firebase-hooks/firestore/dist/firestore/types';
import { includeIgnoreCase } from '../../util';

const query = collection(firestore, 'products') as Query<IFirebaseProduct>;

const options: DataOptions<IFirebaseProduct> = {
  snapshotListenOptions: { includeMetadataChanges: true },
};

type QueryOptions = {
  status: 'ready_to_install' | 'all';
};
const advanseCategory = {
  marketing: [
    'AppsFlyer SDK\'s in-app events tracking',
    'Facebook SDK\'s in-app events tracking',
    'Customer.io push notification',
  ],
  contentCreatorAndViewe: [
    'Document Viewer',
    'PDF Generator',
    'Simple web image editor',
    'Vertical Image list',
  ],
  device: [
    'Audio Recorder',
    'Native Photo Camera',
    'Adalo Bluetooth printer',
    'NFC Background Reader',
    'NFC Reader',
    'Camera',
    'Native Video Camera',
    'File Downloader',
    'Native Video Player (HLS/M3u8) with Web supports',
  ],
};

function productIncludes (searchTerm: string, product: Product) {
  return (
    includeIgnoreCase(product.name, searchTerm) ||
    includeIgnoreCase(product.searchString, searchTerm) ||
    includeIgnoreCase(product.description, searchTerm)
  );
}
function productsFilterbyCategory (category: string, product: Product) {
  switch (category) {
    case 'Marketing tool':
      return advanseCategory.marketing.includes(product.title);
    case 'Content creator and viewer':
      return advanseCategory.contentCreatorAndViewe.includes(product.title);
    case 'Device':
      return advanseCategory.device.includes(product.title);
    case 'Other':
      return (
        !advanseCategory.device.includes(product.title) &&
        !advanseCategory.contentCreatorAndViewe.includes(product.title) &&
        !advanseCategory.marketing.includes(product.title) &&
        !productIncludes(category, product)
      );
    default:
      return productIncludes(category, product);
  }
}
export function useProductList (
  queryOptions: QueryOptions = { status: 'all' }
) {
  const filter = useRecoilValue(productFilterState);
  const search = useRecoilValue(productSearchState);
  const sort = useRecoilValue(productSortState);


  const [loadedProducts, loading, error] = useCollection<IFirebaseProduct>(
    query,
    options
  );

  const allProducts = useMemo(() => {
    if (!loadedProducts) {
      return [];
    }

    return loadedProducts.docs
      .filter((p) => queryOptions.status === 'all' || p.data().status === queryOptions.status)
      .map((p) => new Product({ firebaseId: p.id, ...p.data() }));
  }, [loadedProducts]);

  const products = useMemo(() => {
    let result = [...allProducts];

    if (search) {
      const searchString = search.toLowerCase();

      result = result.filter((p) => p.searchString.search(searchString) > -1);
    }
    if(filter){
      
      result= result.filter(product=>productsFilterbyCategory(filter, product));
    }

    if (sort) {
   
      result= result.filter((p) => p.categories?.includes(sort));
    }

    return result;
  }, [allProducts, search, sort,filter]);

  return {
    products,
    loading,
    error,
  };
}
