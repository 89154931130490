import { useRef } from 'react';

export function useAnchor () {
  const anchorRef = useRef<HTMLDivElement>(null);
  const anchor = <div aria-hidden ref={anchorRef}></div>;

  const scrollToAnchor = () => {
    if (anchorRef.current) {
      const { top } = anchorRef.current.getBoundingClientRect();
      window.scrollTo({
        top: top + window.scrollY,
        behavior: 'smooth',
      });
    }
  };

  return {
    anchor,
    scrollToAnchor,
  };
}
