import { BusinessFeaturesWrapper } from '../generic/landing/BusinessFeaturesWrapper';
import { HelpForm } from './HelpForm';

const features = [
  { id: 1, value: 'Extensive component library' },
  { id: 2, value: 'Expert consultation' },
  { id: 3, value: 'Top-notch support' },
  { id: 4, value: 'Develop ChatGPT Plugins' },
  { id: 5, value: 'Build custom ChatGPT knowledge base' },
];

export const HelpSection = () => {
  return (
    <div className='py-12 lg:py-0'>
      <BusinessFeaturesWrapper
        title="Our expert can do any component or ChatGPT integration"
        features={features}
        secondChildren={<HelpForm/>}
      >
        Developers can save time when searching for components that they need
        for their projects, allowing them to focus on other aspects of
        development such as coding or designing an interface without
        compromising quality or efficiency.<br/><br/>
        Enhance your solution by integrating with ChatGPT, developing plugins, or extending its capabilities using a 
        custom ChatGPT knowledge base.  Experience the benefits of seamless integration and improved functionality.
      </BusinessFeaturesWrapper>
    </div>
  );
};
