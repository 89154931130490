import { Breadcrumbs } from '../components/layout';
import { Preloader } from '../components/generic';
import EstimatedDueSummary from '../components/billing/EstimatedDueSummary';
import EstimationHistoryTable from '../components/billing/EstimationHistoryTable';
import MonthToDateSummary from '../components/billing/MonthToDateSummary';
import PaymentMethodTable from '../components/billing/PaymentMethodTable';
import BillingHistoryTable from '../components/billing/BillingHistoryTable';
import { useBillingListLoading, useScreenSize } from '../hooks';

export const BillingPage = () => {
  const screen = useScreenSize();
  const { billingData, loading } = useBillingListLoading();

  const crumbs = [
    { label: 'Billing' }
  ];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />

      <div className="h-fit flex-grow md:overflow-y-auto px-4 sm:px-3 pb-6">
        <Preloader visible={loading} />

        {!loading &&
          <>
            <EstimatedDueSummary data={billingData} />

            <EstimationHistoryTable
              className="mt-6 xl:mt-9"
              data={billingData}
              isMobile={screen.lt.lg}
            />

            <MonthToDateSummary
              className="mt-6 lg:mt-12"
              data={billingData}
            />

            <PaymentMethodTable
              className="mt-12"
              isMobile={screen.lt.lg}
            />

            <BillingHistoryTable
              className="mt-12"
              isMobile={screen.lt.lg}
            />
          </>
        }
      </div>
    </>
  );
};
