import { useGridTable } from '../../hooks';
import { ITableColumn, ITableData, ITableGrid } from '../../types';

interface Props {
  id: string;
  columns: ITableColumn[];
  data: ITableData[][];
  className?: string;
  isTitleVisible?: boolean;
  topColumn?: (grid: ITableGrid) => string | JSX.Element
  bottomColumn?: (grid: ITableGrid) => string | JSX.Element
}

export function TableGrid ({
  id,
  columns,
  data,
  className = '',
  isTitleVisible = true,
  topColumn,
  bottomColumn
}: Props):JSX.Element {
  const { grids } = useGridTable({ id, columns, data });

  return (
    <div className={`table-grid w-full space-y-3 ${id} ${className}`}>
      {grids.map((grid) => (
        <div key={grid.id} className="border-b py-4">
          {isTitleVisible && (
            <div className="font-semibold mb-4">{grid.title}</div>
          )}

          <div className="rows">
            {topColumn && topColumn(grid)}
            {grid.rows.map((row) => (
              <div key={row.id} className="grid-row grid grid-cols-[1fr_2fr] gap-x-4 py-3">
                {row.cells.map((cell,index) => (
                  <div key={cell.id} className={`w-full flex items-center overflow-hidden ${cell.className}`}>
                    <div className={`${index? 'text-cosmith-primary-900/60': ''}`}>{cell.value}</div>
                  </div>
                ))}
              </div>
            ))}
            {bottomColumn && bottomColumn(grid)}
          </div>
        </div>
      ))}
    </div>
  );
}
