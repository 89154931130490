import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export function PageSubtitle ({ children, className = '' }: PropsWithChildren<Props>): JSX.Element {
  return (
    <div className={`text-cosmith-primary-900/60 text-center my-6 mx-auto max-w-[600px] ${className}`}>
      {children}
    </div>
  );
}
