import { useCallback, useState } from 'react';
import { signOut, signInWithGoogle } from '../../lib/firebase';

export function useFirebase () {
  const [loading, setLoading] = useState(false);

  const handleSignIn = useCallback(async () => {
    try {
      setLoading(true);
      await signInWithGoogle();
    } catch (error) {
      console.log('Sign-in error:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSignOut = useCallback(async () => {
    try {
      setLoading(true);
      await signOut();
      console.log('Sign out');
    } catch (error) {
      console.log('Sign-out error:', error);
    } finally {
      setLoading(false);
    }
  }, []);


  return {
    isSigningIn: loading,
    signIn: handleSignIn,
    signOut: handleSignOut
  };
}
