import { Routes } from '../../util/constants';
import { NavLinkButton } from '../generic';

interface Props {
  productId: string;
  productName: string;
  isInstalled: boolean;
  isTrial: boolean;
}

export default function MyComponentButtons ({ productId, productName, isInstalled, isTrial }: Props): JSX.Element {
  return (
    <>
      {isInstalled && isTrial
        ? (
          <NavLinkButton
            className="w-full"
            type='dark'
            state={productId}
            to={Routes.myCategoryProductPricing(productName)}
          >
            Buy now
          </NavLinkButton>
        ) : (
          <NavLinkButton
            className="w-full"
            type='link'
            to={Routes.myCategoryProductPricing(productName)}
            state={productId}
          >
            Edit Subscription
          </NavLinkButton>
        )
      }

      <NavLinkButton
        className="w-full"
        state={productId}
        to={Routes.myCategoryProductOverview(productName)}
      >
        Learn More
      </NavLinkButton>
    </>
  );
}
