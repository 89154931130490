import moment from 'moment';
import { IFirebaseInstallationInfo } from '../../types';

export class Installation {
  constructor (
    protected readonly _data: IFirebaseInstallationInfo
  ) {}

  get id () {
    return this._data.libraryId;
  }

  get libraryId () {
    return this._data.libraryId;
  }

  get adaloAppId () {
    return this._data.adaloAppId;
  }

  get isInstalled () {
    return this._data.productInstalled;
  }

  get isSubscription () {
    return 'subscriptionCreated' in this._data;
  }

  get isTrial () {
    return !this.isSubscription;
  }

  get activePlanId () {
    return 'plan' in this._data
      ? this._data.plan.id
      : undefined;
  }

  private get trialDaysLeft () {
    return getDifDays(this._data.trialTillDate);
  }

  private get subscriptionDaysLeft () {
    return 'subscriptionCurrentPeriodEnd' in this._data
      ? getDifDays(this._data.subscriptionCurrentPeriodEnd * 1000)
      : 0;
  }

  get daysLeft () {
    if (!this.isInstalled) {
      return 0;
    }

    return this.isTrial
      ? this.trialDaysLeft
      : this.subscriptionDaysLeft;
  }
}

// helpers
function getDifDays (endTimestamp: number | undefined) {
  if (!endTimestamp) {
    return 0;
  }

  return Math.max(
    0,
    moment(endTimestamp).diff(moment(), 'days')
  );
}
