import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export default function ProductInstallationWrapper ({ className = '', children }: PropsWithChildren<Props>): JSX.Element {
  return (
    <div
      className={`
        ${className}
        product-installation-wrapper
        grid grid-cols-1 gap-6
        lg:grid-cols-[15fr_8fr] lg:gap-3
        w-full max-w-[996px] m-auto
      `}
    >
      {children}
    </div>
  );
}
