export const CrossIcon = ({ clasName }:{clasName:string}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      width='15'
      height='15'
      viewBox='0 0 20 20'
      className={clasName}
      aria-label="cross-icon"
    >
      <path d='M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z' />
    </svg>
  );
};
