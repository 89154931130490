import { useState, useCallback } from 'react';

export function useElementWidth<T extends HTMLElement> () {
  const [width, setWidth] = useState<number>(0);

  const ref = useCallback((node: T) => {
    if (!node) return;

    const resizeObserver = new ResizeObserver(() => {
      setWidth(node ? node.offsetWidth : 0);
    });

    resizeObserver.observe(node);
  }, []);

  return { ref, width };
}
