import { createPortal } from 'react-dom';
import { RotatingLines } from 'react-loader-spinner';

interface Props {
  visible: boolean;
  className?: string;
}

export function Preloader ({ visible, className = '' }: Props): JSX.Element | null {
  if (!visible) {
    return null;
  }

  return createPortal((
    <div className={[
      'absolute w-full h-full top-0 left-0',
      'flex items-center justify-center z-[2000]',
      className
    ].join(' ')}>
      <div
        className="absolute w-full h-full top-0 left-0 bg-black/25 pointer-events-none z-[100000]"
      />
      <RotatingLines
        strokeColor="#4fa94d"
        strokeWidth="5"
        animationDuration="0.75"
        width="60"
        visible={true}
      />
    </div>
  ), document.body);
}
