import { useCurrentProduct, useIsUserAuth } from '../../hooks';
import adalo from '../../assets/components/adalo-logo.png';
import { NavLinkButton } from '../generic';
import { PaymentButton } from './PaymentButton';

export const CardHeader = () => {
  const { product, installedComponent } = useCurrentProduct();
  const isUserAuth = useIsUserAuth();
  const isPurchased = installedComponent?.installation.isSubscription;

  if (!product) {
    return null;
  }

  return (
    <div className="h-auto md:h-52 md:bg-green-200">
      <div className="flex flex-col md:flex-row md:gap-3 lg:gap-6 justify-around items-center text-center sm:text-left h-full md:h-40 my-3 md:my-6 mx-4 md:px-2 lg:px-3 bg-white rounded-xl">
        <div className="order-first mb-6 md:mb-0 md:mr-4">
          <img
            src={product.imageUrl}
            alt="product logo"
            className="w-20 h-20 md:w-12 md:h-12"
          />
        </div>
        <div className="md:order-3 flex-1 mb-6 md:mb-0">
          <span className="text-4xl font-semibold md:text-2xl lg:text-4xl">
            {product.price ? `$${product.price}` : ''}
          </span>
          <span className="text-cosmith-primary-900/60 md:text-xs lg:text-base">
            {product.pricePeriod ? `/${product.pricePeriod}` : ''}
          </span>
        </div>
        <div className="md:hidden mb-6">
          {product.tags.map((tag) => (
            <span
              key={tag}
              className="inline-flex items-center rounded-2xl bg-cosmith-success-100 px-2 py-1 text-sm font-medium text-cosmith-success-600"
            >
              {tag}
            </span>
          ))}
        </div>
        <div className="flex flex-col md:order-2 md:flex-3  xl:mr-8 mb-6 md:mb-0 mb:py-1.5">
          <h4 className="text-2xl font-semibold mb-6 md:mb-0 md:text-sm lg:text-2xl">
            {product.title}
          </h4>
          <p className="text-base text-cosmith-primary-900/60 md:text-xs lg:text-base">
            {product.description}
          </p>
        </div>
        <div className="md:order-5 flex flex-col flex-1 items-center md:h-28 justify-start mb-6 md:mb-0">
          <div className="hidden md:flex md:mb-2">
            {product.tags.map((tag) => (
              <span
                key={tag}
                className="inline-flex items-center rounded-2xl bg-cosmith-success-100 px-2 py-1 text-sm font-medium text-cosmith-success-600"
              >
                {tag}
              </span>
            ))}
          </div>
          <img src={adalo} alt="app logo" />
        </div>
        <div
          className={`flex flex-col ${
            isPurchased && 'flex-col-reverse'
          } h-28 w-full justify-between md:flex-1 md:order-6`}
        >
          <PaymentButton
            isUserAuth={isUserAuth}
            installedComponent={installedComponent}
            product={product}
            isPurchased={isPurchased}
          />

          <NavLinkButton
            to={isUserAuth ? '/my/talk-to-expert' : '/talk-to-expert'}
            type="primary"
            size="normal"
          >
            Talk to an expert
          </NavLinkButton>
        </div>
      </div>
    </div>
  );
};
