import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Tag, Price, NavLinkButton, ProductImage } from '../generic';
import { Product } from '../../state/models';
import { Routes } from '../../util/constants';
import { componentProgressStatus } from '../../appconfig';
import { useIsUserAuth } from '../../hooks/auth/useIsUserAuth';
import adalo from '../../assets/components/adalo-logo.png';


interface IProductCardProps {
  className?: string;
  product: Product;
}

export default function ProductCard ({
  className = '',
  product,
}: IProductCardProps): JSX.Element {
  const isUserAuth = useIsUserAuth();
  
  return (
    <div
      className={`${className} product-card
        flex flex-col
        border-solid border border-cosmith-primary-900/10
        rounded-xl
        divide-y divide-border-cosmith-primary-900/10
        w-full p-6
      `}
    >
      <section className="head-section pb-6 min-h-[194px]">
        <div className="flex justify-between">
          <ProductImage src={product.imageUrl} alt={product.name} />
          {product.status !== componentProgressStatus ? (
            <Tag type="green" label={product.tags[0]} />
          ) : (
            ''
          )}
        </div>
        {product.status !== componentProgressStatus ? (
          <Price
            className="mt-6"
            price={product.price}
            period={product.pricePeriod}
          />
        ) : (
          ''
        )}
      </section>

      <section className="description-section grow py-6 min-h-40">
        <div className="text-2xl text-cosmith-primary-900 font-semibold">
          {product.title}
        </div>
        <div className="text-cosmith-primary-900/60 mt-2">
          {product.description}
        </div>
      </section>

      <section className="footer-section pt-6 flex flex-col">
        <img
          className="h-10 self-start"
          src={adalo}
          alt={product.name}
        />

        {product.status !== componentProgressStatus ? (
          <NavLinkButton
            type="primary"
            className="w-full mt-6"
            to={isUserAuth? Routes.myCategoryProductInstallation(product.name): Routes.openCategoryProductInstallation(product.name)}
          >
            Install
          </NavLinkButton>
        ) : (
          <NavLinkButton
            type="primary"
            className="w-full mt-6"
            to={product.applyToBetaUrl}
            target="_blank"
          >
            Apply to beta
          </NavLinkButton>
        )}

        <NavLinkButton
          type="link"
          className="w-full self-center mt-6"
          to={isUserAuth? Routes.myCategoryProduct(product.name): Routes.openCategoryProduct(product.name)}
        >
          Learn more
          <ChevronRightIcon className="h-5 w-5 ml-3" />
        </NavLinkButton>
      </section>
    </div>
  );
}
