import { Routes } from '../../util/constants';

import { AuthPageWrapper } from '../../components/auth/AuthPageWrapper';
import { RegisterInfo } from '../../components/auth/register/RegisterInfo';
import { RegisterForm } from '../../components/auth/register/RegisterForm';

export const RegisterPage = () => {
  return (
    <AuthPageWrapper
      linkText="Log in"
      linkHref={Routes.login()}
      info={<RegisterInfo />}
      form={<RegisterForm />}
    />
  );
};
