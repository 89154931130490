import { Outlet } from 'react-router-dom';
import { Navbar } from '../components/layout/ui/navbar/Navbar';
import { Footer } from '../components/layout/ui/footer/Footer';
import { AccountProvider } from '../contexts/AccountContext';
import { ScrollToTopButton } from '../components/generic/ScrollToTopButton';

export const MainPageLayout = () => {
  return (
    <AccountProvider>
      <div className='flex flex-col h-screen w-full'>
        <Navbar />
        <main className='px-4 lg:px-8 xl:px-32 max-w-[1920px] w-full m-auto flex-1'>
          <Outlet />
          <ScrollToTopButton />
        </main>
        <Footer />
      </div>
    </AccountProvider>
  );
};
