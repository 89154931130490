import { useCurrentProduct } from '../../hooks';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const SubPageHeader = () => {
  const { product } = useCurrentProduct();

  if (!product) {
    return null;
  }

  return (
    <div className="">
      <div className="flex flex-col items-center sm:items-start mx-4 xl:mx-28">
        <div className="font-semibold">Related categories</div>
        <div className="mt-3 sm:mt-6 mb-5">
          {product.relatedCategories.map((category) => (
            <span
              key={category}
              className="inline-flex items-center rounded-2xl bg-cosmith-primary-900 px-2 py-1 mr-3 sm:mr-4 my-1 sm:my-0 last:mr-0 text-sm font-medium text-white"
            >
              {category}
            </span>
          ))}
        </div>
      </div>
      <div className="mx-4 xl:mx-28">
        <div className="text-2xl font-semibold">{product.title} help you</div>
        <div className="mt-5 text-cosmith-primary-900/60">
          <ReactMarkdown
            className="prose-sm prose-ul:list-disc prose-ol:list-decimal prose-a:underline underline-offset-2"
            children={product.descriptionFull}
            remarkPlugins={[remarkGfm]}
          />
        </div>
      </div>
    </div>
  );
};
