import { useMemo, useContext } from 'react';
import { Query, collection, query } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { IFirebaseInstallationInfo } from '../../types';
import { AccountContext } from '../../contexts/AccountContext';

const options = {
  snapshotListenOptions: { includeMetadataChanges: true },
};

export function useMyInstallations () {
  const account = useContext(AccountContext);

  const requestQuery = useMemo(() => {
    return query(collection(firestore, `accounts/${account.id}/installations`)) as Query<IFirebaseInstallationInfo>;
  }, [account.id]);

  const [installations, loading, error] = useCollectionData(
    requestQuery,
    options
  );

  return { installations, loading, error };
}
