import { slide as Menu } from 'react-burger-menu';
import { NavLinkButton } from '..';

import { useScreenSize } from '../../../hooks';
import { Routes } from '../../../util/constants';
import { requestComponentLink } from '../../../appconfig';

interface IBurgerMenuProps {
  isBurgerMenuVisible?: boolean;
  onOptionClick?: () => void;
}

export const BurgerMenu = ({
  isBurgerMenuVisible,
  onOptionClick,
}: IBurgerMenuProps) => {
  const screen = useScreenSize();

  const isResponsive = screen.xxs;

  const handleOnClose = () => {
    onOptionClick && onOptionClick();
  };

  return (
    <Menu
      className='bg-white ml-[-16px]'
      width={`${isResponsive ? '100%' : '390px'}`}
      isOpen={isBurgerMenuVisible}
      onClose={handleOnClose}
    >
      <nav className='pt-12 pb-6 px-4 border-y-2 mb-6'>
        <ul className='flex flex-col gap-9 '>
          <li className='flex'>
            <div onClick={() => onOptionClick && onOptionClick()}>
              <NavLinkButton to='/category' type='link' size='extraSmall'>
                Category
              </NavLinkButton>
            </div>
          </li>
          <li className='flex'>
            <div onClick={() => onOptionClick && onOptionClick()}>
              <NavLinkButton to='/solutions' type='link' size='extraSmall'>
                Solutions
              </NavLinkButton>
            </div>
          </li>
          <li className='flex'>
            <div onClick={() => onOptionClick && onOptionClick()}>
              <NavLinkButton to='/user-case' type='link' size='extraSmall'>
                User case
              </NavLinkButton>
            </div>
          </li>
          <li className='flex'>
            <div onClick={() => onOptionClick && onOptionClick()}>
              <NavLinkButton to='/work' type='link' size='extraSmall'>
                How it works?
              </NavLinkButton>
            </div>
          </li>
          <li className='flex'>
            <div onClick={() => onOptionClick && onOptionClick()}>
              <NavLinkButton
                to={requestComponentLink}
                type='link'
                size='extraSmall'
                target='_blank'
              >
                Request a component
              </NavLinkButton>
            </div>
          </li>
          <li className='flex'>
            <div onClick={() => onOptionClick && onOptionClick()}>
              <NavLinkButton to='/talk-to-expert' type='link' size='extraSmall'>
                Talk to an expert
              </NavLinkButton>
            </div>
          </li>
        </ul>
      </nav>
      <div className='px-4'>
        <div onClick={() => onOptionClick && onOptionClick()}>
          <NavLinkButton to={Routes.login()} type='primary' className='mb-2'>
            Login
          </NavLinkButton>
        </div>
        <div onClick={() => onOptionClick && onOptionClick()}>
          <NavLinkButton to={Routes.register()} type='dark'>
            Signup
          </NavLinkButton>
        </div>
      </div>
    </Menu>
  );
};
