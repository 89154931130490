import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductRouteParams } from '../../types';
import { useMyComponentList } from './useMyComponentList';
import { installProduct, linkProductToApp } from '../../lib/firebase';
import { AccountContext } from '../../contexts/AccountContext';
import { useCurrentProduct } from './useCurrentProduct';

const INSTALLATION_STEPS = [
  { step: 0, label: 'Start' },
  { step: 1, label: 'Input Token' },
  { step: 2, label: 'Link to Adalo App' },
  { step: 3, label: 'Done' }
];

export function useComponentInstallationFlow () {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { productName } = useParams<ProductRouteParams>();
  const account = useContext(AccountContext);
  const { product } = useCurrentProduct();
  const { components } = useMyComponentList();

  const current = components.find((c) => c.product.name === productName) || null;

  useEffect(() => {
    if (current) {
      if (current.installation.adaloAppId) {
        setCurrentStep(3);
        return;
      }
      if (current.installation.isInstalled) {
        setCurrentStep(2);
        return;
      }
    }
  }, [current]);

  const onStart = () => {
    setCurrentStep(1);
  };

  const onAgain = () => {
    setCurrentStep(2);
  };

  const onCancel = () => {
    setCurrentStep(0);
  };

  const onInstallProduct = async (accessToken: string): Promise<void> => {
    try {
      setLoading(true);

      await installProduct({
        accountId   : account.id,
        productId   : product?.firebaseId,
        accessTocken: accessToken
      });
    } finally {
      setLoading(false);
    }
  };

  const onLinkProductToApp = async (adaloAppId: string): Promise<void> => {
    try {
      setLoading(true);

      await linkProductToApp({
        accountId : account.id,
        productId : product?.firebaseId,
        adaloAppId
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    steps: INSTALLATION_STEPS,
    current,
    currentStep,
    loading,

    onStart,
    onAgain,
    onCancel,
    onInstallProduct,
    onLinkProductToApp
  };
}
