import { Routes } from '../../util/constants';

import { AuthPageWrapper } from '../../components/auth/AuthPageWrapper';
import { ConfirmEmailInfo } from '../../components/auth/confirm-email/ConfirmEmailInfo';
import { ConfirmEmailForm } from '../../components/auth/confirm-email/ConfirmEmailForm';

export const ConfirmEmailPage = () => {
  return (
    <AuthPageWrapper
      linkText="Sign up"
      linkHref={Routes.register()}
      info={<ConfirmEmailInfo />}
      form={<ConfirmEmailForm />}
    />
  );
};
