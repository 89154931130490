import { PropsWithChildren } from 'react';
import { SectionTitle } from '../SectionTitle';
import { SectionMainText } from '../SectionMainText';

interface IInfoDescriptionProps {
  title: string;
  className?: string;
}

export const InfoDescription = ({
  children,
  title,
  className,
}: PropsWithChildren<IInfoDescriptionProps>) => {
  return (
    <div>
      <SectionTitle title={title} />
      <SectionMainText className={className}> {children}</SectionMainText>
    </div>
  );
};
