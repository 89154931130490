import { useMemo } from 'react';
import { FormFieldState } from '../../types';
import { upperFirst } from '../../util';

export function useFieldState (errorMessage: string | undefined) {
  const fieldState = useMemo((): FormFieldState => {
    return errorMessage
      ? {
        variant: 'error',
        message: upperFirst(errorMessage)
      } : {
        variant: 'normal'
      };
  }, [errorMessage]);

  return fieldState;
}
