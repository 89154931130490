import { IBillingData } from '../../types';

export const billingList: IBillingData[] = [
  {
    id: '595920b4-9218-44bc-b652-20fc1fdbcb88',
    product: 'Native Photo Camera',
    price: 13.6,
    boughtDate: 1642629600000,
    expireDate: 1658264400000,
    renew: true
  },
  {
    id: '793912d7-3d46-4815-86ae-00b70e7f05e5',
    product: 'Document Previewer',
    price: 11.1,
    boughtDate: 1647381600000,
    expireDate: 1658264400000,
    renew: true
  },
  {
    id: '595920b4-9218-44bc-b652-20fc1fdbcb88',
    product: 'Kakao Chat Login button',
    price: 28,
    boughtDate: 1655326800000,
    expireDate: 1664571600000,
    renew: true
  },
  {
    id: '595920b4-9218-44bc-b652-20fc1fdbcb89',
    product: 'Kakao Chat Login button',
    price: 28,
    boughtDate: 1655326800000,
    expireDate: 1664571600000,
    renew: true
  },
  {
    id: '595920b4-9218-44bc-b652-20fc1fdbcb98',
    product: 'Kakao Chat Login button',
    price: 28,
    boughtDate: 1655326800000,
    expireDate: 1664571600000,
    renew: true
  }
];
