import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export default function ProductPlanWrapper ({ children, className = '' }: PropsWithChildren<Props>): JSX.Element {
  return (
    <div
      className={`
        product-plan-wrapper py-6
        grid grid-cols-1 auto-rows-max gap-6 justify-start
        lg:gap-3 lg:grid-cols-[repeat(auto-fill,_minmax(260px,1fr))]
        xl:gap-6 xl:grid-cols-3
        ${className}
      `}
    >
      {children}
    </div>
  );
}
