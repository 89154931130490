export const CosmithIoLabel = ({ fill }: { fill?: string }) => {
  return (
    <svg width="98" height="20" viewBox="0 0 98 20" fill={fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
      <path d="M0.433594 11.5207C0.433594 10.7225 0.557468 9.97948 0.805217 9.29146C1.05297 8.58968 1.40394 7.98422 1.85815 7.47509C2.32612 6.96595 2.89044 6.5669 3.5511 6.27793C4.21177 5.98896 4.95502 5.84447 5.78085 5.84447C6.79937 5.84447 7.76284 6.03024 8.67125 6.40177L8.13446 8.4452C7.84542 8.32135 7.51509 8.21815 7.14347 8.13559C6.78561 8.05302 6.40022 8.01174 5.9873 8.01174C5.01007 8.01174 4.26682 8.32135 3.75756 8.94057C3.2483 9.54603 2.99367 10.4061 2.99367 11.5207C2.99367 12.594 3.23454 13.4471 3.71627 14.0801C4.198 14.6993 5.01007 15.0089 6.15247 15.0089C6.57915 15.0089 6.99894 14.9676 7.41186 14.8851C7.82478 14.8025 8.18264 14.6993 8.48544 14.5755L8.83642 16.6395C8.56114 16.7771 8.14135 16.901 7.57703 17.0111C7.02647 17.1212 6.45527 17.1762 5.86343 17.1762C4.94125 17.1762 4.13607 17.0386 3.44787 16.7634C2.77345 16.4744 2.20913 16.0822 1.75492 15.5869C1.31448 15.0777 0.984147 14.4791 0.763926 13.7911C0.543704 13.0893 0.433594 12.3325 0.433594 11.5207Z" fill={fill ?? '#F6F6F8'}/>
      <path d="M20.3142 11.5C20.3142 12.3532 20.1903 13.1306 19.9425 13.8324C19.6948 14.5342 19.3438 15.1328 18.8896 15.6281C18.4354 16.1235 17.8849 16.5088 17.238 16.784C16.6048 17.0592 15.9029 17.1968 15.1321 17.1968C14.3613 17.1968 13.6594 17.0592 13.0262 16.784C12.3931 16.5088 11.8494 16.1235 11.3952 15.6281C10.941 15.1328 10.5831 14.5342 10.3216 13.8324C10.0739 13.1306 9.95 12.3532 9.95 11.5C9.95 10.6469 10.0739 9.87628 10.3216 9.18826C10.5831 8.48648 10.941 7.8879 11.3952 7.39252C11.8632 6.89715 12.4137 6.51873 13.0469 6.25729C13.68 5.98208 14.3751 5.84447 15.1321 5.84447C15.8891 5.84447 16.5842 5.98208 17.2173 6.25729C17.8642 6.51873 18.4148 6.89715 18.869 7.39252C19.3232 7.8879 19.6742 8.48648 19.9219 9.18826C20.1834 9.87628 20.3142 10.6469 20.3142 11.5ZM17.7541 11.5C17.7541 10.4267 17.5201 9.58043 17.0521 8.96121C16.5979 8.32823 15.9579 8.01174 15.1321 8.01174C14.3063 8.01174 13.6594 8.32823 13.1914 8.96121C12.7372 9.58043 12.5101 10.4267 12.5101 11.5C12.5101 12.5871 12.7372 13.4471 13.1914 14.0801C13.6594 14.7131 14.3063 15.0296 15.1321 15.0296C15.9579 15.0296 16.5979 14.7131 17.0521 14.0801C17.5201 13.4471 17.7541 12.5871 17.7541 11.5Z" fill={fill ?? '#F6F6F8'}/>
      <path d="M25.5924 15.1121C26.2531 15.1121 26.7348 15.0364 27.0376 14.8851C27.3404 14.72 27.4918 14.4447 27.4918 14.0595C27.4918 13.7017 27.3266 13.4058 26.9963 13.1719C26.6797 12.938 26.1498 12.6834 25.4066 12.4082C24.9524 12.2431 24.5326 12.0711 24.1472 11.8922C23.7756 11.6995 23.4521 11.4794 23.1768 11.2317C22.9016 10.984 22.6813 10.6881 22.5162 10.3441C22.3648 9.98637 22.2891 9.55291 22.2891 9.04378C22.2891 8.05302 22.6538 7.27556 23.3833 6.71138C24.1128 6.13344 25.1038 5.84447 26.3563 5.84447C26.9894 5.84447 27.595 5.90639 28.1731 6.03024C28.7512 6.14032 29.1847 6.25041 29.4738 6.36049L29.0196 8.38327C28.7443 8.25943 28.3933 8.14935 27.9666 8.05302C27.54 7.94294 27.0445 7.8879 26.4802 7.8879C25.9709 7.8879 25.558 7.97734 25.2414 8.15623C24.9248 8.32135 24.7666 8.5828 24.7666 8.94057C24.7666 9.11946 24.7941 9.2777 24.8491 9.41531C24.918 9.55291 25.0281 9.68363 25.1795 9.80748C25.3309 9.91756 25.5304 10.0345 25.7782 10.1584C26.0259 10.2685 26.3288 10.3854 26.6866 10.5093C27.2785 10.7294 27.7808 10.9496 28.1938 11.1698C28.6067 11.3762 28.9439 11.617 29.2054 11.8922C29.4807 12.1536 29.6802 12.4564 29.8041 12.8004C29.928 13.1444 29.9899 13.5572 29.9899 14.0388C29.9899 15.0708 29.6045 15.8552 28.8338 16.3918C28.0768 16.9147 26.9894 17.1762 25.5717 17.1762C24.622 17.1762 23.8581 17.0936 23.2801 16.9285C22.702 16.7771 22.2959 16.6533 22.062 16.557L22.4955 14.4723C22.8671 14.6236 23.3076 14.7681 23.8169 14.9057C24.3399 15.0433 24.9317 15.1121 25.5924 15.1121Z" fill={fill ?? '#F6F6F8'}/>
      <path d="M38.476 11.2317C38.476 10.1033 38.3315 9.29146 38.0424 8.79609C37.7671 8.28695 37.2441 8.03238 36.4733 8.03238C36.1981 8.03238 35.8953 8.05302 35.5649 8.09431C35.2346 8.13559 34.9869 8.16999 34.8217 8.19751V16.9079H32.3235V6.46369C32.8053 6.32609 33.4315 6.19536 34.2023 6.07152C34.9869 5.94768 35.8127 5.88575 36.6798 5.88575C37.4231 5.88575 38.0287 5.98208 38.4966 6.17472C38.9784 6.36737 39.3775 6.62194 39.6941 6.93843C39.8455 6.82835 40.0382 6.71138 40.2722 6.58754C40.5062 6.46369 40.7677 6.35361 41.0567 6.25729C41.3457 6.1472 41.6486 6.05776 41.9651 5.98896C42.2955 5.92016 42.6258 5.88575 42.9561 5.88575C43.7957 5.88575 44.4839 6.0096 45.0207 6.25729C45.5712 6.49121 45.9979 6.82835 46.3007 7.26868C46.6173 7.69525 46.8306 8.21815 46.9407 8.83737C47.0646 9.44283 47.1266 10.1102 47.1266 10.8395V16.9079H44.6284V11.2317C44.6284 10.1033 44.4908 9.29146 44.2155 8.79609C43.9402 8.28695 43.4103 8.03238 42.6258 8.03238C42.2266 8.03238 41.8481 8.10119 41.4903 8.23879C41.1324 8.36263 40.864 8.48648 40.6851 8.61032C40.7952 8.95433 40.8709 9.31898 40.9122 9.70428C40.9535 10.0896 40.9741 10.5024 40.9741 10.9427V16.9079H38.476V11.2317Z" fill={fill ?? '#F6F6F8'}/>
      <path d="M52.7058 16.9079H50.2076V6.1128H52.7058V16.9079ZM52.9741 2.95478C52.9741 3.42264 52.8227 3.79417 52.5199 4.06938C52.2171 4.34458 51.8593 4.48219 51.4464 4.48219C51.0197 4.48219 50.6549 4.34458 50.3521 4.06938C50.0493 3.79417 49.8979 3.42264 49.8979 2.95478C49.8979 2.47317 50.0493 2.09475 50.3521 1.81955C50.6549 1.54434 51.0197 1.40673 51.4464 1.40673C51.8593 1.40673 52.2171 1.54434 52.5199 1.81955C52.8227 2.09475 52.9741 2.47317 52.9741 2.95478Z" fill={fill ?? '#F6F6F8'}/>
      <path d="M55.7689 3.26439L58.267 2.85158V6.1128H62.1072V8.19751H58.267V12.594C58.267 13.4609 58.4047 14.0801 58.68 14.4516C58.9552 14.8232 59.4232 15.0089 60.0839 15.0089C60.5381 15.0089 60.9372 14.9608 61.2813 14.8644C61.6392 14.7681 61.9213 14.6787 62.1278 14.5961L62.5407 16.5776C62.2517 16.7015 61.8732 16.8253 61.4052 16.9491C60.9372 17.0868 60.3867 17.1556 59.7535 17.1556C58.9828 17.1556 58.3359 17.0523 57.8128 16.8459C57.3036 16.6395 56.8975 16.3437 56.5947 15.9584C56.2919 15.5593 56.0786 15.0846 55.9547 14.5342C55.8308 13.97 55.7689 13.3302 55.7689 12.6146V3.26439Z" fill={fill ?? '#F6F6F8'}/>
      <path d="M64.5221 16.9079V1.30353L67.0202 0.890717V6.23665C67.2955 6.14032 67.6121 6.05776 67.9699 5.98896C68.3415 5.92016 68.7063 5.88575 69.0641 5.88575C69.9313 5.88575 70.647 6.0096 71.2113 6.25729C71.7894 6.49121 72.2505 6.82835 72.5946 7.26868C72.9387 7.69525 73.1795 8.21127 73.3172 8.81673C73.4686 9.42219 73.5443 10.0964 73.5443 10.8395V16.9079H71.0461V11.2317C71.0461 10.6537 71.0048 10.1652 70.9222 9.7662C70.8534 9.35338 70.7296 9.02313 70.5506 8.77545C70.3855 8.514 70.1584 8.32823 69.8693 8.21815C69.5803 8.0943 69.2224 8.03238 68.7957 8.03238C68.4654 8.03238 68.1282 8.06678 67.7841 8.13559C67.44 8.20439 67.1854 8.26631 67.0202 8.32135V16.9079H64.5221Z" fill={fill ?? '#F6F6F8'}/>
      <path d="M79.3464 15.5456C79.3464 16.0272 79.1812 16.4194 78.8509 16.7221C78.5343 17.0248 78.1489 17.1762 77.6947 17.1762C77.2267 17.1762 76.8345 17.0248 76.5179 16.7221C76.2013 16.4194 76.043 16.0272 76.043 15.5456C76.043 15.064 76.2013 14.6718 76.5179 14.3691C76.8345 14.0526 77.2267 13.8943 77.6947 13.8943C78.1489 13.8943 78.5343 14.0526 78.8509 14.3691C79.1812 14.6718 79.3464 15.064 79.3464 15.5456Z" fill={fill ?? '#F6F6F8'}/>
      <path d="M84.5414 16.9079H82.0432V6.1128H84.5414V16.9079ZM84.8097 2.95478C84.8097 3.42264 84.6583 3.79417 84.3555 4.06938C84.0527 4.34458 83.6949 4.48219 83.282 4.48219C82.8553 4.48219 82.4905 4.34458 82.1877 4.06938C81.8849 3.79417 81.7335 3.42264 81.7335 2.95478C81.7335 2.47317 81.8849 2.09475 82.1877 1.81955C82.4905 1.54434 82.8553 1.40673 83.282 1.40673C83.6949 1.40673 84.0527 1.54434 84.3555 1.81955C84.6583 2.09475 84.8097 2.47317 84.8097 2.95478Z" fill={fill ?? '#F6F6F8'}/>
      <path d="M97.4938 11.5C97.4938 12.3532 97.3699 13.1306 97.1222 13.8324C96.8745 14.5342 96.5235 15.1328 96.0693 15.6281C95.6151 16.1235 95.0645 16.5088 94.4176 16.784C93.7845 17.0592 93.0825 17.1968 92.3117 17.1968C91.541 17.1968 90.839 17.0592 90.2059 16.784C89.5727 16.5088 89.0291 16.1235 88.5749 15.6281C88.1207 15.1328 87.7628 14.5342 87.5013 13.8324C87.2535 13.1306 87.1297 12.3532 87.1297 11.5C87.1297 10.6469 87.2535 9.87628 87.5013 9.18826C87.7628 8.48648 88.1207 7.8879 88.5749 7.39252C89.0428 6.89715 89.5934 6.51873 90.2265 6.25729C90.8596 5.98208 91.5547 5.84447 92.3117 5.84447C93.0688 5.84447 93.7638 5.98208 94.397 6.25729C95.0439 6.51873 95.5944 6.89715 96.0486 7.39252C96.5028 7.8879 96.8538 8.48648 97.1016 9.18826C97.3631 9.87628 97.4938 10.6469 97.4938 11.5ZM94.9338 11.5C94.9338 10.4267 94.6998 9.58043 94.2318 8.96121C93.7776 8.32823 93.1376 8.01174 92.3117 8.01174C91.4859 8.01174 90.839 8.32823 90.371 8.96121C89.9168 9.58043 89.6897 10.4267 89.6897 11.5C89.6897 12.5871 89.9168 13.4471 90.371 14.0801C90.839 14.7131 91.4859 15.0296 92.3117 15.0296C93.1376 15.0296 93.7776 14.7131 94.2318 14.0801C94.6998 13.4471 94.9338 12.5871 94.9338 11.5Z" fill={fill ?? '#F6F6F8'}/>
    </svg>
  );
};
