import NotFound from '../assets/404.png';
import { AdaptiveErrorSection } from '../components/generic/AdaptiveErrorSection';

export const PageNotFound = () => {
  return (
    <section className="pb-10">
      <AdaptiveErrorSection
        imgUrl={NotFound}
        title="We’ve lost this page"
        buttonText="Go to main"
        to="/"
      >
        Sorry, the page you are looking for doesn't exist or has been moved.
      </AdaptiveErrorSection>
    </section>
  );
};
