import { HeroSection } from '../components/main-page/HeroSection';
import { HighQualitySection } from '../components/main-page/HighQualitySection';
import { TimeSavingsSection } from '../components/main-page/TimeSavingsSection';
import { ProductListSection } from '../components/main-page/ProductListSection';
import { CallToAction } from '../components/generic/landing/CallToAction';
import { MainSlider } from '../components/main-page/MainSlider';

export const MainPage = () => {
  return (
    <>
      <HeroSection />
      <ProductListSection />
      <HighQualitySection />
      <TimeSavingsSection />
      <MainSlider />
      <CallToAction />
    </>
  );
};
