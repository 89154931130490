import { IProductPlan } from '../../types';

export class Plan {


  constructor (
    private readonly _data: IProductPlan & { id: string }
  ) {}

  get id () {
    return this._data.id;
  }

  get name () {
    return this._data.name;
  }

  get description () {
    return this._data.description;
  }

  get features () {
    return this._data.features;
  }

  get price () {
    return this._data.price;
  }

  get paymentCycle () {
    return this._data.paymentCycle;
  }

  get status () {
    return this._data.status;
  }

  get currency () {
    return this._data.currency;
  }
  get orderNumber () {
    return this._data.orderNumber;
  }
}
