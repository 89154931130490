export const sections = [
  {
    id: '1',
    title: 'Organize and manage your cosmith.io',
    articles: [
      { id: '1', title: 'Nullam quis imperdiet augue' },
      { id: '2', title: 'Lorem ipsum dolor sit amet' },
      { id: '3', title: 'Maecenas vitae mattis' },
      { id: '4', title: 'Carpe diem' },
      { id: '5', title: 'Ad astra' },
      { id: '6', title: 'Veni, vidi, vici' },
    ],
  },
  {
    id: '2',
    title: 'Connect your app accounts to cosmith.io',
    articles: [
      { id: '4', title: 'Vestibulum auctor ornare' },
      { id: '5', title: 'Nullam quis imperdiet' },
      { id: '6', title: 'Aliquam in hendrerit urna' },
      { id: '7', title: 'In vino veritas' },
      { id: '8', title: 'Alea iacta est' },
      { id: '9', title: 'Fortes fortuna adiuvat' },
    ],
  },
  {
    id: '3',
    title: 'View and manage your cosmith.io history',
    articles: [
      { id: '7', title: 'Pellentesque sit' },
      { id: '8', title: 'Lorem ipsum dolor sit amet' },
      { id: '9', title: 'Aliquam in hendrerit urna' },
      { id: '10', title: 'Tempus fugit' },
      { id: '11', title: 'Amor vincit omnia' },
      { id: '12', title: 'Memento mori' },
    ],
  },
];
