import { FaqItem } from './FaqItem';
import { content } from './faqContent';

export const FaqSection = () => {
  return (
    <div className='py-12 lg:py-16'>
      <h2
        className='text-4xl text-center font-semibold leading-[1.17] tracking-[-0.041rem] 
      lg:text-5xl lg:leading-[1.125] lg:tracking-[-0.06rem] '
      >
        Frequently asked questions
      </h2>
      <ul className='py-6 lg:py-16 max-w-3xl mx-auto'>
        {content.map((contentItem, index) => (
          <FaqItem key={index} {...contentItem} />
        ))}
      </ul>
    </div>
  );
};
