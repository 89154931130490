import { Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useCurrentProduct, useModal, useNotification } from '../../hooks';
import { IModal, ISubscriptionOptions } from '../../types';
import { StripeSubscriptionForm } from '../generic/payments';
import { createSubscription } from '../../lib/firebase';
import { useRecoilValue } from 'recoil';
import { accountState } from '../../state';
import { Preloader } from '../generic';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../util/constants';

export interface IPlanInfo {
  planId: string;
  planName: string;
  productId: string;
}

export const PlanPaymentModal = forwardRef((props: object, ref: Ref<IModal<IPlanInfo>> | undefined): JSX.Element => {
  const account = useRecoilValue(accountState);
  const { showError, showSuccess } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [plan, setPlan] = useState<IPlanInfo | null>(null);
  const { isOpen, open, close } = useModal();
  const { product } = useCurrentProduct();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    open (planInfo: IPlanInfo) {
      setPlan(planInfo);
      open();
    },
    close () {
      close();
    }
  }));

  const onSuccess = async (options: ISubscriptionOptions) => {
    if (!plan) {
      return;
    }

    try {
      setLoading(true);

      const { paymentMethodId, ...billing } = options;

      await createSubscription({
        accountId: account?.id,
        productId: product?.firebaseId,
        planId   : plan?.planId,
        paymentMethodId,
        billing
      });

      showSuccess('You have successfully subscribed');
      close();
      navigate(Routes.myCategoryProductInstallation(product?.name));
    } catch (err) {
      if (err instanceof Error) {
        showError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onError = (errorMessage: string) => {
    showError(errorMessage);
  };

  return (
    <Modal
      open={isOpen}
      onClose={close}
      center
      classNames={{
        overlay: 'customOverlay',
        modal  : 'customModal'
      }}
    >
      <div className="w-[360px] max-w-full">
        <div className="text-center font-semibold text-3xl">Subscription</div>
        <div className="text-center text-2xl">to the plan { plan?.planName }</div>

        <StripeSubscriptionForm
          className="mt-10"
          onSuccess={onSuccess}
          onError={onError}
        />

        <Preloader visible={loading} />
      </div>
    </Modal>
  );
});
