type TagType = 'base' | 'dark' | 'green' | 'warn';

interface Props {
  type: TagType;
  label: string;
  className?: string;
}

const TAG_TYPE_MAP: Record<TagType, string> = {
  base : 'bg-transparent border-cosmith-primary-900/20 text-cosmith-primary-900',
  dark : 'bg-cosmith-primary-900 border-cosmith-primary-900/20 text-white',
  green: 'bg-cosmith-success-100 border-cosmith-success-100 text-cosmith-success-600',
  warn : 'bg-cosmith-warning-100 border-cosmith-warning-100 text-cosmith-warning-600'
};

export function Tag ({ type, label, className = '' }: Props): JSX.Element {
  const colorClasses = TAG_TYPE_MAP[type] || TAG_TYPE_MAP['base'];

  return (
    <span className={`inline-flex items-center h-6 rounded-xl px-2 text-sm font-medium border-solid border whitespace-nowrap ${colorClasses} ${className}`}>
      {label}
    </span>
  );
}
