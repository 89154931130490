import { AdaptiveImageCard } from '../generic/AdaptiveImageCard';
import { ResponsivePicture } from '../generic/ResponsivePicture';
import { SectionSubTitle } from '../generic/landing/SectionSubTitle';
import { InfoFeatures } from '../generic/landing/info-section/InfoFeatures';
import Expert_mobile from '../../assets/talk-to-an-expert/Expert_mobile.png';
import Expert_tablet from '../../assets/talk-to-an-expert/Expert_tablet.png';
import LampIcon from '../../assets/talk-to-an-expert/LampIcon.svg';
import PhoneIcon from '../../assets/talk-to-an-expert/PhoneIcon.svg';
import LinkIcon from '../../assets/talk-to-an-expert/LinkIcon.svg';
import CardIcon from '../../assets/talk-to-an-expert/CardIcon.svg';

const images = {
  mobile: {
    src: Expert_mobile,
    width: 358,
  },
  tablet: {
    src: Expert_tablet,
    width: 389,
  },
  pc: {
    src: Expert_tablet,
    width: 389,
  },
};

export const WhatExpertCanDo = () => {
  return (
    <section className="flex flex-col items-center ">
      <SectionSubTitle className="text-center pt-12 max-w-[280px] mb-[0px] lg:pt-16 lg:max-w-[40rem]">
        What cosmith.io expert can do for you?
      </SectionSubTitle>
      <div className="py-12 lg:py-0">
        <AdaptiveImageCard
          reversed
          className="lg:pt-0 xl:pt-16"
          childrenClassName="lg:w-[690px] xl:w-1/2"
          secondChildrenclassName="lg:h-[665px] lg:max-w-[25rem] xl:max-w-1/2 "
          secondChildren={
            <ResponsivePicture
              imageUrl={Expert_tablet}
              images={images}
              classNameImg="lg:w-[389px] "
            />
          }
        >
          <div>
            <div className="mb-12 lg:mb-8">
              <div className="lg:grid lg:gap-y-16 lg:gap-x-6 lg:grid-cols-2">
                <InfoFeatures
                  imageUrl={LampIcon}
                  title="Develop custom components"
                  className="xl:w-full"
                >
                  Our experts can develop custom components to meet your
                  specific needs and requirements, providing you with a tailored
                  solution that works seamlessly with your no-code platform.
                </InfoFeatures>

                <InfoFeatures
                  imageUrl={LinkIcon}
                  title="Provide technical support"
                  className="xl:w-full"
                >
                  Experience our comprehensive technical support, including
                  guidance on component configuration and usage. Enjoy prompt,
                  expert assistance.
                </InfoFeatures>

                <InfoFeatures
                  imageUrl={PhoneIcon}
                  title="Offer consultation services"
                  className="xl:w-full"
                >
                  Leverage our experts' extensive experience in low-code
                  development to identify the optimal solution tailored to your
                  needs.
                </InfoFeatures>

                <InfoFeatures
                  imageUrl={CardIcon}
                  title="Optimize performance"
                  className="xl:w-full"
                >
                  Benefit from our experienced developers' guidance to enhance
                  your application's performance. We thoroughly investigate
                  issues, identify root causes, and implement targeted
                  solutions, including data structure optimization, custom
                  component development, or transitioning to pro-code solutions.
                  Experience the difference.
                </InfoFeatures>
              </div>
            </div>
          </div>
        </AdaptiveImageCard>
      </div>
    </section>
  );
};
