import { useElementWidth } from '../../hooks';

import checked from '../../assets/form/checkbox-checked.svg';
import unchecked from '../../assets/form/checkbox-unchecked.svg';
import disabled from '../../assets/form/checkbox-disabled.svg';

interface IStepParams {
  step: number;
  label: string;
}

interface Props {
  steps: IStepParams[];
  currentStep: number;
  className?: string;
}

export function Stepper ({ className = '', steps, currentStep }: Props): JSX.Element {
  const { ref, width } = useElementWidth<HTMLDivElement>();

  const lastStep = steps[steps.length - 1].step;
  const isLastStep = lastStep === currentStep;

  const stepItems = steps.map(({ step, label }) => {
    let image: string;

    if (isLastStep) {
      image = checked;
    } else {
      image = step === currentStep
        ? unchecked
        : (currentStep > step) ? checked : disabled;
    }

    return {
      id: step,
      label,
      image
    };
  });

  const stepWidth = width / steps.length;

  return (
    <div className={`stepper ${className}`}>
      <div
        ref={ref}
        className="w-full relative"
      >
        <ul className="w-full flex justify-between items-start">
          {stepItems.map(({ id, label, image }) => (
            <li
              className="shrink-0 flex-1 flex flex-col items-center gap-1"
              key={id}
            >
              <img src={image} alt={`step-${id}`} width="24" height="24" />
              <p className="text-center text-cosmith-primary-900/60">{label}</p>
            </li>
          ))}
        </ul>

        <div
          className="indicator-wrapper absolute h-[4px] top-[10px] z-[-1] bg-cosmith-primary-900/10"
          style={{ left: stepWidth / 2, right: stepWidth / 2 }}
        >
          <div
            className="indicator absolute left-0 top-0 bottom-0 bg-cosmith-lavanda-400"
            style={{ width: stepWidth * currentStep }}
          />
        </div>
      </div>
    </div>
  );
}
