import * as yup from 'yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldState } from './useFieldState';
import { registerWithCredentials, signInWithGoogle } from '../../lib/firebase';
import { FirebaseError } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../util/constants';
import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../state';

const schemaBuilder = () => yup.object({
  name    : yup.string().min(5).max(50).required(),
  email   : yup.string().email().required(),
  password: yup.string().min(6).max(20).required()
}).required();

export function useRegisterForm () {
  const setLoading = useSetRecoilState(loadingState);
  const navigate = useNavigate();

  const [emailError, setEmailError] = useState<string>('');

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schemaBuilder())
  });

  const onSubmit = useCallback(async (data: yup.InferType<ReturnType<typeof schemaBuilder>>) => {
    setLoading(true);
    setEmailError('');

    try {
      await registerWithCredentials(data);
      navigate(Routes.waitingEmailConfirmation());
    } catch (err) {
      if (err instanceof FirebaseError) {
        switch (err.code) {
          case 'auth/email-already-in-use': {
            setEmailError(`Email ${data.email} already in use`);
            break;
          }
        }
      }
    }

    setLoading(false);
  }, []);

  const onRegisterWithGoogle = async () => {
    setLoading(true);

    try {
      await signInWithGoogle();
      navigate(Routes.myComponents(), { replace: true });
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return {
    name: {
      register: register('name'),
      state   : useFieldState(formState.errors.name?.message)
    },
    email: {
      register: register('email'),
      state   : useFieldState(emailError || formState.errors.email?.message)
    },
    password: {
      register: register('password'),
      state   : useFieldState(formState.errors.password?.message)
    },
    onSubmit: handleSubmit(onSubmit),
    onRegisterWithGoogle
  };
}
