import { Breadcrumbs } from '../../layout/Breadcrumbs';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import ButtonCrossIcon from '../ButtonCrossIcon';
import { useRef, useState, ChangeEvent, useEffect } from 'react';
import { searchAndHighlight } from '../../../util/highlightUtils';

interface Crumb {
  label: string;
  to?: string;
}

interface HeaderSectionProps {
  mainHeader: string;
  secondaryHeader: string;
  crumbs?: Crumb[];
}

const ClientInfoPageHeader = ({ mainHeader, secondaryHeader, crumbs }: HeaderSectionProps) => {
  const input = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onClickHandler = () => {
    input.current?.focus();
  };

  useEffect(() => {
    searchAndHighlight(value);
  }, [value]);

  return (
    <section className="relative w-full bg-cosmith-purple-300 bg-right bg-no-repeat rounded-xl bg-dots no-search">
      <div className="absolute w-full h-[230px] bg-gradient rounded-xl sm:hidden"></div>
      <nav className={`relative z-10 hidden sm:flex justify-center ${crumbs && crumbs.length > 0 ? 'py-8' : 'py-15'} no-search`}>
        {crumbs && crumbs.length > 0 && (
          <Breadcrumbs crumbs={crumbs} />
        )}
      </nav>
      <header className="flex flex-col justify-center w-full h-[230px] md:h-[174px] max-w-screen-xl items-center sm:items-start mx-auto relative pb-7 pt-4 px-4 sm:px-10 md:px-6 sm:text-left no-search">
        <h1 className="text-[48px] font-semibold leading-[54px] text-cosmith-primary-900 tracking-[-0.02em] text-center pt-12 sm:pt-0">
          {mainHeader}
        </h1>
        <p className="text-[16px] leading-[24px] text-cosmith-primary-800 tracking-[-0.01em] mt-6 text-center">
          {secondaryHeader}
        </p>
        {!crumbs || crumbs.length === 0 ? (
          <div className="relative w-full max-w-md mt-6 mb-6 px-2 sm:px-0" onClick={onClickHandler}>
            <div className="relative flex items-center border-solid border border-cosmith-primary-900/10 rounded-lg shadow py-3 pl-12 h-12 bg-white focus-within:outline focus-within:outline-2 focus-within:outline-cosmith-lavanda-400">
              <MagnifyingGlassIcon className="absolute top-2.5 left-3.5 h-7 w-7 text-cosmith-primary-900/60 pr-2" />
              <input
                className="w-full outline-none bg-white"
                ref={input}
                type="text"
                value={value}
                onChange={onChangeHandler}
                placeholder="Search"
              />
              {value !== '' && (
                <ButtonCrossIcon
                  className="absolute right-3 text-cosmith-primary-900/60"
                  onClick={() => setValue('')}
                />
              )}
            </div>
          </div>
        ) : null}
      </header>
    </section>
  );
};

export default ClientInfoPageHeader;
