interface ISectionTitleProps {
  title: string;
}

export const SectionTitle = ({ title }: ISectionTitleProps) => {
  return (
    <h2
      className="text-2xl font-semibold	leading-[1.33] tracking-[-0.024rem] text-center mb-4
        lg:text-5xl lg:mb-6 lg:text-left lg:leading-[1.125] lg:tracking-[-0.06rem]"
    >
      {title}
    </h2>
  );
};
