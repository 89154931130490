import { useCurrentProduct } from '../../hooks';
import { EmptyDataResponse } from '../generic/EmptyDataResponse';
import { SubPageHeader } from './SubPageHeader';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { PlayIcon } from '@heroicons/react/20/solid';

const settings = {
  className: 'slider variable-width',
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: false,
      },
    },
  ],
};

export const Overview = () => {
  const { product } = useCurrentProduct();
  const [imgUrl, setImgUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [open, setOpen] = useState(false);

  const onOpenModal = ({ video, image }: { video?: string; image: string }) => {
    setVideoUrl(video || '');
    setImgUrl(image || '');
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);

  if (!product || !product.issues) {
    return <EmptyDataResponse />;
  }

  const { images } = product;

  return (
    <div className="h-auto" id="modal">
      <div className="">
        <SubPageHeader />
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModal',
          }}
        >
          {videoUrl ? (
            <iframe
              title="YouTube Video Player"
              style={{ width: '70vw', height: '70vh' }}
              src={videoUrl}
              allowFullScreen
            ></iframe>
          ) : (
            <img src={imgUrl} alt="foto" className="rounded-lg max-h-[80vh]" />
          )}
        </Modal>

        {images ? (
          <div className="my-6 py-6 mx-4 lg:mx-2 xl:mx-10  border-b-2 border-y-2">
            <Slider {...settings} className="main-slider images-slider px-6">
              {images.map(({ id, url, alt, video }) => {
                return (
                  <div
                    onClick={() => onOpenModal({ video, image: url })}
                    key={id}
                    className=" hover:cursor-pointer focus:cursor-pointer max-w-[340px] relative fill-cosmith-primary-900 hover:fill-cosmith-success-500"
                  >
                    <img src={url} alt={alt} className="rounded-lg h-[200px]" />
                    {video ? (
                      <PlayIcon
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fill-inherit"
                        width={40}
                      />
                    ) : null}
                  </div>
                );
              })}
            </Slider>
          </div>
        ) : null}
        <div className="flex flex-col lg:flex-row mx-4 xl:mx-28 px-4 lg:px-0 text-justify">
          <div className="font-semibold mb-2 lg:mr-8 xl:mr-40">Issues</div>
          <div className="flex flex-col lg:mr-6 xl:mr-36">
            <div className="text-cosmith-primary-900/70 mb-6">
              {product.issuesDescription}
            </div>
            {product.issues.map((issue) => (
              <div id={issue.id} key={issue.id} className="mb-6 last:mb-0">
                <div className="font-semibold mb-2">{issue.title}</div>
                <div className="text-cosmith-primary-900/70">
                  {issue.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
