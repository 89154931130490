import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface Props {
  data: string;
  className?: string;
}

export default function InstallationManual ({
  data,
  className = '',
}: Props): JSX.Element {
  return (
    <div className={`installation-manual w-full ${className}`}>
      <ReactMarkdown
        className="prose-sm prose-ul:list-disc prose-ol:list-decimal prose-a:underline underline-offset-2"
        children={data}
        remarkPlugins={[remarkGfm]}
      />
    </div>
  );
}
