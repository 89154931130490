import { InputHTMLAttributes, forwardRef } from 'react';
import { ChangeHandler, FormItemVariant } from '../../../types';

import checked from '../../../assets/form/checkbox-checked.svg';
import unchecked from '../../../assets/form/checkbox-unchecked.svg';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  variant?: FormItemVariant;
  onChange: ChangeHandler;
}

const VARIANT_PROPS: Record<FormItemVariant, string> = {
  normal : '',
  success: '',
  error  : ''
};

export const FormCheckbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { id = '', className = '', label, onChange, variant = 'normal', ...restProps } = props;

  return (
    <label id={id}
      className={[
        'flex gap-4 relative cursor-pointer',
        VARIANT_PROPS[variant],
        className
      ].join(' ')}
    >
      <input
        className="w-0 h-0 text-white/0 peer absolute appearance-none"
        type="checkbox"
        {...restProps}
        onChange={onChange}
        ref={ref}
      />

      <img
        className="rounded peer-focus:outline peer-focus:outline-[3px] peer-focus:outline-cosmith-lavanda-400"
        src={restProps.checked ? checked : unchecked}
        alt="checked"
        width="24"
        height="24"
      />

      <span className="group-focus:bg-cyan-400">
        {label}
      </span>
    </label>
  );
});
