import React, { ReactNode } from 'react';

interface StyledListProps {
  items: (string | ReactNode)[];
}

const StyledList: React.FC<StyledListProps> = ({ items }) => (
  <ul className="list-disc ml-6 space-y-2 marker:text-black">
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>
);

export default StyledList;