import { RotatingLines } from 'react-loader-spinner';

export const Loader = () => {
  return (
    <div className="flex items-center justify-center min-h-[348px] lg:min-h-[779px]">
      <RotatingLines
        strokeColor="#4fa94d"
        strokeWidth="5"
        animationDuration="0.75"
        width="60"
        visible={true}
      />
    </div>
  );
};
