import { FormControl, FormHint, FormInput, FormLabel, FormHeader, FormDescription, Button } from '../../generic';
import { useResetPasswordForm } from '../../../hooks/auth/useResetPasswordForm';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../util/constants';

export function ResetPasswordForm () {
  const { email, isButtonDisabled, onSubmit } = useResetPasswordForm();

  return (
    <div className='w-full sm:w-[356px]'>
      <FormHeader text="Reset your password" />
      <FormDescription>
        Enter the email will be sent containing a link to reset your password.
      </FormDescription>

      <form onSubmit={(e) => e.preventDefault()} className='w-full space-y-6'>
        <FormControl variant={email.state.variant}>
          <FormLabel htmlFor='email' children="Email" />
          <FormInput id="email" placeholder="Enter your email" {...email.register} />
          <FormHint hint={email.state.message} />
        </FormControl>

        <Button
          className="w-full"
          type="dark"
          disabled={isButtonDisabled}
          onClick={onSubmit}
        >
          Continue
        </Button>
      </form>
      <NavLink className="font-medium block mt-2 text-center focus-outline-custom" to={Routes.login()}>Return to Login page</NavLink>
    </div>
  );
}
