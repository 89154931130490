import { Button } from '../../components/generic/Button';
import Arrow from '../../icons/Arrow.svg';

type ShowMoreButtonsProps = {
  allShown: boolean;
  loadMore: () => void;
  resetToDefault: () => void;
};

export const ShowMoreButtons = (props: ShowMoreButtonsProps) => {
  const { loadMore, allShown, resetToDefault } = props;

  return (
    <div className="flex justify-center mb-12 lg:mb-16">
      {!allShown ? (
        <Button
          className="min-w-[19rem] max-w-[25rem] lg:min-w-[9.75rem] lg:max-w-[9.75rem]"
          type="secondary"
          onClick={loadMore}
        >
          Show more
          <img src={Arrow} alt="arrow icon" className="ml-2" />
        </Button>
      ) : (
        <Button
          className="min-w-[19rem] max-w-[25rem] lg:min-w-[9.75rem] lg:max-w-[9.75rem]"
          type="secondary"
          onClick={resetToDefault}
        >
          Show less
        </Button>
      )}
    </div>
  );
};
