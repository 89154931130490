import { ReactNode } from 'react';
import authLogo from '../../assets/auth/auth-logo-icon.svg';
import { AuthPageHeader } from './AuthPageHeader';
import { useAuthPageProps } from '../../hooks/auth/useAuthPageProps';

interface Props {
  linkText: string;
  linkHref: string;
  info: ReactNode;
  form: ReactNode;
}

export function AuthPageWrapper ({ linkText, linkHref, info, form }: Props): JSX.Element {
  const { containerClassName } = useAuthPageProps();

  return (
    <>
      <AuthPageHeader
        linkText={linkText}
        linkHref={linkHref}
        containerClassName={containerClassName}
      />

      <div className={`page-content grid grid-cols-1 md:grid-cols-2 gap-10 ${containerClassName}`}>
        <section className="left-section items-center hidden md:flex">
          {info}
        </section>
        <section className="right-section flex flex-col gap-6 items-center md:justify-center pt-12 md:pt-0">
          <img src={authLogo} alt="auth logo" width="75" height="75" className="md:hidden" />
          {form}
        </section>
      </div>
    </>
  );
}
