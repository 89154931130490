import SadNoResults from '../../assets/sadNoResult.png';
import { requestComponentLink } from '../../appconfig';
import { AdaptiveErrorSection } from '../generic/AdaptiveErrorSection';

export const EmptyCategories = () => {
  return (
    <div className="lg:min-h-[779px] flex justify-center items-center py-10 lg:py-0">
      <AdaptiveErrorSection
        imgUrl={SadNoResults}
        title="Oops!"
        buttonText="Request a component"
        to={requestComponentLink}
        target="_blank"
      >
        We don't have that yet... but you can help!
      </AdaptiveErrorSection>
    </div>
  );
};
