import { BuyNowButton } from './BuyNowButton';
import { TalkToAnExpertButton } from './TalkToAnExpertButton';
import { CallToActionDescription } from './CallToActionDescription';
import { SectionSubTitle } from './SectionSubTitle';

export const CallToAction = () => {
  return (
    <section
      className="flex flex-col items-center p-6 mb-12 text-center bg-cosmith-success-400 
      border border-cosmith-primary-900/10 rounded-[2rem] lg:p-16 lg:my-16 no-search"
    >
      <SectionSubTitle>
        Unlock the power of no-code with cosmith.io!
      </SectionSubTitle>
      <CallToActionDescription />
      <div className="flex items-center flex-col gap-[0.75rem] mt-6 lg:flex-row lg:gap-4 lg:mt-8">
        <BuyNowButton />
        <TalkToAnExpertButton />
      </div>
    </section>
  );
};
