export const BillingIcon = ({
  className = '',
}: {
  className: string;
}) => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" className={className} xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6667 17.3333H1.33333C1.11232 17.3333 0.900358 17.2455 0.744078 17.0892C0.587797 16.933 0.5 16.721 0.5 16.5V1.49999C0.5 1.27898 0.587797 1.06701 0.744078 0.910734C0.900358 0.754454 1.11232 0.666656 1.33333 0.666656H14.6667C14.8877 0.666656 15.0996 0.754454 15.2559 0.910734C15.4122 1.06701 15.5 1.27898 15.5 1.49999V16.5C15.5 16.721 15.4122 16.933 15.2559 17.0892C15.0996 17.2455 14.8877 17.3333 14.6667 17.3333ZM13.8333 15.6667V2.33332H2.16667V15.6667H13.8333ZM4.66667 6.49999H11.3333V8.16666H4.66667V6.49999ZM4.66667 9.83332H11.3333V11.5H4.66667V9.83332Z" />
    </svg>
  );
};
