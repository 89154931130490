import * as yup from 'yup';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldState } from './useFieldState';
import { FormItemVariant } from '../../types';
import { resetPassword } from '../../lib/firebase';
import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../state';

const schemaBuilder = () => yup.object({
  email: yup.string().email().required()
}).required();

export function useResetPasswordForm () {
  const setLoading = useSetRecoilState(loadingState);
  const [isSent, setIsSent] = useState<boolean>(false);

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schemaBuilder())
  });

  const baseEmailState = useFieldState(formState.errors.email?.message);

  const emailState = useMemo((): { variant: FormItemVariant, message?: string } => {
    if (isSent) {
      return {
        variant: 'success',
        message: 'We sent you a link to your email'
      };
    }

    return baseEmailState;
  }, [isSent, baseEmailState]);

  const onSubmit = useCallback(async (data: yup.InferType<ReturnType<typeof schemaBuilder>>) => {
    setLoading(true);

    try {
      await resetPassword(data.email);
      setIsSent(true);
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  }, []);

  const isButtonDisabled = useMemo(() => {
    return !formState.isDirty || isSent;
  }, [isSent, formState]);

  return {
    email: {
      register: register('email'),
      state   : emailState
    },
    onSubmit: handleSubmit(onSubmit),
    isButtonDisabled
  };
}
