import { Query, collection } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useMemo } from 'react';
import { UserCase } from '../../state/models/UserCase';
import { IFirebaseUserCase } from '../../types';
import { DataOptions } from 'react-firebase-hooks/firestore/dist/firestore/types';

const query = collection(firestore, 'userCases') as Query<IFirebaseUserCase>;
const options: DataOptions<IFirebaseUserCase> = {
  snapshotListenOptions: { includeMetadataChanges: true },
};

export const useGetUserCases = () => {
  const [loadedUserCases, loading, error] = useCollection<IFirebaseUserCase>(
    query,
    options
  );

  const allUserCases = useMemo(() => {
    if (!loadedUserCases) {
      return [];
    }

    return loadedUserCases.docs.map(
      (p) => new UserCase({ firebaseId: p.id, ...p.data() })
    );
  }, [loadedUserCases]);

  return { allUserCases, loading, error };
};
