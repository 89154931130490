import { HowCosmithWorksItem } from './HowCosmithWorksItem';

export const HowCosmithWorksList = () => {
  return (
    <ul className="flex flex-col gap-4 mt-12 lg:mt-0">
      <HowCosmithWorksItem number="01">
        Browse our components: Take a look at our extensive selection of
        components, including UI kits, templates, and integrations, to find the
        ones that best suit your needs.
      </HowCosmithWorksItem>
      <HowCosmithWorksItem number="02">
        Choose your components: Select the components you want to use and add
        them to your cart. You can purchase individual components or choose from
        one of our affordable bundle packages.
      </HowCosmithWorksItem>
      <HowCosmithWorksItem number="03">
        Integrate and customize: Once you have purchased your components,
        integrate them into your no-code platform and customize them to fit your
        specific requirements.
      </HowCosmithWorksItem>
      <HowCosmithWorksItem number="04">
        Enjoy seamless performance: Our components are thoroughly tested and
        verified to ensure that they work seamlessly with your no-code platform,
        providing an excellent user experience.
      </HowCosmithWorksItem>
    </ul>
  );
};
