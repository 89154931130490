import ComponentServiceInfoSection from './ComponentServiceInfoSection';
import MyComponentButtons from './MyComponentButtons';
import { ProductImage } from '../generic';
import { AccountComponent } from '../../state/models';

interface Props {
  className?: string;
  component: AccountComponent
}

export default function MyComponentCardSmall ({ className = '', component }: Props): JSX.Element {
  const { product, installation } = component;

  return (
    <div
      className={`
        ${className} component-card-small
        border-solid border border-cosmith-primary-900/10 rounded-xl
        w-full p-6
      `}
    >
      <section className="top-section grid grid-cols-[1fr,106px] gap-x-2 gap-y-4">
        <ComponentServiceInfoSection
          className="gap-y-1 -mt-1 h-[60px] items-start"
          isTrial={installation.isTrial}
          daysLeft={installation.daysLeft}
          price={product.price}
        />
        <ProductImage
          className="col-start-2 col-end-3 row-start-1 row-end-3"
          src={product.imageUrl}
          alt={product.name}
        />
        <div className="text-cosmith-primary-900 font-semibold">{product.title}</div>
      </section>

      <section className="py-3 border-b">
        <div className="text-cosmith-primary-900/60">{product.description}</div>
      </section>

      <section className={`action-section flex flex-col gap-6 mt-5 ${installation.isSubscription && 'flex-col-reverse'}`}>
        <MyComponentButtons
          productId={product.id}
          productName={product.name}
          isTrial={installation.isTrial}
          isInstalled={installation.isInstalled}
        />
      </section>
    </div>
  );
}
