import { AdaptiveImageCard } from '../../generic/AdaptiveImageCard';
import { ResponsivePicture } from '../../generic/ResponsivePicture';
import { DottedWrapperHero } from '../../generic/landing/DottedWrapperHero';
import { HeroText } from '../../generic/landing/HeroText';
import { HeroTitle } from '../../generic/landing/HeroTitle';
import Placeholder_mob from '../../../assets/how-its-work/Placeholder_mob.png';
import Placeholder_tablet from '../../../assets/how-its-work/Placeholder_tablet.png';
import Placeholder_pc from '../../../assets/how-its-work/Placeholder_pc.png';
import { EmailForm } from './EmailForm';

const images = {
  mobile: {
    src: Placeholder_mob,
    width: 358,
  },
  tablet: {
    src: Placeholder_tablet,
    width: 500,
  },
  pc: {
    src: Placeholder_pc,
    width: 588,
  },
};

export const MainSection = () => {
  return (
    <DottedWrapperHero>
      <div className='text-center lg:text-left'>
        <AdaptiveImageCard
          className='py-12'
          secondChildrenclassName='lg:w-[500px] lg:h-[550px] xl:w-588px xl:h-[588px]'
          secondChildren={
            <ResponsivePicture imageUrl={Placeholder_mob} images={images} />
          }
        >
          <div className='mb-6'>
            <HeroTitle>What is cosmith.io and how does it work?</HeroTitle>
            <div>
              <HeroText>
                Want to know more? Drop your email, and we’ll reach out with all
                the info!
              </HeroText>
            </div>
          </div>
          <EmailForm />
        </AdaptiveImageCard>
      </div>
    </DottedWrapperHero>
  );
};
