export const CosmithIcon = () => {
  return (
    <svg width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7495 0.00190784C16.5799 0.585633 17.5071 1.25274 18.0274 2.22339C18.4377 2.98724 17.5638 3.34748 17.1136 3.78111C13.5515 7.2034 9.99951 10.6357 6.38745 14.008C5.58366 14.7585 5.58366 15.1587 6.38745 15.9092C10.0395 19.3149 13.6216 22.8005 17.2536 26.2295C17.9674 26.9033 18.6478 27.5537 17.5071 28.3209C16.64 28.9046 16.2131 30.8793 14.5254 29.2382C9.9128 24.7485 5.23346 20.3222 0.554128 15.9026C-0.1963 15.1921 -0.176299 14.7084 0.564123 14.0113C5.28014 9.54831 9.96949 5.0553 14.6655 0.572288C14.9423 0.302106 15.1858 -0.0281123 15.7495 0.00190784Z" fill="#AFE7BB"/>
      <path d="M34.3374 29.9998C33.2768 29.4661 32.4963 28.6622 31.8793 27.7683C31.3423 26.9911 32.2229 26.6242 32.6631 26.1972C36.2251 22.7749 39.7738 19.3393 43.3925 15.977C44.2364 15.1931 44.3131 14.7829 43.4192 13.9556C39.7538 10.5634 36.1818 7.07103 32.5464 3.64873C31.7926 2.93826 31.299 2.27447 32.3863 1.53731C33.2634 0.94358 33.707 -0.934344 35.378 0.706757C39.9306 5.17642 44.5766 9.55269 49.2159 13.9323C49.9963 14.6695 50.063 15.1798 49.2459 15.9503C44.5632 20.3666 39.9206 24.8229 35.2612 29.2626C34.9878 29.5228 34.6676 29.7362 34.3374 29.9998Z" fill="#AFE7BB"/>
      <path d="M16.656 17.8346C15.1485 17.8813 13.7744 16.5937 13.7077 15.0694C13.6377 13.485 14.9851 12.0807 16.5927 12.0707C18.1035 12.0607 19.4343 13.3883 19.4543 14.9193C19.4743 16.4503 18.1902 17.7912 16.656 17.8346Z" fill="#AFE7BB"/>
      <path d="M27.8044 14.9142C27.8278 16.4419 26.5437 17.7794 25.0028 17.8228C23.4653 17.8695 22.1178 16.612 22.0478 15.0709C21.9744 13.4699 23.3119 12.0789 24.9228 12.0789C26.4603 12.0823 27.7777 13.3798 27.8044 14.9142Z" fill="#AFE7BB"/>
      <path d="M33.2297 17.8365C31.7155 17.8332 30.3948 16.5023 30.3848 14.9713C30.3781 13.4336 31.6789 12.0927 33.1964 12.076C34.8073 12.0593 36.1748 13.4302 36.1314 15.0213C36.0947 16.5356 34.7506 17.8399 33.2297 17.8365Z" fill="#AFE7BB"/>
    </svg>
  );
};
