import { useMemo } from 'react';
import type { ITableColumn, ITableData, ITableGrid, ITableRow } from '../../types';

interface UseGridOptions {
  id: string;
  columns: ITableColumn[];
  data: ITableData[][];
  instanceIdKey?: string;
}

export function useGridTable ({ id, columns, data, instanceIdKey = 'id' }: UseGridOptions) {
  const [grids] = useMemo(() => {
    const gridRows: ITableGrid[] = [];

    for (let row = 0; row < data.length; row++) {
      const rowData = data[row];
      const titleData = rowData.find((d) => d.key === columns[0].id);

      if (!titleData) {
        throw new Error('No row title');
      }

      const idValue = rowData.find((r) => r.key === instanceIdKey);

      const instanceId = idValue && (typeof idValue.value === 'string')
        ? idValue.value
        : null;

      const grid: ITableGrid = {
        id   : `${id}-grid-${row + 1}`,
        title: titleData.value,
        rows : [],
        instanceId
      };

      for (let col = 1; col < columns.length; col++) {
        const column = columns[col];
        const value = rowData.find((r) => r.key === column.id);

        const gridRow: ITableRow = {
          id: `${grid.id}-${col + 1}`,
          cells: [
            {
              id: '1',
              value: column.header,
              className: column.headerClassName || ''
            },
            {
              id: '2',
              value: value ? value.value : '',
              className: column.rowClassName || ''
            },
          ]
        };

        grid.rows.push(gridRow);
      }

      gridRows.push(grid);
    }

    return [gridRows];
  }, [columns, data]);

  return { grids };
}
