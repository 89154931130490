import { User } from 'firebase/auth';
import { IFirebaseAccountInfo } from '../../types';

export class Account {
  private readonly _accountId: string;
  private readonly _accountInfo: IFirebaseAccountInfo;
  private readonly _authInfo: User;

  constructor (
    accountId: string,
    accountInfo: IFirebaseAccountInfo,
    authInfo: User
  ) {
    this._accountId = accountId;
    this._accountInfo = JSON.parse(JSON.stringify(accountInfo));
    this._authInfo = JSON.parse(JSON.stringify(authInfo));
  }

  get id () {
    return this._accountId;
  }

  get uid () {
    return this._authInfo.uid;
  }

  get email () {
    return this._authInfo.email;
  }

  get name () {
    return this._authInfo.displayName;
  }

  get photo () {
    return this._authInfo.photoURL || 'http://www.gravatar.com/avatar/?d=identicon';
  }
}
