import SadNoResults from '../../assets/sadNoResult.png';
import { AdaptiveErrorSection } from '../generic/AdaptiveErrorSection';

export const ErrorSection = () => {
  const reloadPage = () => {
    window.location.reload();
  };
  const location = document.location;

  return (
    <AdaptiveErrorSection
      imgUrl={SadNoResults}
      title="Oops!"
      buttonText="Reload page"
      to={location.href}
      onClick={reloadPage}
    >
      Something went wrong, try to reload page
    </AdaptiveErrorSection>
  );
};
