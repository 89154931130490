import { SetterOrUpdater } from 'recoil';
import { Search } from '../generic/Search';

interface Props {
  value: string;
  onChange: SetterOrUpdater<string>;
  className?: string;
}

export default function ProductSearchFilter ({ value, onChange, className = '' }: Props): JSX.Element {
  return (
    <Search
      className={className}
      value={value}
      onChange={onChange}
    />
  );
}
