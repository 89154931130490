import { PropsWithChildren } from 'react';

interface ISectionMainTextProps {
  className?: string;
}
export const SectionMainText = ({
  className,
  children,
}: PropsWithChildren<ISectionMainTextProps>) => {
  return (
    <div
      className={`tracking-[-0.01rem] opacity-60 text-center
        lg:text-lg lg:leading-[1.44] lg:tracking-[-0.014rem] lg:text-left ${className}`}
    >
      {children}
    </div>
  );
};
