import { IFirebaseUserCase } from '../../types';

export class UserCase {
  constructor (
    protected readonly _data: IFirebaseUserCase & { firebaseId: string }
  ) {}

  get id () {
    return this._data.firebaseId;
  }

  get description () {
    return this._data.description;
  }

  get image () {
    return this._data.image;
  }

  get title () {
    return this._data.title;
  }

  get shortDescription () {
    return this._data.shortDescription;
  }
  get publishDate () {
    const timestamp = this._data.publishDate;
    
    const date = new Date(timestamp.seconds * 1000);
    
    return date.toISOString().split('T')[0];
  }
  get productName () {
    return this._data.productName;
  }
  get relatedCategories () {
    return this._data.relatedCategories;
  }
}
