import { NavbarList } from './NavbarList';
import { Routes } from '../../../../util/constants';
import { requestComponentLink } from '../../../../appconfig';

const footerLists = [
  {
    title: 'Products',
    links: [
      { slug: Routes.solutions(), title: 'For any size company' },
      { slug: Routes.talkToExpert(), title: 'Hire an Expert' },
      { slug: Routes.userCase(), title: 'User cases' },
      {
        slug: requestComponentLink,
        title: 'Request a component',
        isOpenInNewTab: true,
      },
    ],
  },
  {
    title: 'Categories',
    links: [
      { slug: Routes.categoryOption('Adalo'), title: 'Adalo' },
      {
        slug: Routes.categoryOption('Marketing tool'),
        title: 'Marketing tool',
      },
      {
        slug: Routes.categoryOption('Content creator and viewer'),
        title: 'Content creator and viewer',
      },
      { slug: Routes.categoryOption('Device'), title: 'Device' },
      { slug: Routes.categoryOption('Other'), title: 'Other' },
      { slug: Routes.category(), title: 'All components' },
    ],
  },
  {
    title: 'Company',
    links: [
      { slug: '/', title: 'About us' },
      { slug: 'contact', title: 'Contact us' },
      { slug: Routes.help(), title: 'Help Center' },
    ],
  },

  {
    title: 'Info',
    links: [
      // { slug: 'license', title: 'License' },
      // { slug: 'cookies', title: 'Cookies' },
      { slug: 'terms', title: 'Terms of use' },
      { slug: 'privacy', title: 'Privacy' },
    ],
  },
];

export const FooterNavbar = () => {
  return (
    <ul className='flex justify-between flex-wrap gap-x-4 gap-y-8 lg:justify-normal lg:gap-x-6 lg:gap-y-0 no-search'>
      {footerLists.map(({ title, links }) => (
        <li key={title}>
          <NavbarList title={title} links={links} />
        </li>
      ))}
    </ul>
  );
};
