import { toast } from 'react-toastify';

export function useNotification () {
  const showInfo = (message: string) => {
    toast.info(message, { autoClose: 3000 });
  };

  const showSuccess = (message: string) => {
    toast.success(message, { autoClose: 3000 });
  };

  const showError = (error: string) => {
    toast.error(error, { autoClose: 3000 });
  };

  return {
    showInfo,
    showSuccess,
    showError
  };
}
