import { InfoDescription } from '../generic/landing/info-section/InfoDescription';
import { IndicatorsList } from './IndicatorsList';
export const IdicatorsOfWork = () => {
  return (
    <section className="mt-12">
      <InfoDescription title="Indicators of our work">
        Design better and spend less time without restricting creative freedom.
      </InfoDescription>
      <IndicatorsList />
    </section>
  );
};
