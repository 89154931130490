import { maxMobileScreen, maxTabletScreen, pcScreen } from '../../appconfig';

interface IImages {
  mobile: {
    src: string;
    width: number;
  };
  tablet: {
    src: string;
    width: number;
  };
  pc: {
    src: string;
    width: number;
  };
}

interface IResponsivePicture {
  images: IImages;
  imageUrl: string;
  className?: string;
  classNameImg?: string;
}

export const ResponsivePicture = ({
  images,
  imageUrl,
  className,
  classNameImg,
}: IResponsivePicture) => {
  const { mobile, tablet, pc } = images;

  return (
    <picture className={className}>
      <source
        srcSet={`${mobile.src} ${mobile.width}w`}
        media={`(max-width: ${maxMobileScreen})`}
      />
      <source
        srcSet={`${tablet.src} ${tablet.width}w`}
        media={`(max-width: ${maxTabletScreen})`}
      />
      <source
        srcSet={`${pc.src} ${pc.width}w`}
        media={`(max-width: ${pcScreen})`}
      />

      <img
        src={imageUrl}
        alt="illustration"
        className={`w-full ${classNameImg}`}
      />
    </picture>
  );
};
