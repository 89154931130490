import { CrossIcon } from '../../icons/CrossIcon';

interface IButtonCrossIconProps {
  onClick: () => void;
  className?: string;
}

export default function ButtonCrossIcon ({
  onClick,
  className,
}: IButtonCrossIconProps): JSX.Element {
  return (
    <button
      type='button'
      name='cross'
      onClick={onClick}
      className={`focus-outline-custom bg-transparent border-none shadow-none p-3 ${className}`}
    >
      <CrossIcon clasName='fill-cosmith-dark-400 hover:fill-cosmith-lavanda-400' />
    </button>
  );
}
