import { PropsWithChildren } from 'react';



interface ICrossIconButtonProps {
  onClick: ()=>void;
  className?: string;
}

export default function ButtonIcon ({ onClick,children,className }: PropsWithChildren<ICrossIconButtonProps>): JSX.Element {
  return (
    <button
      type='button'
      name='icon-button'
      onClick={onClick}
      className={`bg-transparent border-none shadow-none p-3 absolute ${className}`}
    >
     {children}
    </button>
  );
}
