import { PropsWithChildren } from 'react';

interface IBetterThenOthersItemProps {
  imgUrl: string;
  title: string;
}

export const BetterThenOthersItem = ({
  imgUrl,
  title,
  children,
}: PropsWithChildren<IBetterThenOthersItemProps>) => {
  return (
    <li className="w-full text-center flex flex-col items-center justify-center border rounded-lg border-cosmith-primary-900/10 p-6">
      <img src={imgUrl} alt="icon" width={48} height={48} className="mb-6" />
      <p className="mb-4 text-xl font-semibold leading-7 tracking-[-0.0175rem]">
        {title}
      </p>
      <p className="leading-[1.375rem] tracking-[-0.01rem] text-cosmith-primary-900/60">
        {children}
      </p>
    </li>
  );
};
