import { FormItemVariant } from '../../../types';

type Props = {
  className?: string;
  variant?: FormItemVariant;
  hint?: string;
}

const VARIANT_PROPS: Record<FormItemVariant, string> = {
  normal : 'text-cosmith-primary-900/40',
  success: 'text-cosmith-success-500',
  error  : 'text-cosmith-error-500'
};

export function FormHint (props: Props) {
  const { className = '', variant = 'normal', hint, ...restProps } = props;

  if (!hint) {
    return null;
  }

  return (
    <div
      className={[
        'cosmith-form-hint',
        'text-sm',
        VARIANT_PROPS[variant],
        className
      ].join(' ')}
      {...restProps}
    >
      {hint}
    </div>
  );
}
