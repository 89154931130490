export const TalkToExpertIcon = ({
  className = '',
}: {
  className: string;
}) => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" className={className} xmlns="http://www.w3.org/2000/svg">
      <path d="M4.37852 13.8333L0.666016 16.75V1.33333C0.666016 1.11232 0.753813 0.900358 0.910093 0.744078C1.06637 0.587797 1.27834 0.5 1.49935 0.5H16.4993C16.7204 0.5 16.9323 0.587797 17.0886 0.744078C17.2449 0.900358 17.3327 1.11232 17.3327 1.33333V13C17.3327 13.221 17.2449 13.433 17.0886 13.5893C16.9323 13.7455 16.7204 13.8333 16.4993 13.8333H4.37852ZM2.33268 13.3208L3.80185 12.1667H15.666V2.16667H2.33268V13.3208ZM9.83268 7.16667V10.5H8.16602V7.16667H5.66602L8.99935 3.83333L12.3327 7.16667H9.83268Z" />
    </svg>
  );
};
