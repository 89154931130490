import { useRecoilState } from 'recoil';
import ProductSorter from './ProductSorter';
import ProductCategoryFilter from './ProductCategoryFilter';
import ProductSearchFilter from './ProductSearchFilter';

import {
  productFilterState,
  productSortState,
  productSearchState,
} from '../../state';

interface Props {
  className?: string;
}

export default function ProductFilterSection ({
  className = '',
}: Props): JSX.Element {
  const [filter, setFilter] = useRecoilState(productFilterState);
  const [sort, setSort] = useRecoilState(productSortState);
  const [search, setSearch] = useRecoilState(productSearchState);

  return (
    <section className={`flex gap-x-12 gap-y-6 flex-wrap ${className}`}>
      <p className='text-cosmith-primary-900/60 text-center md:hidden'>
        Use our search engine to quickly find the right app
      </p>

      <ProductSorter
        className='order-2 w-full md:w-80 md:order-1'
        value={sort}
        onChange={setSort}
      />

      <ProductCategoryFilter
        className='order-3 w-full md:w-80 md:order-2'
        value={filter}
        onChange={setFilter}
      />

      <ProductSearchFilter
        className='w-full order-1 md:order-3'
        value={search}
        onChange={setSearch}
      />
    </section>
  );
}
