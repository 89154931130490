import { NavLinkButton } from '../../generic';
import { AuthHeader } from '../generic/AuthHeader';

export function ConfirmEmailInfo () {
  return (
    <div className="reset-password-info">
      <AuthHeader>Need the development of own application?</AuthHeader>

      <NavLinkButton to="/talk-to-expert" className="w-[160px] mt-8">
        Talk to expert
      </NavLinkButton>
    </div>
  );
}
