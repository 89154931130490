import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export function PageTitle ({ children, className = '' }: PropsWithChildren<Props>): JSX.Element {
  return (
    <h2 className={`my-4 text-cosmith-primary-900 font-semibold text-4xl text-center ${className}`}>
      {children}
    </h2>
  );
}
