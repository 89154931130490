import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, FormHint, FormInput, FormLabel } from '../../generic';
import FormWrapper from './FormWrapper';
import { useFieldState } from '../../../hooks/auth/useFieldState';
import FormSpinner from './FormSpinner';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { ADALO_APP_ID_HINT_LINK, ADALO_TOKEN_REG_EX } from '../../../util/constants';

interface Props {
  className?: string;
  loading: boolean;
  linkProductToApp: (adaloAppId: string) => Promise<void>;
  onCancel: () => void;
}

export default function FormStep2 ({ className = '', loading, linkProductToApp, onCancel }: Props): JSX.Element {
  const [error, setError] = useState<string>('');

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(yup.object({
      adaloAppId: yup.string().matches(ADALO_TOKEN_REG_EX, 'Invalid Adalo App ID format').required()
    }).required())
  });

  const tokenState = useFieldState(error || formState.errors.adaloAppId?.message);

  const handleInstall = handleSubmit(async (data) => {
    setError('');

    try {
      await linkProductToApp(data.adaloAppId);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
    }
  });

  return (
    <FormWrapper className={className}>
      <form onSubmit={(e) => e.preventDefault()} className='w-full space-y-6'>
        <FormControl variant={tokenState.variant}>
          <FormLabel
            htmlFor='assessToken'
            children={
              <div className="flex gap-1">
                Adalo App ID*
                <a href={ADALO_APP_ID_HINT_LINK} target="_blank" rel="noreferrer">
                  <QuestionMarkCircleIcon className="w-5 h-5" />
                </a>
              </div>
            }
          />
          <FormInput id="assessToken" placeholder="Enter Adalo App ID" autoComplete="off" {...register('adaloAppId')} />
          <FormHint hint={tokenState.message} />
        </FormControl>

        <FormSpinner visible={loading} />

        <div className="text-cosmith-primary-900/60">
          Adalo App ID should have format:
          <br />
          xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.
        </div>

        <div className="grid grid-cols-2 items-center gap-6 max-w-[380px]">
          <Button type="dark" onClick={handleInstall} disabled={loading}>
            Link to Adalo App
          </Button>

          <Button onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </FormWrapper>
  );
}
