interface IPriceProps {
  price: number | string;
  period: string;
  className?: string;
  priceClass?: string;
  periodClass?: string;
}

export function Price ({
  price,
  period,
  className = '',
  priceClass = '',
  periodClass = ''
}: IPriceProps): JSX.Element {
  return (
    <div className={`flex-1 ${className}`}>
      <span className={`text-4xl font-semibold text-cosmith-primary-900 ${priceClass}`}>${price}</span>
      <span className={`mx-1 text-cosmith-primary-900/60 ${periodClass}`}>/</span>
      <span className={`text-cosmith-primary-900/60 ${periodClass}`}>{period}</span>
    </div>
  );
}
