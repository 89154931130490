import { ColorRing } from 'react-loader-spinner';

export const ButtonLoader = () => {
  return (
    <div className="flex items-center justify-center">
      <ColorRing
        colors={['#ffffff','#ffffff','#ffffff','#ffffff','#ffffff']}
        width="30"
        visible={true}
      />
    </div>
  );
};
