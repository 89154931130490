import { AdaptiveImageCard } from '../generic/AdaptiveImageCard';
import HighQuality_mob from '../../assets/main-page/HighQuality-mobile.png';
import HighQuality_tablet from '../../assets/main-page/HighQuality-tablet.png';
import HighQuality_pc from '../../assets/main-page/HighQuality-pc.png';
import { ResponsivePicture } from '../generic/ResponsivePicture';
import { InfoDescription } from '../generic/landing/info-section/InfoDescription';
import { InfoFeatures } from '../generic/landing/info-section/InfoFeatures';
import TargetPurple from '../../icons/TargetPurple.svg';
import ComponentGreen from '../../icons/ComponentGreen.svg';
import { BuyNowButton } from '../generic/landing/BuyNowButton';

const images = {
  mobile: {
    src: HighQuality_mob,
    width: 390,
  },
  tablet: {
    src: HighQuality_tablet,
    width: 482,
  },
  pc: {
    src: HighQuality_pc,
    width: 588,
  },
};

export const HighQualitySection = () => {
  return (
    <div className="relative">
      <div className="pt-6 pb-6 lg:p-0 lg:mr-[-2rem] xl:mr-0 ">
        <AdaptiveImageCard
          secondChildren={
            <ResponsivePicture
              imageUrl={HighQuality_pc}
              images={images}
              className="reletive flex items-center 
                          after:content-[' '] after:h-[358px] after:w-[358px]
                          after:-z-10 after:absolute after:bg-[url('/src/assets/BackgroundRight.svg')] after:bg-no-repeat after:right-0
                          sm:after:h-[588px] sm:after:w-[588px]
                          lg:after:bg-none
                          xl:after:right-0
                          xl:after:bg-[url('/src/assets/BackgroundRight.svg')]
                          xl:after:mr-[-8rem] after:bg-left "
            />
          }
        >
          <div>
            <div className="mb-12 lg:mb-8">
              <InfoDescription
                title={'High Quality Components for no-code platform'}
              >
                The components available on сosmith.io are of the highest
                quality and will help developers create amazing applications on
                no-code platforms. All components are tested and verified to
                ensure that they work as intended, providing an excellent user
                experience.
              </InfoDescription>
              <div
                className="lg:flex lg:flex-col lg:gap-6 lg:mt-8 lg:mb-8 lg:max-w-[331px]
            xl:flex-row xl:gap-6 xl:max-w-[588px] "
              >
                <InfoFeatures
                  imageUrl={TargetPurple}
                  title="Сreative developers"
                >
                  Developers on no-code platforms can get really cool
                  applications
                </InfoFeatures>

                <InfoFeatures
                  imageUrl={ComponentGreen}
                  title="Unique component"
                >
                  You can quickly find the components you need to make your
                  applications unique.
                </InfoFeatures>
              </div>
            </div>

            <div className="flex justify-center lg:block">
              <BuyNowButton />
            </div>
          </div>
        </AdaptiveImageCard>
      </div>
    </div>
  );
};
