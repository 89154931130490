import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button, FormControl, FormHint, FormInput } from '../../generic';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldState } from '../../../hooks/auth/useFieldState';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerContactUsMessage } from '../../../lib/firebase';
import { ButtonLoader } from '../../generic/ButtonLoader';

const emailRegex =
  /^([a-zA-Z0-9]+){1}([a-zA-Z0-9?'"`#$%&*+-_./|^{}~]+){1}@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,3})$/;

const schemaBuilder = () =>
  yup.object({
    email: yup
      .string()
      .matches(emailRegex, 'Please provide a valid email')
      .required(),
  });

export const EmailForm = () => {
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schemaBuilder()),
  });
  const [isLoading,setIsLoading]=useState(false);

  const onSubmit = useCallback(
    async (data: yup.InferType<ReturnType<typeof schemaBuilder>>) => {
      try {
        setIsLoading(true);
        const requestData = {
          ...data,
          isSubscribed: true,
        };
        await registerContactUsMessage(requestData);
        toast.success('Thank you. We will contact you ASAP');
        setIsLoading(false);
        reset();
      } catch (error) {
        toast.error('Something went wrong. Please try again');
        console.log(error);
      }
    },
    []
  );

  return (
    <form
      className="flex flex-col justify-center gap-4 max-w-md  mx-auto lg:mx-0 lg:max-w-full lg:justify-start lg:flex-row lg:gap-3"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl
        variant={useFieldState(formState.errors.email?.message).variant}
        className="relative lg:w-auto"
      >
        <FormInput
          id="email"
          placeholder="Enter your email"
          className="lg:w-80"
          {...register('email')}
        />
        <FormHint
          hint={useFieldState(formState.errors.email?.message).message}
          className="absolute bottom-[-20px]"
        />
      </FormControl>
      <Button type="dark">
        {
          isLoading?<ButtonLoader />:'Enter your email'
        }
      </Button>
    </form>
  );
};
