export const searchAndHighlight = (query: string): void => {
  const highlightedElements = document.querySelectorAll('.highlight');
  highlightedElements.forEach((el) => {
    const parent = el.parentNode;
    if (parent) {
      parent.replaceChild(document.createTextNode(el.textContent || ''), el);
      parent.normalize();
    }
  });

  const validQuery = query.trim();
  if (!validQuery || validQuery.length < 2 || !/^[a-zA-Z0-9]+$/.test(validQuery)) {
    return;
  }

  const regex = new RegExp(`(${validQuery})`, 'gi');

  const elementsToSearch = document.querySelectorAll('div, a, strong, span, p, li, h1, h2, h3, h4, h5, h6');

  elementsToSearch.forEach((element) => {
    if (!(element as HTMLElement).closest('.no-search')) {
      element.childNodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent;
          if (text && regex.test(text)) {
            const fragment = document.createDocumentFragment();
            let lastIndex = 0;

            text.replace(regex, (match, _, offset) => {
              fragment.appendChild(document.createTextNode(text.slice(lastIndex, offset)));

              const highlightSpan = document.createElement('span');
              highlightSpan.className = 'highlight bg-cosmith-lavanda-400';
              highlightSpan.textContent = match;

              fragment.appendChild(highlightSpan);

              lastIndex = offset + match.length;

              return match;
            });

            fragment.appendChild(document.createTextNode(text.slice(lastIndex)));

            if (node.parentNode) {
              node.parentNode.replaceChild(fragment, node);
            }
          }
        }
      });
    }
  });
};