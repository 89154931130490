import { useMemo } from 'react';

export function useAuthPageProps () {
  const containerClassName = useMemo(() => {
    return 'max-w-[1280px] mx-auto w-full h-full px-4 md:px-10';
  }, []);

  return {
    containerClassName
  };
}
