import paypalImg from '../../assets/payment-methods/paypal.svg';
import visaImg from '../../assets/payment-methods/visa.svg';
import mastercardImg from '../../assets/payment-methods/mastercard.svg';
import skrillImg from '../../assets/payment-methods/skrill.svg';
import payoneerImg from '../../assets/payment-methods/payoneer.svg';
import gPayImg from '../../assets/payment-methods/google-pay.svg';
import aPayImg from '../../assets/payment-methods/apple-pay.svg';
import stripeImg from '../../assets/payment-methods/stripe.svg';

interface Props {
  className?: string;
}

const METHODS: { src: string, alt: string }[] = [
  { src: paypalImg, alt: 'Paypal' },
  { src: visaImg, alt: 'Visa' },
  { src: mastercardImg, alt: 'Mastercard' },
  { src: skrillImg, alt: 'Skrill' },
  { src: payoneerImg, alt: 'Payoneer' },
  { src: gPayImg, alt: 'Google pay' },
  { src: aPayImg, alt: 'Apple pay' },
  { src: stripeImg, alt: 'Stripe' },
];

export default function ProductPlanWrapper ({ className = '' }: Props): JSX.Element {
  return (
    <div className={`
      border-solid border border-cosmith-primary-900/10 rounded-[32px] pt-12 pb-11 px-6
      sm:pt-14
      ${className}
    `}>
      <div className="text-cosmith-primary-900/60 text-center text-lg">We accept these payment method:</div>

      <div className={`
        grid gap-6 item-center justify-center mx-auto
        grid-cols-[repeat(2,minmax(58px,58px))] mt-8
        sm:grid-cols-[repeat(4,minmax(58px,58px))] sm:mt-12
        lg:grid-cols-[repeat(8,minmax(58px,58px))]
      `}>
        {METHODS.map(({ src, alt }) => (
          <img key={alt} src={src} alt={alt} width="58" height="40" />
        ))}
      </div>
    </div>
  );
}
