import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { billingListState } from '../state';
import { billingList } from '../state/data/billingList';

export function useBillingListLoading () {
  const [loading, setLoading] = useState(false);
  const [billingData, setBillingData] = useRecoilState(billingListState);

  const hasData = Boolean(billingData && billingData.length);

  const fetchBillingData = useCallback(async () => {
    setLoading(true);

    try {
      await new Promise((r) => setTimeout(r, 1000));

      setBillingData(billingList);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!hasData) {
      fetchBillingData();
    }
  }, []);

  return {
    loading,
    billingData
  };
}
