import CloseIcon from '../../../assets/icons/close.svg';
import BurgerIcon from '../../../assets/icons/burger.svg';
import { useState } from 'react';
import { useScrollLock } from '../../../hooks/useScrollLock';

interface IBurgerProps {
  onBurgerClick: () => void;
  isMenuOpen: boolean;
}

export const Burger = ({ onBurgerClick, isMenuOpen }: IBurgerProps) => {
  const icon = isMenuOpen
    ? { src: CloseIcon, alt: 'close' }
    : { src: BurgerIcon, alt: 'burger' };
  return (
    <button className="lg:hidden" type="button" onClick={onBurgerClick}>
      <img src={icon.src} alt={icon.alt} />
    </button>
  );
};

export const useNavMenu = () => {
  const [isNavMenuOpen, setMenuState] = useState(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  const toggleNavMenu = () => {
    setMenuState((isBurgerMenuVisible) => {
      isBurgerMenuVisible ? unlockScroll() : lockScroll();
      return !isBurgerMenuVisible;
    });
  };

  return {
    isNavMenuOpen,
    toggleNavMenu,
  };
};
