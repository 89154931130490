import { SectionMainText } from '../generic/landing/SectionMainText';
import { Button } from '../generic';
import { ArrowRight } from '../../assets/how-its-work/ArrowRight';

interface IParagraphsProps {
  paragraphs: string[];
  showAll: boolean;
  isOpen: boolean;
  setShowAll: (value: boolean) => void;
}
export const Paragraphs = ({
  paragraphs,
  showAll,
  isOpen,
  setShowAll,
}: IParagraphsProps) => {
  const firstPart = paragraphs.slice(0, 2);
  const secondPart = paragraphs.slice(2);
  return (
    <>
      <ul
        className={`flex flex-col gap-3 mt-4 lg:mt-5 ${
          showAll && 'overflow-y-auto'
        }`}
      >
        {firstPart.map((paragraph) => (
          <li key={paragraph}>
            <SectionMainText className='text-start'>
              {paragraph}
            </SectionMainText>
          </li>
        ))}
      </ul>
      <div
        className={`${
          showAll ? 'max-h-[1200px]' : 'max-h-[80px]'
        }  overflow-y-hidden transition-[max-height] duration-700 ease-in-out `}
      >
        {paragraphs.length > 2 && !showAll && isOpen && (
          <Button
            type='link'
            className='tracking-[-0.01rem] my-2 ml-1 lg:leading-[1.44] lg:tracking-[-0.014rem] lg:mt-[14px] group'
            onClick={() => setShowAll(true)}
          >
            Read more
            <ArrowRight className='fill-cosmith-primary-900/60 group-hover:fill-cosmith-primary-900' />
          </Button>
        )}
        <ul className='flex flex-col gap-3 mt-4 lg:mt-5'>
          {showAll &&
            secondPart.map((paragraph) => (
              <li key={paragraph}>
                <SectionMainText className='text-start'>
                  {paragraph}
                </SectionMainText>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};
