export const content = [
  {
    title: 'Creativity with Cosmith.io',
    paragraphs: [
      '1. Who are we?',
      'We’re a team of passionate low-code developers with a strong background in full-stack development. Our team members have extensive experience in a wide range of technologies and platforms, including FlutterFlow, Adalo, React, Next.js, as well as integration with Firebase, Xano, Make, Zapier, and more.',
      '2. What makes us unique?',
      'Our deep expertise in both traditional development and low-code platforms allows us to build solutions that are both creative and technically sound.',
      '3. What kind of projects have we worked on?',
      'We`ve delivered solutions in various domains, including healthcare, wineries, dating apps, social media platforms, and even taxi apps.',
      '4. How do we approach low-code development?',
      'We combine our technical knowledge with a creative mindset to build efficient, scalable, and tailored low-code solutions for our clients.',
      '5. Why choose us?',
      'We provide  excellent technical support to ensure that your applications work seamlessly with our components. With Cosmith.io, you dont need any coding expertise to create amazing applications.',
      'Our team of experts is available to help you achieve your business goals and guide you through the development process.',
    ],
  },
  //   {
  //     title: 'Cosmith.io',
  //     paragraphs: [
  //       'We provide excellent technical support. With Cosmith.io, you dont need any coding expertise to create amazing applications.',
  //       'Our team of experts is available to help you achieve.',
  //     ],
  //   },
  {
    title: 'Adalo',
    paragraphs: [
      '1. What makes your team experts in Adalo?',
      'Our team consists of experienced low-code consultants who have worked extensively with Adalo. We understand the platform deeply and can leverage its tools to build powerful, custom apps quickly and efficiently.',
      '2. How fast can you build an app using Adalo?',
      ' Adalo is designed to streamline development, so we can deliver a functional app in a much shorter time frame than traditional coding. Depending on the project’s complexity, many apps can be built in just a few weeks.',
      '3. Can you build custom features with Adalo?',
      'Yes, We can create custom component for your project. Adalo gives us the flexibility to design custom features and integrate third-party services, ensuring the app meets your unique business needs without sacrificing quality.',
      '4. Can you build a fully customized app with Adalo?',
      'Absolutely! Adalo allows us to create custom features, layouts, and integrations tailored to your specific business needs, all without complicated coding.',
      'We provide excellent technical support. With Cosmith.io, you dont need any coding expertise to create amazing applications.',
      '5. What kind of apps can you build with Adalo?',
      'We can build both mobile and web apps with various features like user authentication, databases, payment systems, and more — whatever your project requires.',
      '6. Do you offer ongoing support after the app is built?',
      'Yes, we provide ongoing support for updates, troubleshooting, and feature improvements even after your app is live to ensure everything runs smoothly.',
    ],
  },
  {
    title: 'FlutterFlow',
    paragraphs: [
      '1. Why should I choose your team for FlutterFlow development?',
      'Our team specializes in FlutterFlow low-code platform as well, with a deep understanding of how to create visually stunning and highly functional apps. We combine our expertise in design and development to deliver powerful, scalable apps quickly.',
      '2. How fast can you build an app using FlutterFlow?',
      'FlutterFlow speeds up the development process, allowing us to create apps much faster than traditional coding. Depending on your project’s complexity, we can have a fully functional app ready in weeks, not months.',
      '3. Can you build custom features with FlutterFlow?',
      'Yes, FlutterFlow gives us the flexibility to design custom features and integrate third-party services, ensuring the app meets your unique business needs without sacrificing quality.',
      '4. What types of apps can you create with FlutterFlow?',
      'We can build mobile and web apps with rich features, including real-time databases, push notifications, payment gateways, and more, using FlutterFlow`s extensive toolkit.',
      '5. Do you offer ongoing support for FlutterFlow apps?',
      'Absolutely! We provide ongoing maintenance, updates, and feature enhancements to keep your app running smoothly and up to date with any new requirements.',
    ],
  },
];
