import { Product } from './Product';
import { Installation } from './Installation';

interface IOptions {
  product: Product;
  installation: Installation
}

export class AccountComponent {
  private readonly _product: Product;
  private readonly _installation: Installation;

  constructor (options: IOptions) {
    this._product      = options.product;
    this._installation = options.installation;
  }

  get id () {
    return this._product.libraryId;
  }

  get product () {
    return this._product;
  }

  get installation () {
    return this._installation;
  }
}
