import { atom } from 'recoil';
import { Product } from '../../models/Product';

export const productListState = atom<Product[]>({
  key: 'productListState',
  default: []
});

export const productsListByCategorieState = atom<Product[]>({
  key: 'productsByCategorie',
  default: []
});