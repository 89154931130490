import { useCallback } from 'react';

export const useScrollLock = () => {
  const lockScroll = useCallback(
    () => document.body?.classList.add('scrollLock'),
    []
  );

  const unlockScroll = useCallback(
    () => document.body?.classList.remove('scrollLock'),
    []
  );

  return {
    lockScroll,
    unlockScroll,
  };
};
