import SadNoResults from '../../assets/sadNoResult.png';

export const MyComponentEmpty = ({ loading }: { loading: boolean }) => {
  if (loading) {
    return <></>;
  }
  return (
    <section className="flex flex-col items-center ">
      <img src={SadNoResults} alt="icon" />
      <h3 className="text-2xl font-semibold leading-[1.33] tracking-[-0.024rem] text-center">
        Oops!
      </h3>
      <p className="mb-4 text-center text-base font-medium leading-6 tracking-[-0.01rem] text-cosmith-primary-900/60">
        You don't have any added components!
      </p>
    </section>
  );
};
