import starRounded from '../../../assets/solutions-page/StarRounded.svg';
import starIcon from '../../../assets/solutions-page/StarIcon.svg';

// TODO - change this constant into the info from TrustPilot widget
const quantity = 5;
interface IReviewStarstQty {
  type?: 'dark' | 'light';
}
export const ReviewStarstQty = ({ type }: IReviewStarstQty) => {
  const starsArr = Array.from({ length: quantity }, (_, index) => index);
  return (
    <div className="flex gap-2 m-auto">
      {starsArr.map((_, index) => (
        <div key={index}>
          <img src={type === 'dark' ? starRounded : starIcon} alt="stars" />
        </div>
      ))}
    </div>
  );
};
