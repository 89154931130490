import { ArrowPathIcon } from '@heroicons/react/20/solid';

interface Props {
  className?: string;
}

const TOKEN_COUNT = ['', '', ''];

export default function TokenUsageHistory ({ className = '' }: Props): JSX.Element {
  return (
    <div className={`token-usage-history w-full ${className}`}>
      <div className="font-medium">Token usage history</div>

      <div className="token-list space-y-12 mt-5">
        {TOKEN_COUNT.map((_, index) => (
          <div key={index} className="token-item">
            <div className="flex gap-x-1 items-center">
              <ArrowPathIcon className="w-5 h-5" />
              <span>Used token - February 20, 2023</span>
            </div>

            <div className="pl-4 pt-2 ml-[9px] mt-1 border-l border-cosmith-primary-900">
              Used xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
