import { AccountComponent, Product } from '../../state/models';
import { Routes } from '../../util/constants';
import { NavLinkButton } from '../generic';

interface IPaymentButtonProps {
  isUserAuth: boolean;
  installedComponent: AccountComponent | null;
  product: Product;
  isPurchased?: boolean;
}

export const PaymentButton = ({
  isUserAuth,
  installedComponent,
  product,
  isPurchased,
}: IPaymentButtonProps) => {
  const getCurrentState = () => {
    if (!isUserAuth) {
      return 'UnAuthorized';
    } else if (!installedComponent) {
      return 'NotInstalled';
    } else if (!isPurchased) {
      return 'NotPurchased';
    }
    return 'Purchased';
  };

  switch (getCurrentState()) {
    case 'UnAuthorized':
      return (
        <NavLinkButton to={Routes.login()} type="dark" size="normal">
          14 days trial
        </NavLinkButton>
      );
    case 'NotInstalled':
      return (
        <NavLinkButton
          to={Routes.myCategoryProductInstallation(product.name)}
          type="dark"
          size="normal"
        >
          14 days trial
        </NavLinkButton>
      );
    case 'NotPurchased':
      return (
        <NavLinkButton
          to={Routes.myCategoryProductPricing(installedComponent?.product.name)}
          state={product.id}
          type="dark"
          size="normal"
        >
          Buy now
        </NavLinkButton>
      );
    case 'Purchased':
      return (
        <NavLinkButton
          to={Routes.myCategoryProductPricing(installedComponent?.product.name)}
          state={product.id}
          type="link"
          size="normal"
        >
          Edit Subscription
        </NavLinkButton>
      );
    default:
      return null;
  }
};
