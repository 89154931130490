import { selector } from 'recoil';
import { testProductListState } from '../atoms/testProductListState';
import { productFilterState } from '../atoms/productFilterState';
import { productSearchState } from '../atoms/productSearchState';
import { productSortState } from '../atoms/productSortState';

export const filteredProductListState = selector({
  key: 'filteredProductListState',
  get: ({ get }) => {
    const list   = get(testProductListState);
    const filter = get(productFilterState);
    const search = get(productSearchState);
    const sort   = get(productSortState);

    const filtered = [...list]
      .filter((item) => !filter || item.relatedCategories.indexOf(filter) > -1)
      .filter((item) => !search || `${item.name} ${item.description}`.toLowerCase().search(search.toLowerCase()) > -1);

    if (sort) {
      filtered.sort((p1, p2) => p1.price - p2.price);
    }

    return filtered;
  },
});
