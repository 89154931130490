import { IFirebaseProduct } from '../../types';

export class Product {
  constructor (
    protected readonly _data: IFirebaseProduct & { firebaseId: string }
  ) {}

  get id () {
    return this.libraryId || this.name;
  }
  get firebaseId () {
    return this._data.firebaseId;
  }
  get libraryId () {
    return this._data.libraryId;
  }
  get descriptionFull () {
    return this._data.descriptionFull;
  }
  get relatedCategories () {
    return this._data.relatedCategories;
  }
  get imgUrl () {
    return this._data.imgUrl;
  }
  get images () {
    return this._data.images;
  }
  get issues () {
    return this._data.issues;
  }
  get cases () {
    return this._data.cases;
  }
  get plans () {
    return this._data.plans;
  }
  get issuesDescription () {
    return this._data.issuesDescription;
  }
  get installation () {
    return this._data.installation;
  }

  get name () {
    return this._data.name;
  }

  get title () {
    return this._data.title;
  }

  get description () {
    return this._data.description;
  }

  get imageUrl () {
    return this._data.imgUrl;
  }

  get price () {
    if (!this._data.price) {
      return 0;
    }
    const [price] = this._data.price.split('/');

    return parseFloat(price);
  }

  get pricePeriod () {
    if(!this._data.price){
      return '';
    }
    const [, period] = this._data.price.split('/');

    return period || 'month';
  }

  get tags () {
    return ['14 days trial'];
  }

  get searchString () {
    return `${this.name} ${this.description} ${this.title}`.toLowerCase();
  }

  get status () {
    return this._data.status;
  }

  get applyToBetaUrl () {
    return this._data.applyToBetaUrl || '';
  }

  get categories () {
    return this._data.categories;
  }

  get features () {
    return this._data.features;
  }
}
