import { scrollToTop } from '../../../util/scrollToTop';
import { NavLinkButton } from '../../generic/NavLinkButton';
export const BuyNowButton = () => {
  return (
    <NavLinkButton
      to="/category"
      type="dark"
      size="normal"
      className="min-w-[19rem] max-w-[25rem] lg:min-w-[9.75rem] lg:max-w-[9.75rem]"
      onClick={scrollToTop}
    >
      Buy now
    </NavLinkButton>
  );
};
