import { SelectHTMLAttributes, forwardRef } from 'react';
import { ChangeHandler, FormItemVariant } from '../../../types';

interface ISelectOption {
  id: string;
  value: string | number;
  label: string;
  disabled?: boolean;
}

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  variant?: FormItemVariant;
  options: ISelectOption[];
  placeholder?: string;
  onChange: ChangeHandler;
}

const VARIANT_PROPS: Record<FormItemVariant, string> = {
  normal : 'border-cosmith-primary-900/10',
  success: 'text-cosmith-success-500 border-cosmith-success-200 bg-cosmith-success-50',
  error  : 'text-cosmith-error-500 border-cosmith-error-200 bg-cosmith-error-50'
};

export const FormSelect = forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const { className = '', onChange, variant = 'normal', placeholder, options, ...restProps } = props;

  return (
    <select
      className={[
        'cosmith-form-select',
        'w-full', 'relative',
        'flex', 'items-center',
        'border-solid border rounded-lg',
        'shadow px-4 h-12',
        'focus:outline focus:outline-[3px] focus:outline-cosmith-lavanda-400 focus:border-transparent',
        VARIANT_PROPS[variant],
        className
      ].join(' ')}
      {...restProps}
      ref={ref}
      onChange={onChange}
    >
      <option value="" disabled={true}>{placeholder}</option>

      {options.map((item) => (
        <option key={item.value} value={item.value}>{item.label}</option>
      ))}
    </select>
  );
});
