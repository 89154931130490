import { PropsWithChildren } from 'react';

type Props = {
  className?: string;
}

export function FormDescription ({ className = '', children }: PropsWithChildren<Props>) {
  return (
    <h2
      className={[
        'cosmith-form-description',
        'text-center w-full mt-3 mb-6',
        className
      ].join(' ')}
    >
      {children}
    </h2>
  );
}
