import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export default function MyComponentListWrapper ({ children, className = '' }: PropsWithChildren<Props>): JSX.Element {
  return (
    <div className={`${className} my-components-wrapper grid grid-cols-1 auto-rows-max gap-6 justify-start`}>
      {children}
    </div>
  );
}
