import { Price, Button, Tag } from '../generic';
import dotImag from '../../assets/list-dot.svg';
import { Plan } from '../../state/models';

interface Props {
  className?: string;
  plan: Plan;
  tag: string;
  isActive: boolean;
  onClick: () => void;
}

export default function ProductPlanCard ({ className = '', plan, tag = '', isActive, onClick }: Props): JSX.Element {
  return (
    <div
      className={`${className} product-card
      flex flex-col
      border-solid border border-cosmith-primary-900/10 rounded-3xl
      divide-y divide-border-cosmith-primary-900/10
      w-full px-6 py-6
      md:px-3
      xl:px-6
      ${isActive ? 'shadow-[0_0_10px_2px_rgba(0,0,0,0)] shadow-cosmith-success-600/50' : ''}
    `}
    >
      <section className="head-section pb-6 flex flex-col">
        <div className="flex items-center justify-between gap-2 order-1">
          <h3 className="text-2xl font-semibold">{plan.name}</h3>
          {tag && <Tag type="warn" label={tag} />}
        </div>

        <div className="text-cosmith-primary-900/60 lg:h-[75px] mt-2 order-3 lg:order-2">{plan.description}</div>

        <Price className="mt-4 mb-2 xl:mt-0 xl:mb-0 order-2 lg:order-3" priceClass="text-4xl md:text-5xl" price={plan.price} period={plan.paymentCycle} />
      </section>

      <section className="main-info-section py-6 grow">
        <ul className="list-image-none pl-2 space-y-4">
          {plan.features.map((feature, index) => (
            <li key={index} className="flex items-start">
              <img src={dotImag} alt="marker" className="mr-5 mt-2" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </section>

      <section className="footer-section pt-6 flex flex-col">
        <Button className="w-full" onClick={onClick} disabled={isActive}>
          Get started
        </Button>
      </section>
    </div>
  );
}
