import moment from 'moment';
import { useMemo } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { Table, Button, TableGrid } from '../generic';
import type { ITableColumn, ITableGrid } from '../../types';

interface Props {
  isMobile: boolean;
  className?: string;
}

const COLUMNS: ITableColumn[] = [
  { id: 'date',    header: 'Date' },
  { id: 'desc',    header: 'Description', flex: 2 },
  { id: 'amount',  header: 'Amount' },
  { id: 'actions', header: '',            width: 185 }
];

const history = [
  { id: 1, date: 1647036000000, description: 'Payment (mastercard 0485)', amount: '82.2' },
  { id: 2, date: 1649710800000, description: 'Payment (mastercard 0485)', amount: '85' },
  { id: 3, date: 1652302800000, description: 'Payment (mastercard 0485)', amount: '101.3' },
  { id: 4, date: 1654981200000, description: 'Payment (mastercard 0485)', amount: '12' },
];

export default function BillingHistoryTable ({ isMobile, className = '' }: Props): JSX.Element {
  const tableData = useMemo(() => history.map((h) => [
    { key: 'date',    value: moment(h.date).format('MMM DD, YYYY') },
    { key: 'desc',    value: h.description },
    { key: 'amount',  value: `$${h.amount}` },
    { key: 'actions', value: (
      <Button type="link" className="space-x-3">
        <span>Download: CSV</span>
        <ArrowDownTrayIcon className="w-5 h-5" />
      </Button>
    ) }
  ]), [history]);

  const gridColumns = useMemo(() => {
    const columns = COLUMNS.slice(0, COLUMNS.length - 1);

    return columns.map((c) => ({
      ...c,
      rowClassName: 'text-cosmith-primary-900/60'
    }));
  }, []);

  return (
    <div className={`billing-history ${className}`}>
      <div className="font-semibold">Billing history</div>

      {isMobile
        ? (
          <TableGrid
            id="methods"
            className="w-full mt-8"
            columns={gridColumns}
            data={tableData}
            bottomColumn={(_: ITableGrid) => (
              <Button type="link" className="space-x-3 w-full">
                <span>Download: CSV</span>
                <ArrowDownTrayIcon className="w-5 h-5" />
              </Button>
            )}
          />
        ) : (
          <Table
            id="methods"
            className="w-full mt-8"
            columns={COLUMNS}
            data={tableData}
          />
        )
      }

    </div>
  );
}
