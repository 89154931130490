import { PropsWithChildren } from 'react';

interface GenericListWrapperProps {
  loading: boolean;
  error: Error | undefined;
  errorComponent: JSX.Element;
  loadingComponent: JSX.Element;
}

export const GenericListWrapper = ({
  loading,
  error,
  loadingComponent,
  errorComponent,
  children,
}: PropsWithChildren<GenericListWrapperProps>) => {
  if (loading) {
    return loadingComponent;
  }

  if (error) {
    return errorComponent;
  }
  return children;
};
