import { NavLinkButton } from '../../../generic';
import { requestComponentLink } from '../../../../appconfig';
export const Navigation = () => {
  return (
    <nav className='hidden lg:block grow border-r lg:pr-1 xl:pr-3'>
      <div className='flex justify-between'>
        <ul className='flex gap-1'>
          <li>
            <NavLinkButton to='/category' type='link' size='extraSmall'>
              Category
            </NavLinkButton>
          </li>
          <li>
            <NavLinkButton to='/solutions' type='link' size='extraSmall'>
              Solutions
            </NavLinkButton>
          </li>
          <li>
            <NavLinkButton to='/user-case' type='link' size='extraSmall'>
              User case
            </NavLinkButton>
          </li>
          <li>
            <NavLinkButton to='/work' type='link' size='extraSmall'>
              How it works?
            </NavLinkButton>
          </li>
        </ul>
        <ul className='flex gap-1'>
          <li className='lg:max-w-[100px] xl:max-w-full'>
            <NavLinkButton
              to={requestComponentLink}
              type='link'
              size='extraSmall'
              target='_blank'
              className='lg:text-balance xl:text-nowrap	 '
            >
              Request a component
            </NavLinkButton>
          </li>
          <li>
            <NavLinkButton to='/talk-to-expert' type='link' size='extraSmall'>
              Talk to an expert
            </NavLinkButton>
          </li>
        </ul>
      </div>
    </nav>
  );
};
