export const CrossIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="#2F2B43">
      <path
        fillRule="evenodd"
        d="M10 8.586l4.95-4.95 1.414 1.414L11.414 10l4.95 4.95-1.414 1.414L10 11.414l-4.95 4.95-1.414-1.414L8.586 10 3.636 5.05l1.414-1.414L10 8.586z"
        clipRule="evenodd"
      />
    </svg>
  );
};
