import moment from 'moment';
import { useMemo } from 'react';
import { Button, Table, TableGrid } from '../generic';
import type { IBillingData, ITableColumn, ITableGrid } from '../../types';

interface Props {
  data: IBillingData[];
  isMobile: boolean;
  className?: string;
}

const COLUMNS: ITableColumn[] = [
  { id: 'product',  header: 'Usage', flex: 2 },
  { id: 'price',    header: 'Price/m' },
  { id: 'bought',   header: 'Bought' },
  { id: 'expires',  header: 'Expires' },
  { id: 'renew',    header: 'Renew', width: 65 },
  { id: 'daysLeft', header: 'Days left' },
  { id: 'actions',  header: 'Actions' }
];

export default function EstimationHistoryTable ({ data, isMobile, className = '' }: Props): JSX.Element {
  const gridColumns = useMemo(() => {
    const columns = COLUMNS.slice(0, COLUMNS.length - 1);

    return columns.map((c) => ({
      ...c,
      rowClassName: 'text-cosmith-primary-900/60'
    }));
  }, []);

  const tableData = useMemo(() => data.map((d) => [
    { key: 'product',  value: d.product },
    { key: 'price',    value: `$${d.price}` },
    { key: 'bought',   value: moment(d.boughtDate).format('DD.MM.YYYY') },
    { key: 'expires',  value: moment(d.expireDate).format('DD.MM.YYYY') },
    { key: 'renew',    value: d.renew ? 'On' : 'Off' },
    { key: 'daysLeft', value: `${moment().diff(moment(d.expireDate), 'days')} days` },
    { key: 'actions',  value: <Button size="extraSmall">Change</Button> }
  ]), [data]);

  if (isMobile) {
    return (
      <TableGrid
        id="billing"
        className={`w-full ${className}`}
        columns={gridColumns}
        data={tableData}
        bottomColumn={(_: ITableGrid) => (
          <Button className="w-full mt-4">Change</Button>
        )}
      />
    );
  }

  return (
    <Table
      id="billing"
      className={`w-full ${className}`}
      columns={COLUMNS}
      data={tableData}
    />
  );
}
