interface Props {
  src: string;
  alt: string;
  className?: string;
}

export function ProductImage ({ src, alt, className = '' }: Props): JSX.Element {
  return (
    <img src={src} alt={alt} className={`w-[106px] h-[106px] rounded-3xl border ${className}`} />
  );
}
