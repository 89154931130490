export const ComponentsIcon = ({
  className = '',
}: {
  className: string;
}) => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" className={className} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.49935 0.5H16.4993C16.7204 0.5 16.9323 0.587797 17.0886 0.744078C17.2449 0.900358 17.3327 1.11232 17.3327 1.33333V14.6667C17.3327 14.8877 17.2449 15.0996 17.0886 15.2559C16.9323 15.4122 16.7204 15.5 16.4993 15.5H1.49935C1.27834 15.5 1.06637 15.4122 0.910093 15.2559C0.753813 15.0996 0.666016 14.8877 0.666016 14.6667V1.33333C0.666016 1.11232 0.753813 0.900358 0.910093 0.744078C1.06637 0.587797 1.27834 0.5 1.49935 0.5ZM15.666 9.66667H12.8193C12.4951 10.4101 11.961 11.0428 11.2824 11.4871C10.6039 11.9315 9.81043 12.1681 8.99935 12.1681C8.18827 12.1681 7.39481 11.9315 6.71627 11.4871C6.03773 11.0428 5.50359 10.4101 5.17935 9.66667H2.33268V13.8333H15.666V9.66667ZM15.666 8V2.16667H2.33268V8H6.49935C6.49935 8.66304 6.76274 9.29893 7.23158 9.76777C7.70042 10.2366 8.33631 10.5 8.99935 10.5C9.66239 10.5 10.2983 10.2366 10.7671 9.76777C11.236 9.29893 11.4993 8.66304 11.4993 8H15.666Z" />
    </svg>
  );
};
