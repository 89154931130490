import { PropsWithChildren } from 'react';
interface ISectionSubTitleProps {
  className?: string;
}

export const SectionSubTitle = ({
  children,
  className,
}: PropsWithChildren<ISectionSubTitleProps>) => {
  return (
    <h2
      className={`text-4xl font-semibold leading-[1.17] tracking-[-0.041rem] mb-4 
      lg:text-5xl lg:leading-[1.125] lg:tracking-[-0.06rem] lg:mb-6 ${className}`}
    >
      {children}
    </h2>
  );
};
