import * as yup from 'yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldState } from './useFieldState';
import { signInWithCredentials, signInWithGoogle, setAuthPersistence, signOut } from '../../lib/firebase';
import { FirebaseError } from 'firebase/app';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../state';
import { Routes } from '../../util/constants';

const schemaBuilder = () => yup.object({
  email   : yup.string().email().required(),
  password: yup.string().required()
}).required();

export function useLoginForm () {
  const setLoading = useSetRecoilState(loadingState);
  const navigate = useNavigate();
  const location = useLocation();

  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schemaBuilder())
  });

  const onSubmit = useCallback(async (data: yup.InferType<ReturnType<typeof schemaBuilder>>) => {
    setLoading(true);
    setEmailError('');
    setPasswordError('');

    try {
      const userCredentials = await signInWithCredentials(data);

      if (userCredentials) {
        const { emailVerified } = userCredentials.user;

        if (!emailVerified) {
          signOut();
          navigate(Routes.waitingEmailConfirmation());
          return;
        }

        setAuthPersistence(rememberMe);
        navigate(location.state ? location.state.previousPath : Routes.myComponents(), { replace: true });
      }
    } catch (err) {
      if (err instanceof FirebaseError) {
        switch (err.code) {
          case 'auth/user-not-found': {
            setEmailError('Email not found');
            break;
          }
          case 'auth/wrong-password': {
            setPasswordError('Wrong password');
            break;
          }
        }
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const onSignInWithGoogle = async () => {
    setLoading(true);

    try {
      await signInWithGoogle();
      setAuthPersistence(rememberMe);
      navigate(location.state ? location.state.previousPath : Routes.myComponents(), { replace: true });
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return {
    email: {
      register: register('email'),
      state   : useFieldState(emailError || formState.errors.email?.message)
    },
    password: {
      register: register('password'),
      state   : useFieldState(passwordError || formState.errors.password?.message)
    },
    rememberMe: {
      register: {
        checked: rememberMe,
        onChange: (e: { target: HTMLInputElement }) => setRememberMe(e.target.checked)
      },
      state: {}
    },
    onSubmit: handleSubmit(onSubmit),
    onSignInWithGoogle
  };
}
