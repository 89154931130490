import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

export default function FormWrapper ({ className = '', children }: PropsWithChildren<Props>): JSX.Element {
  return (
    <div className={`installation-form w-full ${className}`}>
      {children}
    </div>
  );
}
