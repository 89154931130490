import { useMemo } from 'react';
import { ButtonSize, ButtonType } from '../../types';

interface Options {
  type: ButtonType;
  size: ButtonSize;
  disabled?: boolean;
  className?: string;
}

const BASE_CLASSES: string[] = [
  'text-center whitespace-nowrap',
  'flex justify-center items-center',
  'border border-solid rounded-lg',
  'transition-colors',
  'focus:outline focus:outline-[3px] focus:outline-cosmith-lavanda-400'
];

const BTN_TYPES_CLASSES: Record<ButtonType, string[]> = {
  primary: [
    'text-cosmith-primary-900 shadow',
    'bg-white hover:bg-cosmith-primary-50',
    'border-cosmith-primary-900/10 hover:border-cosmith-primary-900/20'
  ],
  link: [
    'text-cosmith-primary-900/60 hover:text-cosmith-primary-900 ',
    'bg-transparent hover:bg-cosmith-primary-100',
    'border-transparent'
  ],
  secondary: [
    'text-cosmith-primary-900',
    'bg-cosmith-primary-900/5 hover:bg-cosmith-primary-900/10',
    'border-cosmith-primary-900/5'
  ],
  dark: [
    'text-white',
    'bg-cosmith-primary-900 hover:bg-cosmith-primary-700',
    'border-cosmith-primary-900',
  ],
  text:[
    'text-cosmith-primary-900/60 hover:text-cosmith-primary-900 ',
    'border-transparent',
    '!justify-start',
    'focus:outline-none focus:outline-[0px] focus:outline-transparent'
  ],
  disabled: [
    'text-cosmith-primary-900/30',
    'bg-cosmith-primary-900/5',
    'border-transparent',
    'cursor-not-allowed'
  ]
};

const BTN_SIZE_CLASSES: Record<ButtonSize, string> = {
  normal    : 'h-12 px-4',
  small     : 'h-10 px-4',
  extraSmall: 'h-8 px-3',
  noSize    : ''
};

export function useButtonProps ({ type, size, disabled = false, className = '' }: Options) {
  const classNames = useMemo(() => {
    return [
      ...BASE_CLASSES,
      ...BTN_TYPES_CLASSES[disabled ? 'disabled' : type],
      BTN_SIZE_CLASSES[size],
      className
    ].join(' ');
  }, [type, size, disabled, className]);

  return { classNames };
}
