import { useMemo } from 'react';
import { Table, TableGrid } from '../generic';
import type { ITableColumn, ITableData, ITableGrid } from '../../types';
import { Plan } from '../../state/models';

interface Props {
  plans: Plan[];
  features?: string[];
  className?: string;
  isMobile: boolean;
}

export default function ProductPlanComparisonTable ({ plans, className = '', isMobile, features = [] }: Props): JSX.Element {
  const COLUMNS: ITableColumn[] = [
    { id: 'planName',   header: 'Usage' },
    { id: 'period',     header: 'Period of plan' },
    { id: 'price',      header: 'Price $' },
    ...features.map((feature)=> ({ id: feature, header: feature }))
  ]; 

  const { gridData, columns, tableData } = useMemo(() => {
    const gData = plans.map((p) => [
      { key: 'planName',   value: p.name },
      { key: 'period',     value: p.paymentCycle },
      { key: 'price',      value: p.price },
      ...features.map((feature,index)=> ({ key: feature, value: p.features[index] }))
    ]);

    const cols: ITableColumn[] = [
      { id: 'usage', header: 'Usage' },
      ...plans.map((p) => ({ id: p.name, header: p.name }))
    ]; 

    const tData: ITableData[][] = [
      [{ key: 'usage', value: 'Period of plan' }, ...plans.map((p) => ({ key: p.name, value: p.paymentCycle }))],
      [{ key: 'usage', value: 'Price, $' },       ...plans.map((p) => ({ key: p.name, value: p.price }))],
      ...features?.map((feature, index)=> [{ key: 'usage', value: feature }, ...plans.map((p) => ({ key: p.name, value: p.features[index] || '-' }))],)
    ];

    return {
      gridData : gData,
      tableData: tData,
      columns  : cols
    };
  }, [plans]);

  if (!plans.length) {
    return <></>;
  }

  return (
    <div className={className}>
      <div className="title font-semibold">Compare Plans and Features</div>
      {isMobile
        ? (
          <TableGrid
            id="prices"
            className="w-full mt-4"
            columns={COLUMNS}
            data={gridData}
            isTitleVisible={false}
            topColumn={(grid: ITableGrid) => (
              <div className="grid-row grid grid-cols-[1fr_2fr] gap-x-4 items-center pb-3 text-cosmith-primary-900/60">
                <span>Usage</span>
                <span>{grid.title}</span>
              </div>
            )}
          />
        ) : (
          <Table
            id="prices"
            className="w-full mt-8"
            columns={columns}
            data={tableData}
          />
        )
      }
    </div>
  );
}
