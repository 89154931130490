import { Query, collection, where, query, getDocs } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import { IProductPlan } from '../../types';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useState, useMemo } from 'react';
import { Plan } from '../../state/models';

export const useGetPlansById = (productId: string) => {
  const [docId, setdocId] = useState<string>();
  const productsCollectionRef = collection(firestore, 'products');
  const productsQuery = query(
    productsCollectionRef,
    where('libraryId', '==', productId)
  );

  (async () => {
    try {
      const querySnapshot = await getDocs(productsQuery);
      const { id } = querySnapshot.docs[0];
      setdocId(id);
    } catch (error) {
      console.log(error);
    }
  })();

  let plansQuery;

  if (docId) {
    const queryPlans = collection(
      firestore,
      'products',
      docId,
      'plans'
    ) as Query<IProductPlan>;
    plansQuery = queryPlans;
  }
  const [loadedPlans, loading, error] = useCollection<IProductPlan>(plansQuery);

  const plans = useMemo(() => {
    if (!loadedPlans) {
      return [];
    }

    return loadedPlans.docs
      .map((p) => new Plan({ id: p.id, ...p.data() }))
      .sort((a,b)=>a.orderNumber - b.orderNumber);
  }, [loadedPlans]);

  return { plans, loading, error };
};
