import { Routes } from '../../util/constants';

import { AuthPageWrapper } from '../../components/auth/AuthPageWrapper';
import { LoginInfo } from '../../components/auth/login/LoginInfo';
import { LoginForm } from '../../components/auth/login/LoginForm';

export const LoginPage = () => {
  return (
    <AuthPageWrapper
      linkHref={Routes.register()}
      linkText="Sign up"
      info={<LoginInfo />}
      form={<LoginForm />}
    />
  );
};
