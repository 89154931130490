import { ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { filterCategoryState, productPaginationState } from '../../state';
import { shownProductsPerPage } from '../../appconfig';

interface IOption {
  value: string;
  label: string;
}

interface IFilteredButtonsProps {
  options: IOption[];
}

export const FilterByCategoriesButtons = ({
  options,
}: IFilteredButtonsProps) => {
  const [selectedCategory, setSelectedCategory] =
    useRecoilState(filterCategoryState);
  const setProductsPerPage = useSetRecoilState(productPaginationState);
  const [_, setSearchParams] = useSearchParams();

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(event.target.value);
    setProductsPerPage(shownProductsPerPage);
    const categories = event.target.value;

    if (categories !== '') {
      setSearchParams({ categories });
    }
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLSpanElement>,
    value: string
  ) => {
    if (event.key === 'Enter') {
      setSelectedCategory(value);
      setProductsPerPage(shownProductsPerPage);
      if (value !== '') {
        setSearchParams({ categories: value });
      }
    }
  };

  return (
    <form className='flex justify-center lg:justify-start'>
      <div role='group'>
        {options.map((option) => (
          <label
            key={option.value}
            className={`cursor-pointer border-y border-r border-cosmith-primary-900/10
                last:rounded-e-lg
                first:rounded-s-lg first:border-l px-2
                sm:font-medium leading-[1.5] tracking -[-0.01rem] text-base
                py-3 sm:px-4
                inline-block h-full
                transition-colors duration-700 ease-in-out
                ${
                  selectedCategory === option.value
                    ? 'bg-white transition-colors  '
                    : 'bg-cosmith-primary-50 transition-colors'
                }
                `}
          >
            <input
              type='radio'
              name='filter'
              value={option.value}
              checked={selectedCategory === option.value}
              onChange={handleRadioChange}
              className='invisible absolute'
            />
            <span
              tabIndex={0}
              className='focus-outline-custom p-2'
              onKeyDown={(e) => handleKeyPress(e, option.value)}
              onMouseDown={(e) => e.preventDefault()}
            >
              {option.label}
            </span>
          </label>
        ))}
      </div>
    </form>
  );
};
