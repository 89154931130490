import { useMemo } from 'react';
import { Button, Table } from '../generic';
import type { ITableColumn } from '../../types';

import visaImg       from '../../assets/payment-methods/visa.svg';
import mastercardImg from '../../assets/payment-methods/mastercard.svg';

interface Props {
  isMobile: boolean;
  className?: string;
}

const COLUMNS: ITableColumn[] = [
  { id: 'type',     header: 'Type', rowClassName: 'pl-6' },
  { id: 'desc',     header: 'Description', flex: 2 },
  { id: 'expires',  header: 'Expires', flex: 2 },
  { id: 'default',  header: 'Default' },
  { id: 'actions',  header: 'Actions', rowClassName: 'pr-6 justify-end' }
];

const TYPE_IMAGES: Record<string, string> = {
  visa      : visaImg,
  mastercard: mastercardImg
};

const payments = [
  { id: 1, type: 'visa',       description: 'Mastercard ending in 0485', expires: '01/2025' },
  { id: 2, type: 'mastercard', description: 'Mastercard ending in 1254', expires: '02/2026' },
];

export default function PaymentMethodTable ({ isMobile, className = '' }: Props): JSX.Element {
  const tableData = useMemo(() => payments.map((p) => [
    { key: 'type',     value: <img src={TYPE_IMAGES[p.type]} alt={p.type} width="58" height="40" /> },
    { key: 'desc',     value: p.description },
    { key: 'expires',  value: `Expires ${p.expires}` },
    { key: 'default',  value: 'Default' },
    { key: 'actions',  value: <Button size="extraSmall">Change</Button> }
  ]), [payments]);

  return (
    <div className={`payment-methods ${className} text-center lg:text-left`}>
      <div className="font-semibold">Payment methods</div>

      {isMobile
        ? (
          <div className="payment-methods mt-2">
            {payments.map((p) => (
              <div key={p.id} className="grid grid-cols-1 justify-items-center gap-y-5 border-b py-4">
                <img src={TYPE_IMAGES[p.type]} alt={p.type} width="46" height="32" />
                <div className="text-cosmith-primary-900/60 text-center">{p.description}</div>
                <div className="text-cosmith-primary-900/60 text-center">Expires {p.expires}</div>
                <div className="text-cosmith-primary-900/60 text-center">Default</div>
                <Button className="w-full">Change</Button>
              </div>
            ))}
          </div>
        ) : (
          <Table
            id="methods"
            className="w-full mt-8"
            columns={COLUMNS}
            data={tableData}
            isHeaderVisible={false}
          />
        )
      }


      <Button className="mt-6 w-full lg:w-auto" type="secondary">
        Add new payment method
      </Button>
    </div>
  );
}
