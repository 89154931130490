import { useRef } from 'react';
import { Breadcrumbs, PageTitle, PageSubtitle } from '../components/layout';
import { Preloader } from '../components/generic';
import ProductPlanWrapper from '../components/pricing/ProductPlanWrapper';
import ProductPlanCard from '../components/pricing/ProductPlanCard';
import ProductPlanPaymentMethods from '../components/pricing/ProductPlanPaymentMethods';
import ProductPlanComparisonTable from '../components/pricing/ProductPlanComparisonTable';
import { PlanPaymentModal, IPlanInfo } from '../components/pricing/PlanPaymentModal';
import { useCurrentProduct, useMyComponentList, useScreenSize } from '../hooks';
import { Routes } from '../util/constants';
import { useGetPlansById } from '../hooks/api/useGetPlansById';
import { useLocation } from 'react-router-dom';
import { EmptyDataResponse } from '../components/generic/EmptyDataResponse';
import { IModal } from '../types';
import { Plan } from '../state/models';

export const MyProductPricingPage = () => {
  const screen = useScreenSize();
  const { product } = useCurrentProduct();
  const location = useLocation();
  const { plans, loading } = useGetPlansById(
    location.state || product?.id || null
  );
  const { components } = useMyComponentList();
  const modalRef = useRef<IModal<IPlanInfo>>(null);

  const crumbs = [
    { label: 'Category', to: Routes.myCategory() },
    { label: product ? product.title : 'Pricing' },
  ];

  const component = components.find((c) => c.product.id === product?.id);

  const onPlanClick = (plan: Plan) => {
    if (product) {
      modalRef.current?.open({
        planId  : plan.id,
        planName: plan.name,
        productId: product.libraryId
      });
    }
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />

      <div className="h-fit flex-grow px-4 pb-6 md:overflow-y-auto sm:px-3">
        <section className="top-section">
          <PageTitle>Build your work this cosmith.io</PageTitle>
          <PageSubtitle>
            No matter what project you're working on, we've got you covered with
            the best component for any platform.
          </PageSubtitle>
        </section>

        <Preloader visible={loading} />

        {plans ? (
          <>
            <ProductPlanWrapper>
              {plans?.map((plan, index) => (
                <ProductPlanCard
                  key={plan.name}
                  plan={plan}
                  tag={index === 0 ? '7 days FREE' : ''}
                  isActive={component?.installation.activePlanId === plan.id}
                  onClick={() => component?.installation.activePlanId !== plan.id && onPlanClick(plan)}
                />
              ))}
            </ProductPlanWrapper>

            <ProductPlanPaymentMethods className="mt-6" />

            <ProductPlanComparisonTable
              className="mt-12"
              plans={plans}
              features={product?.features}
              isMobile={screen.lt.lg}
            />

            <PlanPaymentModal
              ref={modalRef}
            />
          </>
        ) : (
          <EmptyDataResponse />
        )}
      </div>
    </>
  );
};
