import { AdaptiveImageCard } from '../generic/AdaptiveImageCard';
import { DottedWrapperHero } from '../generic/landing/DottedWrapperHero';
import SolutinsHero_mob from '../../assets/solutions-page/SolutionsHero-mob.svg';
import SolutinsHero_tablet from '../../assets/solutions-page/SolutionsHero_tablet.svg';
import SolutinsHero_pc from '../../assets/solutions-page/SolutionsHero_pc.svg';
import { ResponsivePicture } from '../generic/ResponsivePicture';
import { TalkToAnExpertButton } from '../generic/landing/TalkToAnExpertButton';
import { HeroTitle } from '../generic/landing/HeroTitle';
import { HeroText } from '../generic/landing/HeroText';

const images = {
  mobile: {
    src: SolutinsHero_mob,
    width: 358,
  },
  tablet: {
    src: SolutinsHero_tablet,
    width: 500,
  },
  pc: {
    src: SolutinsHero_pc,
    width: 588,
  },
};

export const HeroSection = () => {
  return (
    <DottedWrapperHero>
      <div className="pt-12 pb-4 lg:pb-0 lg:pt-0 lg:mr-[-2rem] xl:mr-0">
        <AdaptiveImageCard
          secondChildrenclassName="lg:w-[500px] lg:h-[550px] xl:w-588px xl:h-[588px]"
          secondChildren={
            <ResponsivePicture imageUrl={SolutinsHero_pc} images={images} />
          }
        >
          <div className="flex flex-col gap-6 xl:gap-8">
            <div className="text-center lg:text-left">
              <HeroTitle>Component suitable for any companies</HeroTitle>
              <HeroText>
                Developers can save time when searching for components that they
                need for their projects
              </HeroText>
            </div>
            <div className="flex justify-center lg:block">
              <TalkToAnExpertButton />
            </div>
          </div>
        </AdaptiveImageCard>
      </div>
    </DottedWrapperHero>
  );
};
