export const CategoryIcon = ({
  className = '',
}: {
  className: string;
}) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" className={className} xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 15.6667C15.5 15.8877 15.4122 16.0997 15.2559 16.2559C15.0996 16.4122 14.8877 16.5 14.6667 16.5H1.33333C1.11232 16.5 0.900358 16.4122 0.744078 16.2559C0.587798 16.0997 0.5 15.8877 0.5 15.6667V6.90835C0.499912 6.78136 0.528848 6.65603 0.584597 6.54193C0.640346 6.42783 0.721433 6.32798 0.821667 6.25001L7.48833 1.06501C7.63462 0.951217 7.81467 0.889435 8 0.889435C8.18533 0.889435 8.36538 0.951217 8.51167 1.06501L15.1783 6.25001C15.2786 6.32798 15.3597 6.42783 15.4154 6.54193C15.4712 6.65603 15.5001 6.78136 15.5 6.90835V15.6667ZM13.8333 14.8333V7.31501L8 2.77835L2.16667 7.31501V14.8333H13.8333ZM3.83333 11.5H12.1667V13.1667H3.83333V11.5Z" />
    </svg>
  );
};
