import { Button } from '../../generic';
import FormWrapper from './FormWrapper';

interface Props {
  className?: string;
  onClick: () => void;
}

export default function FormStep0 ({ className = '', onClick }: Props): JSX.Element {
  return (
    <FormWrapper className={className}>
      <Button type="dark" onClick={onClick}>
        Install to Adalo
      </Button>
    </FormWrapper>
  );
}
