import { PropsWithChildren } from 'react';
import { AdaptiveImageCard } from '../AdaptiveImageCard';
import { SectionMainText } from './SectionMainText';
import { SectionSubTitle } from './SectionSubTitle';

interface Feature {
  id: number;
  value: string;
}
interface IBusinessFeaturesProps {
  features: Feature[];
  title?: string;
  secondChildrenclassName?: string;
  secondChildren?: JSX.Element;
}
export const BusinessFeaturesWrapper = ({
  features,
  title,
  children,
  secondChildrenclassName,
  secondChildren,
}: PropsWithChildren<IBusinessFeaturesProps>) => {
  return (
    <div className="lg:min-h-[37.25rem]">
      <AdaptiveImageCard
        secondChildren={secondChildren}
        secondChildrenclassName={secondChildrenclassName}
        className='lg:items-start'
      >
        <SectionSubTitle className="text-center lg:text-left lg:max-w-[486px]">
          {title}
        </SectionSubTitle>
        <SectionMainText className="mb-6 max-w-[700px]">
          {children}
        </SectionMainText>
        <ul>
          {features.map((feature) => (
            <li key={feature.id} className="mb-1.5 flex gap-2 items-center">
              <div
                className='flex w-9 h-9 justify-center items-center
                     before:content-[" "] before:bg-cosmith-lavanda-400 before:w-[13px] before:h-[13px] 
                     before:rounded-full '
              ></div>
              <SectionMainText className="text-start ">
                {feature.value}
              </SectionMainText>
            </li>
          ))}
        </ul>
      </AdaptiveImageCard>
    </div>
  );
};
