import { useState, useLayoutEffect, useMemo } from 'react';

import {
  SCREEN_WIDTH_XXS,
  SCREEN_WIDTH_SM,
  SCREEN_WIDTH_MD,
  SCREEN_WIDTH_LG,
  SCREEN_WIDTH_XL,
} from '../../util/constants';

type Sizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface Screen {
  width: number;
  height: number;
  name: Sizes;
  sizes: {
    xxs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
  lt: Record<Sizes, boolean>;
  gt: Record<Sizes, boolean>;
  xxs: boolean;
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

const DEFAULT_SCREEN: Omit<Screen, 'width' | 'height' | 'name'> = {
  sizes: {
    xxs: SCREEN_WIDTH_XXS,
    sm: SCREEN_WIDTH_SM,
    md: SCREEN_WIDTH_MD,
    lg: SCREEN_WIDTH_LG,
    xl: SCREEN_WIDTH_XL,
  },
  lt: {
    xxs: false,
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  },
  gt: {
    xxs: false,
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  },
  xxs: false,
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
};

const SIZES: Sizes[] = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'];

export function useScreenSize (): Screen {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let name: Screen['name'] = 'xs';

  switch (true) {
    case width < SCREEN_WIDTH_XXS: {
      name = 'xxs';
      break;
    }
    case width >= SCREEN_WIDTH_XXS && width < SCREEN_WIDTH_SM: {
      name = 'xs';
      break;
    }
    case width >= SCREEN_WIDTH_SM && width < SCREEN_WIDTH_MD: {
      name = 'sm';
      break;
    }
    case width >= SCREEN_WIDTH_MD && width < SCREEN_WIDTH_LG: {
      name = 'md';
      break;
    }
    case width >= SCREEN_WIDTH_LG && width < SCREEN_WIDTH_XL: {
      name = 'lg';
      break;
    }
    case width >= SCREEN_WIDTH_XL: {
      name = 'xl';
      break;
    }
  }

  const screen = useMemo(() => {
    const screenData: Omit<Screen, 'width' | 'height'> = {
      name,
      ...DEFAULT_SCREEN,
    };

    const index = SIZES.indexOf(name);

    for (const size of SIZES) {
      const sIndex = SIZES.indexOf(size);

      screenData[size] = index === sIndex;

      screenData.lt[size] = index < sIndex;
      screenData.gt[size] = index > sIndex;
    }

    return screenData;
  }, [name]);

  return { ...screen, width, height };
}
