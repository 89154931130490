import { BetterThenOthers } from '../components/generic/landing/BetterThanOthers';
import { FaqSection } from '../components/how-its-work/FaqSection';
import { MainSection } from '../components/how-its-work/main-section/MainSection';
import { DiscoverySection } from '../components/how-its-work/discovery-section/DiscoverySection';

export const HowItsWorkPage = () => {
  return (
    <>
      <MainSection />
      <DiscoverySection />
      <BetterThenOthers />
      <FaqSection />
    </>
  );
};
