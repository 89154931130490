import { useNavigate } from 'react-router-dom';
import { SetterOrUpdater } from 'recoil';
import { Routes } from '../../util/constants';
import { CrossIcon, LogoutIcon, MenuIcon } from '../../icons';
import { useContext } from 'react';
import { AccountContext } from '../../contexts/AccountContext';

export const TopBar = ({
  showSidebar,
  setShowSidebar,
  isMobile,
}: {
  showSidebar: boolean;
  setShowSidebar: SetterOrUpdater<boolean>;
  isMobile: boolean;
}) => {
  const navigate = useNavigate();
  const account = useContext(AccountContext);

  const onTalkToExpertClick = () => {
    navigate('my/talk-to-expert');
  };

  const onAvatarClick = () => {
    // navigate('my/setting');
  };

  const onLogoutClick = () => {
    navigate(Routes.logout(), { replace: true });
  };

  return (
    <div
      className={`${
        isMobile ? 'justify-between px-8' : 'justify-end px-16'
      } flex items-center border-b min-h-16`}
    >
      {isMobile && (
        <button
          className='flex items-center justify-center h-8 w-8 rounded-full hover:bg-gray-200 focus:outline-none'
          onClick={() => setShowSidebar(!showSidebar)}
        >
          {showSidebar ? <CrossIcon /> : <MenuIcon />}
        </button>
      )}
      <div className='flex divide-x-2'>
        <button
          type='button'
          className='mr-4 text-gray-600 focus-outline-custom'
          onClick={() => onTalkToExpertClick()}
        >
          Talk to expert
        </button>
        <div className='flex justify-between'>
          <button
            type='button'
            className='ml-4 cursor-auto focus-outline-custom'
            onClick={() => onAvatarClick()}
          >
            <img
              className='inline-block h-8 w-8 rounded-full ring-2 ring-white border'
              src={account.photo}
              alt='avatar'
            />
          </button>
          <button
            type='button'
            className='ml-4 focus-outline-custom'
            onClick={() => onLogoutClick()}
          >
            <LogoutIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
