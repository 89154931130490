import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useResetRecoilState } from 'recoil';
import { useFirebase } from '../../hooks';
import { productListState, accountState } from '../../state';

export const LogoutPage = () => {
  const { signOut } = useFirebase();
  const clearProductList = useResetRecoilState(productListState);
  const clearAccount = useResetRecoilState(accountState);
  const navigate = useNavigate();

  useEffect(() => {
    clearProductList();
    clearAccount();
    signOut();
    navigate('/', { replace: true });
  }, []);

  return <></>;
};
