import { ReactNode, createContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Account } from '../state/models';
import { useAccount } from '../hooks';

export const AccountContext = createContext<Account>({} as Account);

interface Props {
  children: ReactNode;
}

export const AccountProvider = ({ children }: Props) => {
  const { account, loading } = useAccount();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const adminPath = location.pathname.includes('my');
    if (!loading && !account && adminPath) {
      navigate('/login', { replace: true, state: { previousPath: location.pathname } });
    }
  }, [account, loading]);

  if (!account) {
    return <>{children}</>;
  }

  return (
    <AccountContext.Provider value={account}>
      {children}
    </AccountContext.Provider>
  );
};
