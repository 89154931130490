import { useState } from 'react';
import { AuthenticateFirstHint } from '../../components/generic';
import FormStep0 from './forms/FormStep0';

interface Props {
  className?: string;
}

const STEPS = [0, 1];

export default function InstallationPlaceholder ({ className = '' }: Props): JSX.Element {
  const [step, setStep] = useState(STEPS[0]);

  return (
    <div className={`installation-placeholder w-full ${className}`}>
      {step === STEPS[0] && (
        <FormStep0 onClick={() => setStep(STEPS[1])}/>
      )}
      {step === STEPS[1] && (
        <AuthenticateFirstHint />
      )}
    </div>
  );
}
