import { useMemo } from 'react';
import type { ITableColumn, ITableData, ITableRow } from '../../types';

export function useTable (id: string, columns: ITableColumn[], data: ITableData[][]) {
  const [header, rows, columnWidths] = useMemo(() => {
    const widths: string[] = columns.map((c) => (
      c.width
        ? `minmax(${c.width}px, 1fr)`
        : `${c.flex || 1}fr`
    ));

    const tableHeader: ITableRow = {
      id   : `${id}-header`,
      cells: []
    };

    for (let col = 0; col < columns.length; col++) {
      const column = columns[col];

      tableHeader.cells.push({
        id       : `${tableHeader.id}-${column.id}`,
        value    : column.header,
        className: column.headerClassName || ''
      });
    }

    const tableRows: ITableRow[] = [];

    for (let row = 0; row < data.length; row++) {
      const rowData = data[row];

      const tableRow: ITableRow = {
        id   : `${id}-row-${row + 1}`,
        cells: []
      };

      for (let col = 0; col < columns.length; col++) {
        const column = columns[col];

        const value = rowData.find((r) => r.key === column.id);

        tableRow.cells.push({
          id       : `${tableRow.id}-${column.id}`,
          value    : value ? value.value : '',
          className: column.rowClassName || ''
        });
      }

      tableRows.push(tableRow);
    }

    return [tableHeader, tableRows, widths];
  }, [columns, data]);

  return { header, rows, columnWidths };
}
