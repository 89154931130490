import { useMemo } from 'react';
import { useTable } from '../../hooks';
import { ITableColumn, ITableData } from '../../types';

interface Props {
  id: string;
  columns: ITableColumn[];
  data: ITableData[][];
  className?: string;
  isHeaderVisible?: boolean;
}

export function Table ({
  id,
  columns,
  data,
  className = '',
  isHeaderVisible = true
}: Props):JSX.Element {
  const { rows, header, columnWidths } = useTable(id, columns, data);

  const template = useMemo(() => ({
    gridTemplateColumns: columnWidths.join(' ')
  }), columnWidths);

  return (
    <div className={`table w-full ${id} ${className}`}>
      {isHeaderVisible &&
        <div className="header grid gap-x-2 text-cosmith-primary-900/60" style={template}>
          {header.cells.map((cell) => (
            <div key={cell.id} className={`flex items-center h-6 ${cell.className}`}>
              <div>{cell.value}</div>
            </div>
          ))}
        </div>
      }
      <div className="body">
        {rows.map((row) => (
          <div key={row.id} className="row grid gap-x-2 border-b" style={template}>
            {row.cells.map((cell) => (
              <div key={cell.id} className={`w-full flex items-center overflow-hidden h-[59px] ${cell.className}`}>
                <div>{cell.value}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
