import { BusinessFeaturesWrapper } from '../generic/landing/BusinessFeaturesWrapper';
import { ReviewCard } from './ReviewCard';

const features = [
  { id: 1, value: 'All-in-one package' },
  { id: 2, value: 'Prompt support' },
  { id: 3, value: 'Free trial' },
];

export const EnterprisesBusinessInfo = () => {
  return (
    <div>
      <BusinessFeaturesWrapper
        title="Access to office components"
        features={features}
        secondChildren={<ReviewCard type="light" />}
        secondChildrenclassName="lg:min-h-[428px] lg:pt-[25.5px]"
      >
        Experience the value of our office component suite for low-code
        solutions designed for corporations. Effortlessly generate and view
        various document types, and build solutions to enhance business
        processes.
      </BusinessFeaturesWrapper>
    </div>
  );
};
