import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { accountState } from '../../state';
import { firestore, auth, signOut } from '../../lib/firebase';
import { Account } from '../../state/models';
import { CollectionReference, collection, getDocs, query, where } from 'firebase/firestore';
import { IFirebaseAccountInfo } from '../../types';
import { Routes } from '../../util/constants';

export function useAccount () {
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useRecoilState(accountState);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const docRef = collection(firestore, 'accounts') as CollectionReference<IFirebaseAccountInfo>;
          const docQuery = query<IFirebaseAccountInfo>(
            docRef,
            where('access', 'array-contains', user.uid)
          );

          const snapshot = await getDocs(docQuery);
          const [accountDoc] = snapshot.docs;

          if (accountDoc) {
            const { id: accountId } = accountDoc;
            const accountInfo = accountDoc.data();

            setAccount(new Account(accountId, accountInfo, user));
          }
        } else {
          setAccount(null);
        }
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    });
  }, [setAccount]);

  useEffect(() => {
    const adminPath = location.pathname.includes('my');

    if (!loading && !account && adminPath) {
      signOut();
      navigate(Routes.login(), { replace: true });
    }
  }, [loading, account, navigate]);

  return {
    loading,
    account
  };
}
