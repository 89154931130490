import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeForm } from './StripeForm';
import { STRIPE_PUBLIC_KEY } from '../../../../util/constants';
import { ISubscriptionOptions } from '../../../../types';

interface Props {
  className?: string;
  onError: (error: string) => void;
  onSuccess: (options: ISubscriptionOptions) => void;
}

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export function StripeSubscriptionForm (props: Props): JSX.Element {
  return (
    <Elements stripe={stripePromise}>
      <StripeForm {...props} />
    </Elements>
  );
}
